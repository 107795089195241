import React from 'react';
import './index.css';

const Header2Generique = ({title, componentRight, className = ""}) => {

    return <div className={"header2 " +className}>
        <div className={"entete"}>
            <h1 className={"m-2"}>{title}</h1>
            <span className={"mr-5 mt-3"}>
                {componentRight != undefined && componentRight}
            </span>
        </div>

    </div>
};

export default Header2Generique;