import React, {useEffect, useState} from 'react';
import moment from "moment/moment";
import planningActiviteAPI from "../../Services/planningActiviteAPI";
import {EmployeAPI} from "../../Services/EmployeAPI";
import Badge from "react-bootstrap/Badge";

const ActiviteEmployeTab = ({user}) => {
    const [planningActivite, setPlanningActivite] = useState([]);
    const [etat, setEtat] = useState({});

    const getEtat = async () => {
        if (user.id != undefined){
            try {
                const response = await EmployeAPI.getEtat({"id": user.id});
                setEtat(response.data)
            } catch (e) {
                console.log(e)
            }
        }
    }

    const fetchPlanning = async () => {
        if (user.id != undefined){
            try {
                const response = await planningActiviteAPI.getPlanningByUser(user.id)
                response.data.sort((a, b) => {
                    if (moment.unix(a.dateDebut.toString().substring(0.10)) < moment.unix(b.dateDebut.toString().substring(0.10))) {
                        return -1
                    }
                    if (moment.unix(a.dateDebut.toString().substring(0.10)) > moment.unix(b.dateDebut.toString().substring(0.10))) {
                        return 1
                    }

                    return 0;
                })
                setPlanningActivite(response.data);
            } catch (e) {
                console.log(e)
            }
        }
    }

    useEffect(() => {
        fetchPlanning();
        getEtat()
    }, [user]);

    return <>
        <ul className="list-group">
            <li className="list-group-item">
                <span className={"info-color"}>Planning de </span> : {user.nom} {user.prenom}
                <br/>
                <span
                    className={"info-color"}>Statut du {moment().format("DD/MM/YYYY")}</span> : {etat != undefined && Object.keys(etat).length > 0 ? <>
                {etat.dejaplanifie != "false" && <Badge key={etat.dejaplanifie} bg={"danger"} pill>{etat.employe + " => " + etat.dejaplanifie}</Badge>}
                {etat.isindisponible != "false" && <Badge key={etat.isindisponible} bg={"danger"} pill>{etat.employe + " => " + etat.isindisponible}</Badge>}
                {etat.cisp_date_debut != null && <Badge key={etat.cisp_date_debut} bg={"warning"} pill>{"RDV CISP => " + moment.unix(etat.cisp_date_debut.toString().substring(0,10)).format("HH:mm") + " " + moment.unix(etat.cisp_date_fin.toString().substring(0,10)).format("HH:mm")}</Badge>}
                {etat.isindisponible == "false" && etat.dejaplanifie == "false" && etat.cisp_date_debut == null &&  "Disponible"}
            </> : "Disponible"}

            </li>
            {planningActivite && planningActivite.map((activite, index) => {
                let titre = "";
                let groupeActivite = "";
                activite.activites.map((act) => {
                    if (titre == ""){
                        titre = act.libelle;
                        groupeActivite = act.groupeActivite.libelle;
                    }else{
                        titre = titre +", " +act.libelle;
                    }
                })
                return <li key={index} className="list-group-item">
                                    <span
                                        className={"info-color"}>Groupe Activité</span>: {groupeActivite}
                    <br/><span
                    className={"info-color"}>Activité</span>: {titre}
                    <br/>
                    <span
                        className={"info-color"}>Date de début</span>: {new Date(activite.dateDebut).toLocaleDateString()}
                    <br/>
                    <span
                        className={"info-color"}>Date de fin</span>: {new Date(activite.dateFin).toLocaleDateString()}
                    <br/>
                    <span className={"info-color"}>Client</span>: {activite.client.nom}
                    <br/>
                    <span className={"info-color"}>État</span>: {activite.etat}
                    <br/>
                    <span
                        className={"info-color"}>Heure de début</span>: {activite.dateDebut ? moment(activite.dateDebut).format("HH:mm:ss") : ""}
                    <br/>
                    <span
                        className={"info-color"}>Heure de fin</span>: {activite.dateFin ? moment(activite.dateFin).format("HH:mm:ss") : ""}
                </li>
            })}
        </ul>
    </>;
};

export default ActiviteEmployeTab;