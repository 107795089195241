import React, {useContext, useState} from 'react';
import {routes} from "../../rooting/routes";
import Color from "../../Styles/index";
import {Link} from 'react-router-dom';
import './index.css';
import {FirstPage, LastPage, Logout, ScreenshotMonitor} from '@mui/icons-material';
import SubMenu from "../Menus/SubMenu";
import SvgIcon from '@mui/material/SvgIcon';
import SubMenuCollapse from "../Menus/SubMenuCollapse";
import SideBarContext from "../../Context/SideBarContext";
import logoMerlin from '../../Assets/img/logo-merlin.png'
import logoMerlinCollapse from '../../Assets/img/logo-merlin-without-text.png';
import Auth from "../../Services/Auth";
import AuthContext from "../../Context/AuthContext";
import Tooltip from "@mui/material/Tooltip";
import {createFileName, useScreenshot} from "use-react-screenshot";
import moment from "moment";

const Auth0 = new Auth();
const Sidebar = ({reference}) => {
    const {isOpen, setIsOpen} = useContext(SideBarContext)
    const {setIsAuthenticated, user, roles} = useContext(AuthContext);
    const [routeSelected, setRouteSelected] = useState("");
    const [image, takeScreenShot] = useScreenshot({
        type: "image/jpeg",
        quality: 2.0
    });

    const download = (image, { name = "capture_merlin_"+moment().format("YYYYMMDD"), extension = "jpg" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };

    const downloadScreenshot = () => takeScreenShot(reference.current).then(download);

    const logout = () => {
        try {
            Auth0.logout();
            setIsAuthenticated(false);
        } catch (e) {
            console.log(e);
        }
    }

    return <div className={"header"}>
        {isOpen === true ? <>
            <nav className={"header1 flex justify-content-between"}>
                <Tooltip title={"Prendre une capture d'écran"}>
                    <button className="btn btn-refresh ml-3" onClick={() => downloadScreenshot()}>
                        <SvgIcon sx={{color: "#FFFFFF"}}>
                            <ScreenshotMonitor/>
                        </SvgIcon>
                    </button>
                </Tooltip>
                <Link className={"navIcon"} onClick={logout} to={"/"}>
                    <SvgIcon sx={{color: Color.grey}}>
                        <Logout/>
                    </SvgIcon>
                    <span className={"textIcon"}>
                        {user}
                    </span>
                </Link>
            </nav>
            <nav className={"sideBarNav"}>
                <div className={"sideBarWrap"}>
                    <div>
                        <Link className={"navIconLogo"} to={"/"}>
                            <img src={logoMerlin} className={"logo"}/>
                        </Link>
                        {routes.map((route, index) => {
                            if (route.visible == true &&( route.roles.includes(roles.replaceAll('[' , '').replaceAll(']', '')) || route.roles.length === 0)) {
                                return <SubMenu route={route} key={"submenu" + index} setRoute={setRouteSelected}
                                                open={route.name === routeSelected ? true : false} roles={roles}/>
                            }
                        })}
                    </div>
                    <div className={"iconOpenCollapse"}>
                        <Tooltip title={"Réduire le menu"}>
                            <Link onClick={() => setIsOpen(!isOpen)}>
                                <SvgIcon sx={{color: Color.iconMenuColor}}>
                                    <FirstPage/>
                                </SvgIcon>
                            </Link>
                        </Tooltip>
                    </div>
                </div>
            </nav>
        </> : <>
            <nav className={"header1 navCollapse flex justify-content-between"}>
                <Tooltip title={"Prendre une capture d'écran"}>
                    <button className="btn btn-refresh ml-3" onClick={() => downloadScreenshot()}>
                        <SvgIcon sx={{color: "#FFFFFF"}}>
                            <ScreenshotMonitor/>
                        </SvgIcon>
                    </button>
                </Tooltip>
                <Link className={"navIcon"} onClick={logout} to={"/"}>
                    <SvgIcon sx={{color: Color.grey}}>
                        <Logout/>
                    </SvgIcon>
                    <span className={"textIcon"}>
                        {user}
                    </span>
                </Link>
            </nav>
            <nav className={"sideBarNavCollapse"}>
                <div className={"sideBarWrapCollapse"}>
                    <Link className={"navIconCollapse"} to={'/'}>
                        <img src={logoMerlinCollapse} className={"logoCollapse"}/>
                    </Link>
                    {routes.map((route, index) => {
                        if (route.visible == true  &&( route.roles.includes(roles.replaceAll('[' , '').replaceAll(']', '')) || route.roles.length === 0)) {
                            return <SubMenuCollapse route={route} key={"routea" + index} roles={roles} />
                        }
                    })}
                    <Tooltip title={"Agrandir le menu"}>
                        <Link className={"iconOpenCollapse"} onClick={() => setIsOpen(!isOpen)}>
                            <SvgIcon sx={{color: Color.iconMenuColor}}>
                                <LastPage/>
                            </SvgIcon>
                        </Link>
                    </Tooltip>

                </div>
            </nav>
        </>}
    </div>
}

export default Sidebar;