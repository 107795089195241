import axios from "axios";
import {REGISTER_API, USERS_API} from "../Config/url";

export class UtilisateursAPI {
    static async getAllUsers() {
        return await axios.get(USERS_API).then(response => response.data);
    }

    static async getUserById(id) {
        return await axios.get(USERS_API + "/" + id).then(response => response.data);
    }

    static async updateUser(id, user) {
        await axios.put(USERS_API + "/" + id, user)
    }

    static async createAccount(account) {
        await axios.post(REGISTER_API, account)
    }

    static async updateRole(id, role) {
        await axios.put(USERS_API + "/" + id + "/role", role);
    }
}