import React, {useEffect, useState} from 'react';
import Header2Generique from "../../Components/Headers/Header2Generique";
import {prestataireAPI} from "../../Services/prestataireAPI";
import Loading from "../../Components/Loading";
import GenericDataTable from "../../Components/DataTables/GenericDataTable";
import {IconButton, Tooltip} from "@mui/material";
import {Delete, Visibility} from "@mui/icons-material";
import ModalPrestataire from "../../Components/Modal/ModalPrestataire";
import DeleteModal from "../../Components/Modal/DeleteModal/deleteModal";

const ListePrestataire = () => {
    const [loading, setLoading] = useState(false);
    const [dataPrestataire, setDataPrestataire] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [edit, setEdit] = useState(false);
    const [reload, setReload] = useState(false);
    const [columnPrestataire, setColumnPrestataire] = useState([
        {
            name: <span className="top-column">Nom</span>,
            selector: row => row.nom,
            sortable: true
        },
        {
            name: <span className="top-column">Mail</span>,
            selector: row => row.mail,
            sortable: true
        },
        {
            name: <span className="top-column">Adresse</span>,
            selector: row => row.adresse,
            sortable: true
        },
        {
            name: <span className="top-column">Code postal</span>,
            selector: row => row.codePostal,
            sortable: true
        },
        {
            name: <span className="top-column">Ville</span>,
            selector: row => row.ville,
            sortable: true
        },
        {
            name: <span className="top-column">Actions</span>,
            selector: row => <div className="d-flex justify-content-evenly">
                <Tooltip title={"Voir le prestataire"}>
                    <IconButton onClick={() => showPresta(row.id)}>
                        <Visibility color="warning"/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Supprimer le prestataire"}>
                    <IconButton onClick={() => deletePresta(row.id)}>
                        <Delete color="error"/>
                    </IconButton>
                </Tooltip>
            </div>
        }
    ]);

    const deletePresta = (id) => {
        setSelectedId(id);
        setIsOpenDelete(true);
    }

    const showPresta = id => {
        setSelectedId(id);
        setEdit(true);
        setIsOpen(true);
    }

    const fetchData = async () => {
        let data = await prestataireAPI.fetchAll();
        setDataPrestataire(data.data);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        fetchData().then(r => '');
    }, []);

    useEffect(() => {
        if (reload) {
            fetchData().then(r => '');
            setReload(false);
        }
    }, [reload]);

    return (
        <div>
            <Header2Generique title={"Liste des prestataires"}/>

            <div className="col-11 m-auto mt-2">
                {loading && <Loading/> || <GenericDataTable data={dataPrestataire} setIsOpen={setIsOpen}
                                                            noDataText={"Aucun Prestataire trouvé"}
                                                            columnItem={columnPrestataire}
                                                            btnText={"Ajouter un prestataire"}/>}
            </div>
            <ModalPrestataire id={selectedId} setId={setSelectedId} edit={edit} setEdit={setEdit} isOpen={isOpen}
                              setIsOpen={setIsOpen} setReload={setReload}/>

            <DeleteModal setIsOpen={setIsOpenDelete} isOpen={isOpenDelete} id={selectedId} setId={setSelectedId}
                         setReload={setReload} api={prestataireAPI}/>

        </div>
    );
};

export default ListePrestataire;