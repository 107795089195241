import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import '../../../Styles/ModalForm.css';
import {toast} from "react-toastify";
import {modalStyle} from "../../../Styles/ModalStyle";
import SelectInput from "../../FormsComponents/SelectInput";
import planningHoraire from "../../../Services/planningHoraire";
import {EmployeAPI} from "../../../Services/EmployeAPI";

const ModalPlanningHoraire= ({isOpen, setIsOpen, planningExemple, user, setCPlanning, setPlanningData, setIsOpenAddDay}) => {
    const [planning, setPlanning] = useState({});
    const [planningSelected, setPlanningSelected] = useState({});
    const [planningOption, setPlanningOption] = useState([]);
    const handleCloseModal = () => {
        setIsOpen(!isOpen);
    }


    const handleSubmit = async e => {
        e.preventDefault();
        try {
            if(planningSelected.value != undefined){
                planning.jours = planningSelected.value;
                planning.equipe = planningSelected.label;
                planningHoraire.add(planning).then(async response => {
                    user.planningHoraire = response.data;
                    setCPlanning(response.data)
                    setPlanningData((JSON.parse(response.data.jours)))
                    await EmployeAPI.updateEmploye(user.id, user);
                    handleCloseModal();
                    toast.success("Planning horaire ajouté")
                    if(planningSelected.label == "Planning individuel"){
                        setIsOpenAddDay(true)
                    }
                })
            }else {
                toast.error("Veuillez selectionner un modèle horaire")
            }
        } catch (e) {
            toast.error("Une erreur est survenue");
        }
    }
    const setOptionsPlanningExemple = () => {
        let options = []
        planningExemple.map( ex => {
            options.push({value : ex.jours, label : ex.libelle})
        })
        setPlanningOption(options)
    }


    useEffect(() => {
        setOptionsPlanningExemple()
    }, [planningExemple])

    return <Modal open={isOpen}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h5" component="h2"
                        className={"d-flex justify-content-center p-2 border-bottom"}>
                Créer un Planning Horaire
            </Typography>
            <div className={"form-modal flex flex-column gap-2"}>
                <form onSubmit={handleSubmit}>
                    <div className="my-2">
                        <SelectInput
                            classNameLabel={"text-end mr-2 pr-5"}
                            label="Planning Parent"
                            options={planningOption}
                            value={planningSelected}
                            setObjet={setPlanningSelected}
                            objet={planningSelected}
                            isMulti={false}
                            required={true}
                        />
                    </div>
                    <div className="d-flex justify-content-end">
                        <div className="d-flex justify-content-between col-3 me-2 mt-2">
                            <button className={"btn btn-secondary m-2"} onClick={handleCloseModal}>
                                Annuler
                            </button>
                            <button className={"btn btn-add-activity m-2"} type={"submit"}>
                                Enregistrer
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Box>
    </Modal>;
};

export default ModalPlanningHoraire;
