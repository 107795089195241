import React from 'react';

const Synthese = () => {
    return (
        <div>

        </div>
    );
};

export default Synthese;