import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import '../../../Styles/ModalForm.css';
import Button from 'react-bootstrap/Button'
import {toast} from "react-toastify";
import {groupeActiviteAPI} from "../../../Services/groupeActiviteAPI";
import {modalStyle} from "../../../Styles/ModalStyle";

const ModalDeleteGroup = ({isOpen, setIsOpen, groupeActivite, fetch, setGroupeActivite}) => {
    const handleCloseModal = () => {
        setIsOpen(!isOpen);
    }

    const handleDelete = async () => {
        try {
            await groupeActiviteAPI.delete(groupeActivite.id)
            toast.success("Le groupe a bien été supprimé");
            fetch().then(ga => {setGroupeActivite(ga)})
            handleCloseModal();
        } catch (e) {
            toast.error("Une erreur est survenue");
        }
    }

    return <Modal open={isOpen}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h5" component="h2"
                        className={"d-flex justify-content-center p-2 border-bottom"}> Etes-vous sure de supprimer ce groupe d'activité ?
            </Typography>
            <div className={"form-modal flex flex-column gap-2"}>
                <div className="d-flex justify-content-end">
                    <div className="d-flex justify-content-between col-3 me-2 mt-2">
                        <Button variant={"outlined"} className={"btn btn-danger"} color={"danger"}
                                onClick={handleCloseModal} type="button">Annuler</Button>
                        <Button variant={"outlined"} className={"btn btn-alliance"} type="submit" onClick={handleDelete}>Supprimer</Button>
                    </div>
                </div>
            </div>
        </Box>
    </Modal>;
};

export default ModalDeleteGroup;