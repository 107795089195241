import axios from "axios";
import {ACTIVITE_URL, CLIENT_API_URL, GROUPE_ACTIVITE_URL} from "../Config/url";

export class activiteAPI {
    static async fetchAllByGroupeActivite(id) {
        return axios.get(GROUPE_ACTIVITE_URL + "/" + id + "/activites");
    }

    static async fetchAllByGroupeActiviteAndParentIsNull(id) {
        return axios.get(GROUPE_ACTIVITE_URL + "/" + id + "/activites_wihtout_parent");
    }

    static async fetchChildren(parent) {
        return axios.get(ACTIVITE_URL + "/" + parent + "/enfants");
    }

    static async getAllActiviteGeneralTab() {
        return axios.get(ACTIVITE_URL + "/activitesTable");
    }

    static async createActivite(activite) {
        return axios.post(ACTIVITE_URL, activite);
    }

    static async getActiviteByID(id) {
        return axios.get(ACTIVITE_URL + '/' + id);
    }

    static async deleteActivite(id) {
        return axios.delete(ACTIVITE_URL + '/' + id);
    }

    static async update(activite) {
        return axios.put(ACTIVITE_URL + '/' + activite.id, activite);
    }

    static async fetchParent(id) {
        return axios.get(ACTIVITE_URL+"/"+id+"/parent")
    }
}

function fetchAllByGroupeActivite(id) {
    return axios.get(GROUPE_ACTIVITE_URL + "/" + id + "/activites");
}

function fetchAllByGroupeActiviteAndParentIsNull(id) {
    return axios.get(GROUPE_ACTIVITE_URL + "/" + id + "/activites_wihtout_parent");
}
function fetchAllByClientAndParentIsNull(client, ga) {
    return axios.get(CLIENT_API_URL + "/" + client + "/activites_wihtout_parent/"+ga);
}

function fetchAllByClientAndGA(client, ga) {
    return axios.get(CLIENT_API_URL + "/" + client + "/activites_client_ga/"+ga);
}

function fetchChildren(parent) {
    return axios.get(ACTIVITE_URL + "/" + parent + "/enfants");
}

export default {
    fetchAllByGroupeActivite,
    fetchAllByGroupeActiviteAndParentIsNull,
    fetchChildren,
    fetchAllByClientAndParentIsNull,
    fetchAllByClientAndGA
}