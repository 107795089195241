// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-modal {
    background: #f7fbfe;
    padding: 2%;
    width: 100%;
}

.badge-days{
    padding: 20px !important;
}`, "",{"version":3,"sources":["webpack://./src/Styles/ModalForm.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,WAAW;IACX,WAAW;AACf;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".form-modal {\r\n    background: #f7fbfe;\r\n    padding: 2%;\r\n    width: 100%;\r\n}\r\n\r\n.badge-days{\r\n    padding: 20px !important;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
