import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import '../../../Styles/ModalForm.css';
import Input from "../../FormsComponents/Input";
import {CircularProgress} from "@mui/material";
import {toast} from "react-toastify";
import {prestataireAPI} from "../../../Services/prestataireAPI";
import {modalStyle} from "../../../Styles/ModalStyle";

const ModalPrestataire = ({isOpen, setIsOpen, id = null, setId = () => {}, edit = false, setEdit = () =>{}, setReload = () =>{}}) => {
    const [prestataire, setPrestataire] = useState({});
    const [loading, setLoading] = useState(false);

    const handleCloseModal = () => {
        setIsOpen(!isOpen);
        setEdit(false);
        setId(null);
        setPrestataire({});
        setReload(true);
    }
    const fetchData = async id => {
        let data = await prestataireAPI.fetchPrestataireById(id);
        setPrestataire(data.data);
        setLoading(false);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            if (edit) {
                await prestataireAPI.updatePrestataire(id, prestataire);
                toast.success("Le prestataire à bien été modifié")
            } else {
                await prestataireAPI.createPrestataire(prestataire);

                toast.success("Le prestataire à bien été créé")
            }
            handleCloseModal();
        } catch (e) {
            toast.error("Une erreur est survenue");
        }
    }

    useEffect(() => {
        if (id) {
            setLoading(true)
            fetchData(id).then(r => '')
        }
    }, [id])

    return <Modal open={isOpen}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h5" component="h2"
                        className={"d-flex justify-content-center p-2 border-bottom"}>
                {edit ? "Modifier" : "Créer"} un Prestataire
            </Typography>
            <div className={"form-modal flex flex-column gap-2"}>
                {(loading) && <div className={"text-center"}><CircularProgress color={"primary"}/></div>
                    || <>
                        <form onSubmit={handleSubmit}>

                            <div className="my-2">
                                <Input required={true} type={"text"} name={"nom"} label={"Nom"} value={prestataire.nom}
                                       placeholder={"Nom du prestataire"} objet={prestataire}
                                       setObjet={setPrestataire}/>
                            </div>
                            <div className="my-2">
                                <Input required={true} type={"text"} name={"adresse"} label={"Adresse"}
                                       value={prestataire.adresse}
                                       placeholder={"Adresse du prestataire"} objet={prestataire}
                                       setObjet={setPrestataire}/>
                            </div>
                            <div className="my-2">
                                <Input required={true} type={"text"} name={"ville"} label={"Ville"}
                                       value={prestataire.ville}
                                       placeholder={"Ville du prestataire"} objet={prestataire}
                                       setObjet={setPrestataire}/>
                            </div>
                            <div className="my-2">
                                <Input required={true} type={"number"} name={"codePostal"} label={"Code Postal"}
                                       value={prestataire.codePostal}
                                       placeholder={"Code Postal du prestataire"} objet={prestataire}
                                       setObjet={setPrestataire}/>
                            </div>
                            <div className="my-2">
                                <Input required={true} type={"number"} name={"numero"} label={"Numéro de téléphone"}
                                       value={prestataire.numero}
                                       placeholder={"Numéro du prestataire"} objet={prestataire}
                                       setObjet={setPrestataire}/>
                            </div>
                            <div className="my-2">
                                <Input required={true} type={"email"} name={"mail"} label={"Mail du prestataire"}
                                       value={prestataire.mail}
                                       placeholder={"Mail du prestataire"} objet={prestataire}
                                       setObjet={setPrestataire}/>
                            </div>
                            <div className="d-flex justify-content-end">
                                <div className="d-flex justify-content-between col-3 me-2 mt-2">
                                    <button className={"btn btn-secondary m-2"} onClick={handleCloseModal}>
                                        Annuler
                                    </button>
                                    <button className={"btn btn-add-activity m-2"} type={"submit"}>
                                        Enregistrer
                                    </button>
                                </div>
                            </div>
                        </form>

                    </>
                }
            </div>
        </Box>
    </Modal>;
};

export default ModalPrestataire;