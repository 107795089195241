import React, {useEffect, useRef, useState} from 'react';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {modalStyle} from "../../../Styles/ModalStyle";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import SelectInput from "../../FormsComponents/SelectInput";
import DatePicker from "../../DatePicker";
import moment from "moment";
import {typeIndisponibiliteAPI} from "../../../Services/TypeIndisponibiliteAPI";
import {indisponibiliteAPI} from "../../../Services/IndisponibiliteAPI";
import {toast} from "react-toastify";
import {MobileTimePicker} from "@mui/x-date-pickers";
import functionConfig from "../../../config";

const ModalEditIndisponibilite = ({indisponibilite, isOpen, setIsOpen, fetchIndisponibilite, setIndisponibilite}) => {
    const [typeIndisponibilite, setTypeIndisponibilite] = useState([]);
    const [heureDebut, setHeureDebut] = useState(null);
    const [heureFin, setHeureFin] = useState(null);
    const ref = useRef()
    const handleChangeDateDebut = (e) => {
        setHeureDebut(e);
    }

    const handleChangeDateFin = (e) => {
        setHeureFin(e);
    }

    const fetchTypeIndiponibilite = async id => {
        let data = await typeIndisponibiliteAPI.fetchTypeIndiponibilite();
        return data.data;
    }

    const setOptionIndisponibilite = (data) => {
        let options = [];
        data.map((e) => {
            options.push({value: e, label: e.label})
        });
        setTypeIndisponibilite(options)
    }


    const handleCloseModal = () => {
        setIsOpen(!isOpen);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            indisponibilite.dateDebut = moment(moment(indisponibilite.dateDebut).format("YYYY-MM-DD")+"T"+moment(heureDebut["_d"]).format("HH:mm:ss")).locale('fr').format("YYYY-MM-DDTHH:mm:ssZ")
            indisponibilite.dateFin = moment(moment(indisponibilite.dateFin).format("YYYY-MM-DD")+"T"+moment(heureFin["_d"]).format("HH:mm:ss")).locale('fr').format("YYYY-MM-DDTHH:mm:ssZ")
            indisponibilite.nombreJours = functionConfig.calculateBusinessDays(indisponibilite.dateDebut, indisponibilite.dateFin)
            indisponibiliteAPI.update(indisponibilite).then(res => {
                fetchIndisponibilite()
                setIndisponibilite({})
                setIsOpen(false)
                toast.success("Votre modification c'est bien passé")
            })
        }catch (e) {
            console.error(e)
            toast.error("Votre modification à eu une erreur")
        }
    }

    useEffect(() => {
        fetchTypeIndiponibilite().then(data => setOptionIndisponibilite(data))
    }, [])

    useEffect(() =>{
        if (indisponibilite.dateDebut && indisponibilite.dateFin){
            setHeureDebut(moment.unix(indisponibilite.dateDebut.toString().substring(0, 10)))
            setHeureFin(moment.unix(indisponibilite.dateFin.toString().substring(0, 10)))
            setIndisponibilite({...indisponibilite, ["dateDebut"] : moment.unix(indisponibilite.dateDebut.toString().substring(0, 10)).format("YYYY-MM-DD"), ["dateFin"] : moment.unix(indisponibilite.dateFin.toString().substring(0, 10)) })
        }
    }, [isOpen])

    return (
        <Modal open={isOpen}
               onClose={handleCloseModal}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description">
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h5" component="h2"
                            className={"d-flex justify-content-center p-2 border-bottom"}>
                    Modifier une Indisponibilité
                </Typography>
                <form onSubmit={handleSubmit}>
                    <div className={"form-modal flex flex-column gap-2"}>
                                <div className="my-2">
                                    <SelectInput
                                        classNameInput={"mt-3"}
                                        classNameLabel={"text-end mr-2 pr-5 mt-3"}
                                        required={true}
                                        name={"typeIndisponibilite"}
                                        flex={"row"}
                                        placeholder={"Type Indisponibilité"}
                                        options={typeIndisponibilite}
                                        value={indisponibilite.typeIndisponibilite && {value: indisponibilite.typeIndisponibilite, label: indisponibilite.typeIndisponibilite.label}}
                                        objet={indisponibilite}
                                        setObjet={setIndisponibilite}
                                        label={"Type Indisponibilité"}
                                    />
                                    <div className={"mt-3 form-group flex"}>
                                        <label htmlFor={"dateDebut"} className={"form-label mt-1 text-end col-5 pr-5"}
                                               style={{width: "41.66666667%"}}>{"Date Debut"} * :</label>
                                        <div className={"pl-2 col-12"} id={"dateDebut"}>
                                            <DatePicker name={"dateDebut"} objet={indisponibilite}
                                                        setObjet={setIndisponibilite} objectIsMoment={false}
                                                        value={(indisponibilite.dateDebut != null && indisponibilite.dateDebut != undefined) ? moment(indisponibilite.dateDebut) : null}/>
                                        </div>
                                    </div>
                                    <div className={"mt-3 form-group flex"}>
                                        <label htmlFor={"heureDebut"} className={"form-label mt-1 text-end col-5 pr-5"}
                                               style={{width: "41.66666667%"}}>{"Heure Début"} * :</label>
                                        <div className={"pl-2 col-12 justify-content-end"}>
                                            <MobileTimePicker label={'Heures début'} openTo="hours" className={"datePicker"}
                                                              onChange={handleChangeDateDebut} ampm={false} value={heureDebut}
                                                              ampm={false}/></div>
                                    </div>
                                    <div className={"mt-3 form-group flex"}>
                                        <label htmlFor={"dateFin"} className={"form-label mt-1 text-end col-5 pr-5"}
                                               style={{width: "41.66666667%"}}>{"Date Fin"} * :</label>
                                        <div className={"pl-2 col-12"} id={"dateFin"}>
                                            <DatePicker name={"dateFin"} objet={indisponibilite}
                                                        setObjet={setIndisponibilite} objectIsMoment={false}
                                                        value={(indisponibilite.dateFin != null && indisponibilite.dateFin != undefined) ? moment(indisponibilite.dateFin) : null}/>
                                        </div>
                                    </div>
                                    <div className={"mt-3 form-group flex"}>
                                        <label htmlFor={"heureFin"} className={"form-label mt-1 text-end col-5 pr-5"}
                                               style={{width: "41.66666667%"}}>{"Heure fin"} * :</label>
                                        <div className={"pl-2 col-12 justify-content-end"}>
                                            <MobileTimePicker label={'Heures fin'} openTo="hours" className={"datePicker"}
                                                              onChange={handleChangeDateFin} ampm={false} value={heureFin}/></div>
                                    </div>
                                </div>
                    </div>
                    <div id="modal-modal-footer" className={"d-flex-column p-2 border-top justify-content-end"}>
                        <div className="d-flex justify-content-end">
                            <div className="d-flex justify-content-between col-3 me-2 mt-2">
                                <Button variant={"contained"} color={"error"} className={"btn m-2"}
                                        onClick={handleCloseModal} type="button">
                                    Annuler
                                </Button>
                                <Button className={"btn btn-add-activity m-2"} variant={"outlined"}
                                        color={"primary"} type="submit">
                                    Enregistrer
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>

            </Box>

        </Modal>
    );
};

export default ModalEditIndisponibilite;