import React from 'react';
import {toast} from "react-toastify";
import {indisponibiliteAPI} from "../../../Services/IndisponibiliteAPI";
import Box from "@mui/material/Box";
import {modalStyle} from "../../../Styles/ModalStyle";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const ModalDeleteIndisponibilite = ({indisponibilite, isOpen, setIsOpen, fetchIndisponibilite}) => {
    const handleCloseModal = () => {
        setIsOpen(!isOpen);
    }

    const handleDelete = async () => {
        try {
            const response = await indisponibiliteAPI.delete(indisponibilite)
            fetchIndisponibilite()
            setIsOpen(false);
            toast.success("Votre indisponibilité à bien été supprimé")
        } catch (e) {
            toast.error("Une erreur est survenue")
        }
    }

    return <Modal open={isOpen}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h5" component="h2"
                        className={"d-flex justify-content-center p-2 border-bottom"}>
                Supprimer une indisponibilité
            </Typography>
            <div className={"form-modal flex flex-column gap-2"}>
                <Typography>Êtes-vous sûr de vouloir supprimer cet indisponibilité ?</Typography>
                <div className="d-flex justify-content-end">
                    <div className="d-flex justify-content-between col-3 me-2 mt-2">
                        <button className={"btn btn-secondary m-2"} onClick={handleCloseModal}>
                            Annuler
                        </button>
                        <button className={"btn btn-add-activity m-2"} onClick={handleDelete}>
                            Supprimer
                        </button>
                    </div>
                </div>
            </div>
        </Box>
    </Modal>;
};

export default ModalDeleteIndisponibilite;