import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Color from '../../../Styles';
import '../../../Styles/ModalForm.css';
import Input from "../../FormsComponents/Input";
import SelectInput from "../../FormsComponents/SelectInput";
import TextAreaInput from "../../FormsComponents/TextAreaInput";
import SvgIcon from "@mui/material/SvgIcon";
import {Close, Print, Save} from "@mui/icons-material";
import moment from "moment";
import {modalStyle} from "../../../Styles/ModalStyle";
import {Switch} from '@mui/material'
import {MobileTimePicker} from "@mui/x-date-pickers";
import 'moment/locale/fr';
import DatePicker from "../../DatePicker";
import {EmployeAPI} from "../../../Services/EmployeAPI";
import PlanningActiviteAPI from "../../../Services/planningActiviteAPI";
import Badge from 'react-bootstrap/Badge';
import {toast} from "react-toastify";
import {ClientAPI} from "../../../Services/clientAPI";
import Loading from "../../Loading";
import planningActiviteAPI from "../../../Services/planningActiviteAPI";
import ActiviteAPI from "../../../Services/activiteAPI";
import {FaiteAPI} from "../../../Services/FaiteAPI";
import {activiteAPI} from "../../../Services/activiteAPI";
import activiteEmployeTab from "../../TabEmployes/ActiviteEmployeTab";
import Tooltip from "@mui/material/Tooltip";
import ModalEmployePlanificationEdit from "../ModalEmployePlanificationEdit";

const ModalActiviteEdit = ({
                           isOpen,
                           setIsOpen,
                           groupeActivites,
                           prestataires,
                           fetchPlanningActivite,
                            activiteC,
                               setEventSelected
                       }) => {
    const [activite, setActivite] = useState({nombreSalarie : 0})
    const [toValidate, setToValidate] = useState(false);
    const [activites, setActivites] = useState([]);
    const [heuresDebut, setHeuresDebut] = useState(null);
    const [heuresFin, setHeuresFin] = useState(null);
    const [errorMessages, setErrorMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [clients, setClients] = useState([]);
    const [localisation, setLocalisation] = useState([
        {value: {value: "EXTERNE", label: "Externe"}, label: "Externe"},
        {value: {value: "INTERNE", label: "Interne"}, label: "Interne"}
    ])
    const [encadrants, setEncadrants] = useState([]);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [isOpenPlanifEmploye, setIsOpenPlanifEmploye] = useState(false);
    const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);

    const fetchPlannification = async () => {
        if (activiteC.id != undefined){
            try {
                const response = await PlanningActiviteAPI.getPlanningById(activiteC.id)
                let activite = response.data;
                const activiteParent = await activiteAPI.fetchParent(activite.activites[0].id);

                activite.groupeActivite = activite.activites[0].groupeActivite
                activite.localisation = activite.activites[0].localisation
                activite.coordinateur = activite.activites[0].coordinateur
                setHeuresDebut(moment.unix(activite.dateDebut.toString().substring(0,10)))
                setHeuresFin(moment.unix(activite.dateFin.toString().substring(0,10)))
                let heuresDebut = moment.unix(activite.dateDebut.toString().substring(0,10));
                let heuresFin = moment.unix(activite.dateFin.toString().substring(0,10));
                let activites = []
                let newActivites = []
                activite.activites.map((a) => {
                    activites.push(a.id);
                    newActivites.push({
                        label : a.libelle,
                        value : a
                    })
                })
                let dateDebut = moment(moment(activite.dateDebut).format("YYYY-MM-DD") + "T" + moment(heuresDebut["_d"]).locale("fr").format("HH:mm:ss")).locale('fr').format("YYYY-MM-DD HH:mm:ss")
                let dateFin = moment(moment(activite.dateFin).format("YYYY-MM-DD") + "T" + moment(heuresFin["_d"]).locale("fr").format("HH:mm:ss")).locale('fr').format("YYYY-MM-DD HH:mm:ss")
                activite.faites = await FaiteAPI.fetchFaiteByClientAndActivite(activite.client.id,activite.activites[0].id).then(response => response.data);
                const response1 = await EmployeAPI.getErrorMessageForEdit(dateDebut, dateFin,activites, activite.id);
                if (activiteParent.data != ""){
                    activite.activiteParent = {
                        label : activiteParent.data.libelle,
                        value : activiteParent.data
                    };
                    activite.activites = newActivites
                }else{
                    activite.activiteParent = {
                        label : activite.activites[0].libelle,
                        value : activite.activites[0]
                    };
                    activite.activites = [];
                }
                activite.employes = response1.data;
                setActivite(activite);
                setErrorMessages(activite.employes)
                setLoading(false);
                return activite;
            } catch (e) {
                console.log(e)
            }
        }
    }

    const fetchClient = async () => {
        if (activite.groupeActivite != undefined){
            try {
                const response = await ClientAPI.fetchClientByGroupActivite(activite.groupeActivite.id);
                return response.data;
            }catch (e){
                console.log(e)
            }
        }
    }

    const fetchEncadrant = async () => {
        try {
            let response = await EmployeAPI.getEmployeByType("Encadrant");
            return response.data;
        } catch (e) {
            toast.error("Une erreur est survenue");
            console.log(e);
        }
    }
    //
    const fetchActiviteByClientAndGroupeActivite = async () => {
        if (activite.activiteParent) {
            try {
                console.log(activite.activiteParent)
                let response = await ActiviteAPI.fetchChildren(activite.activiteParent.value.id);
                console.log(response.data)
                return response.data;
            }catch (e){
                toast.error("Une erreur est survenue");
                console.log(e);
            }
        }else{
            if (activite.client !== undefined && activite.client !== null && activite.groupeActivite !== undefined && activite.groupeActivite !== null) {
                try {
                    let response = await ActiviteAPI.fetchAllByClientAndGA(activite.client.id, activite.groupeActivite.id);
                    return response.data;
                } catch (e) {
                    toast.error("Une erreur est survenue");
                    console.log(e);
                }
            } else {
                return [];
            }
        }
    }

    // const fetchFaiteByClientAndActivite = async (activite) => {
    //     if (activite !=undefined && activite.client != undefined && activite.activite != undefined){
    //         try {
    //             const response = await FaiteAPI.fetchFaiteByClientAndActivite(activite.client.id, activite.activite.id);
    //             setActivite({...activite, ["nbrsalarietheorique"]: response.data.nombreSalarie})
    //         } catch (e) {
    //             console.log(e)
    //         }
    //     }
    // }

    const setOptionClient = async (e) => {
        if (e != undefined) {
            let options = [];
            e.map((em) => {
                options.push({value: em, label: em.nom})
            })
            setClients(options)
        }
    }

    const setOptionActivites = async (e) => {
        if (e != undefined) {
            let options = [];
            e.map((em) => {
                options.push({value: em, label: em.libelle})
            })
            setActivites(options)
        }
    }

    const setOptionEncadrant = (data) => {
        let options = [];
        data.map((e) => {
            options.push({value: e, label: e.nom +" " + e.prenom})
        });
        setEncadrants(options)
    }

    const removeEmployesForPlannif = (rowSelected) => {
        activite.employes = activite.employes.filter(emp => emp.em_numerosecuritesocial != rowSelected.em_numerosecuritesocial);
        setActivite({...activite, nombreSalarie: activite.employes.length})
        setErrorMessages(activite.employes)
    }

    const handleChangeDateDebut = (e) => {
        setHeuresDebut(e);
        const hours = moment(e["_d"]).format('HH')
        const minutes = moment(e["_d"]).format('mm')
        setActivite({...activite, heureDebut: [hours, minutes]})

    }
    const handleChangeDateFin = (e) => {
        setHeuresFin(e)
        const hours = moment(e["_d"]).format('HH')
        const minutes = moment(e["_d"]).format('mm')
        setActivite({...activite, heureFin: [hours, minutes]})
    }

    const handleSubmit = async () => {
        try {
            let newEmploye = [];
            activite.dateDebut = moment(moment(activite.dateDebut).format("YYYY-MM-DD") + "T" + moment(heuresDebut["_d"]).locale("fr").format("HH:mm:ss")).locale('fr').format("YYYY-MM-DDTHH:mm:ssZ")
            activite.dateFin = moment(moment(activite.dateFin).format("YYYY-MM-DD") + "T" + moment(heuresFin["_d"]).locale("fr").format("HH:mm:ss")).locale('fr').format("YYYY-MM-DDTHH:mm:ssZ")
            if (toValidate === true){
                activite.etat = "ok";
            }else{
                activite.etat = "danger";
            }
            if (activite.employes != undefined && activite.employes != null && activite.employes.length > 0) {
                activite.employes = await EmployeAPI.getEmployesByIDS(activite.employes);
                if (activite.employes == null && activite.employes.length <= 0) {
                    activite.nombreSalarie = 0;
                }
            }else{
                activite.nombreSalarie = 0;
            }

            let newActivites = [];
            if (activite.activites.length > 0 ){
                activite.activites.map((a) => {
                    newActivites.push(a.value)
                })
            }else{
                newActivites.push(activite.activiteParent.value)
            }
            activite.activites = newActivites;
            setTimeout(async () => {
                let response = await PlanningActiviteAPI.update(activite);
                toast.success("L'activité a bien été modifié")
                setTimeout(async () => {
                    fetchPlanningActivite();
                }, 500)
            }, 1000)

        } catch (e) {
            toast.error("L'activité n'a pas bien été modifié")
            console.log(e)
        }
    }

    const deleteOnePlanning = async () => {
        try {
            const response = await planningActiviteAPI.deleteP(activite.id)
            toast.success("La plannification a bien été supprimé")
        } catch (e) {
            console.log(e)
        }
    }

    const handleDelete = async () => {
        try {
            if (activite.recurrence == false) {
                await  deleteOnePlanning()
            }else if(activite.recurrence == true){
                setIsOpenDelete(true)
            }

        } catch (e) {
            console.log(e)
        }
    }

    const handleDeleteRecurrence = async () => {
        try {
            const response = await planningActiviteAPI.deleteRecurrence(activite.id)
            await deleteOnePlanning()
        } catch (e) {
            console.log(e)
        }
    }


    useEffect(() => {
        fetchPlannification().then((res) => {
            // fetchFaiteByClientAndActivite(res);
        })
    }, [activiteC]);

    useEffect(() => {
        fetchClient().then(res => setOptionClient(res))
    }, [activite.groupeActivite]);

    useEffect(() => {
        fetchActiviteByClientAndGroupeActivite();
    }, [activite.activite]);

    useEffect(() => {
        fetchActiviteByClientAndGroupeActivite().then(res => setOptionActivites(res))
    }, [activite.client]);

    useEffect(() => {
        fetchEncadrant().then(res => setOptionEncadrant(res))
    }, [activite.localisation]);

    useEffect(() => {
        let error = false;
        errorMessages.map(err => {
            if (err.dejaplanifie != "false" || err.isindisponible != "false" || err.donthavehabilitation != "false"|| err.workplanning != "false" ){
                error = true;
            }
        })
        if (activite.nombreSalarie != undefined && activite.faites  != undefined) {
            if (activite.nombreSalarie >= activite.faites.nombreSalarie && error == false){
                setToValidate(true)
            }else{
                setToValidate(false)
            }
        }
    }, [activite, errorMessages, activite.nombreSalarie]);

    return <>
    <Modal open={isOpen}
                  onClose={() => {
                      setIsOpen(!isOpen)
                      setToValidate(false)
                  }}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h5" component="h2"
                        className={"d-flex justify-content-center p-2 border-bottom"}>
                Planifier une Activité
            </Typography>
            {loading == true ? <Loading /> : <>
            <div className={"form-modal flex flex-column gap-2"}>
                <div className={"mb-2"}>
                    <SelectInput classNameLabel={"text-end mr-2 pr-5"} required={true} label={"Groupe Activité"}
                                 placeholder={"Groupe Activité"} flex={"row"} options={groupeActivites}
                                 name={"groupeActivite"} value={activite.groupeActivite && {
                        value: activite.groupeActivite,
                        label: activite.groupeActivite.libelle
                    }} objet={activite} setObjet={setActivite} disabled={true}/>
                </div>
                {activite.groupeActivite && <>
                    <div className={"mb-2"}>
                        <SelectInput classNameLabel={"text-end mr-2 pr-5"} required={true} type={"select"}
                                     name={"client"} flex={"row"} placeholder={"Client"} options={clients}
                                     value={activite.client && {value: activite.client, label: activite.client.nom}}
                                     objet={activite} setObjet={setActivite}
                                     label={"Client"} disabled={true}></SelectInput>
                    </div>
                </>}
                {(activite.activiteParent && activite.client )  && <>
                    <div className={"mb-2"}>
                        <SelectInput  classNameLabel={"text-end mr-2 pr-5"} required={true}
                                     type={"select"}
                                     name={"activiteParent"} flex={"row"} placeholder={"Activite"}
                                     objet={activite} setObjet={setActivite}
                                     label={"Activite"} options={activites}
                                     value={activite.activiteParent}
                                      disabled={true}
                        ></SelectInput>
                    </div>
                    <div className="mb-2">
                        <SelectInput isMulti={true} classNameLabel={"text-end mr-2 pr-5"} required={true}
                                     type={"select"}
                                     name={"activites"} flex={"row"} placeholder={"Sous-Activité niv1"}
                                     objet={activite} setObjet={setActivite}
                                     label={"Sous-Activité niv1"} options={activites}
                                     value={activite.activites}
                        ></SelectInput>
                    </div>
                </>}
                {(activite.client && activite.activiteParent == undefined) && <>
                    <div className={"mb-2"}>

                        <SelectInput isMulti={true} classNameLabel={"text-end mr-2 pr-5"} required={true}
                                     type={"select"}
                                     name={"activites"} flex={"row"} placeholder={"Activite"}
                                     objet={activite} setObjet={setActivite}
                                     label={"Activite"} options={activites}
                                     value={activite.activites}
                        ></SelectInput>
                        {/*<SelectInput classNameLabel={"text-end mr-2 pr-5"} required={true} type={"select"}*/}
                        {/*             name={"activite"} flex={"row"} placeholder={"Activite"}*/}
                        {/*             value={activite.activite && {*/}
                        {/*                 value: activite.activite,*/}
                        {/*                 label: activite.activite.libelle*/}
                        {/*             }}*/}
                        {/*             objet={activite} setObjet={setActivite} label={"Activite"}*/}
                        {/*             options={activites} ></SelectInput>*/}
                    </div>
                </>}
                {activite.activites && <>
                    <div className={"mb-2"}>
                        <SelectInput classNameLabel={"text-end mr-2 pr-5"} required={false} type={"select"}
                                     name={"prestataire"} flex={"row"} placeholder={"Prestataire"}
                                     value={activite.prestataire && {
                                         value: activite.prestataire,
                                         label: activite.prestataire.nom
                                     }} objet={activite} setObjet={setActivite}
                                     label={"Prestataire"} options={prestataires}></SelectInput>
                    </div>
                    <div className={"mb-2"}>
                        <SelectInput classNameLabel={"text-end mr-2 pr-5"} required={false} type={"select"}
                                     name={"localisation"} flex={"row"} placeholder={"Localisation"}
                                     value={activite.localisation && {
                                         value: activite.localisation,
                                         label: activite.localisation
                                     }} objet={activite} setObjet={setActivite}
                                     label={"Localisation"} options={localisation} disabled={true}></SelectInput>
                    </div>
                </>}
                {activite.localisation && <>
                    <div className={"mb-2"}>
                        <Input classNameLabel={"text-end mr-2 pr-5"} required={false} type={"number"}
                               name={"nbrsalarietheorique"} flex={"row"} placeholder={"Nb Salariés théorique"}
                               value={activite.faites.nombreSalarie} objet={activite} setObjet={setActivite}
                               label={"Nb Salariés théorique"} disabled={true}></Input>
                    </div>
                    <div className={"mb-2"}>
                        <Input classNameLabel={"text-end mr-2 pr-5"} required={false} type={"text"}
                               name={"coordinateur"} flex={"row"} placeholder={"Coordinateur"}
                               value={activite.coordinateur != undefined && activite.coordinateur.nom + " " + activite.coordinateur.prenom}
                               label={"Coordinateur"} disabled={true}></Input>
                    </div>
                    <div className={"mb-2 form-group flex"}>
                        <label htmlFor={"dateDebut"} className={"form-label mt-1 text-end col-5 pr-5"}
                               style={{width: "41.66666667%"}}>{"Date Début"} * :</label>
                        <div className={"pl-2 col-6"} id={"dateDebut"}>
                            <DatePicker name={"dateDebut"} objet={activite}
                                        setObjet={setActivite} objectIsMoment={false}
                                        value={(activite.dateDebut != null && activite.dateDebut != undefined) ? moment(activite.dateDebut) : null}/>
                        </div>
                        <div className={"pl-2 col-6 justify-content-end"}>
                            <MobileTimePicker label={'Heures'} openTo="hours" className={"datePicker"}
                                              onChange={handleChangeDateDebut} ampm={false} value={heuresDebut}/></div>
                    </div>
                    <div className={"mb-2 form-group flex"}>
                        <label htmlFor={"dateFin"} className={"form-label mt-1 text-end col-5 pr-5"}
                               style={{width: "41.66666667%"}}>{"Date fin"} * :</label>
                        <div className={"pl-2 col-6"} id={"dateFin"}>
                            <DatePicker name={"dateFin"} objet={activite}
                                        setObjet={setActivite} objectIsMoment={false}
                                        value={(activite.dateFin != null && activite.dateFin != undefined) ? moment(activite.dateFin) : null}/>
                        </div>
                        <div className={"pl-2 col-6 justify-content-end"}>
                            <MobileTimePicker label={'Heures'} openTo="hours" className={"datePicker"}
                                              onChange={handleChangeDateFin} ampm={false} value={heuresFin}/>
                        </div>
                    </div>
                    <div className={"mb-2 form-group flex"}>
                        <label htmlFor={"recurrence"} className={"form-label mt-1 text-end col-5 pr-5"}
                               style={{width: "41.66666667%"}}>Recurrence :</label>
                        <Switch id={"recurrence"} checked={activite.isReccurente ? activite.isReccurente : false} disabled={true}/>
                    </div>
                </>}
                {activite.dateDebut != undefined && activite.dateFin != undefined && <>
                    {/* Encadrant */}
                    <SelectInput
                        classNameLabel={"text-end mr-2 pr-5"}
                        label="Encadrant"
                        name={"encadrant"}
                        options={encadrants}
                        value={activite.encadrant && {value: activite.encadrant, label: activite.encadrant.nom + " "+activite.encadrant.prenom }}
                        objet={activite}
                        setObjet={setActivite}
                        required={true}
                    />
                    <div className={"mb-2"}>
                        <div className={"d-flex justify-content-end"}>
                            <button className={"btn btn-alliance"} onClick={() => {
                                setIsOpenPlanifEmploye(true);
                                setIsOpen(false)
                            }}>
                                Planifier les employés
                            </button>
                        </div>
                        <div className={"flex justify-content-center"}>
                            {(activite.nombreSalarie < activite.faites.nombreSalarie) ? <>
                                <Badge bg={"danger"}
                                       pill>{activite.nombreSalarie + ' planifié sur ' + activite.faites.nombreSalarie + " théorique"}</Badge>
                            </> : <>
                                <Badge bg={"success"}
                                       pill>{activite.nombreSalarie + ' planifié sur ' + activite.faites.nombreSalarie + " théorique"}</Badge>
                            </>}
                        </div>
                    </div>
                    {errorMessages.length > 0 && errorMessages.map(err => {
                        return err.dejaplanifie == "false" && err.isindisponible == "false" && err.donthavehabilitation == "false" && err.workplanning == "false" ? <div className={"d-flex "}>
                            <Badge key={err.em_nom+"success"} bg={"success"} className={"col-11 mr-3"} pill>{err.em_nom +" " + err.em_prenom }</Badge>
                            <div className="col-1">
                                <Tooltip title={"Retirer cette employé de la planification"}>
                                    <button className="btn-sm btn-refresh ml-1 rounded" onClick={() => {removeEmployesForPlannif(err)}}>
                                        <SvgIcon sx={{color: "#FFFFFF"}}>
                                            <Close />
                                        </SvgIcon>
                                    </button>
                                </Tooltip>
                            </div>
                        </div> : <>
                            {err.dejaplanifie != "false" && <div className={"d-flex "}>
                                <Badge key={err.dejaplanifie} bg={"danger"} className={"col-11 mr-3"}
                                       pill>{err.em_nom + " " + err.em_prenom + " => " + err.dejaplanifie}</Badge>
                                <div className="col-1">
                                    <Tooltip title={"Retirer cette employé de la planification"}>
                                        <button className="btn-sm btn-refresh ml-1 rounded" onClick={() => {
                                            removeEmployesForPlannif(err)
                                        }}>
                                            <SvgIcon sx={{color: "#FFFFFF"}}>
                                                <Close/>
                                            </SvgIcon>
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                            }
                            {
                                err.isindisponible != "false" && <div className={"d-flex "}>
                                    <Badge key={err.isindisponible} bg={"danger"} className={"col-11 mr-3"}
                                           pill>{err.em_nom + " " + err.em_prenom + " => " + err.isindisponible}</Badge>
                                    <div className="col-1">
                                        <Tooltip title={"Retirer cette employé de la planification"}>
                                            <button className="btn-sm btn-refresh ml-1 rounded" onClick={() => {
                                                removeEmployesForPlannif(err)
                                            }}>
                                                <SvgIcon sx={{color: "#FFFFFF"}}>
                                                    <Close/>
                                                </SvgIcon>
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>
                            }
                            {
                                err.donthavehabilitation != "false" && <div className={"d-flex "}>
                                    <Badge key={err.donthavehabilitation} bg={"danger"} className={"col-11 mr-3"}
                                           pill>{err.em_nom + " " + err.em_prenom + " => " + err.donthavehabilitation}</Badge>
                                    <div className="col-1">
                                        <Tooltip title={"Retirer cette employé de la planification"}>
                                            <button className="btn-sm btn-refresh ml-1 rounded" onClick={() => {
                                                removeEmployesForPlannif(err)
                                            }}>
                                                <SvgIcon sx={{color: "#FFFFFF"}}>
                                                    <Close/>
                                                </SvgIcon>
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>
                            }
                            {
                                err.workplanning != "false" && <div className={"d-flex "}>
                                    <Badge key={err.workplanning} bg={"danger"} className={"col-11 mr-3"}
                                           pill>{err.em_nom + " " + err.em_prenom + " => " + err.workplanning}</Badge>
                                    <div className="col-1">
                                        <Tooltip title={"Retirer cette employé de la planification"}>
                                            <button className="btn-sm btn-refresh ml-1 rounded" onClick={() => {
                                                removeEmployesForPlannif(err)
                                            }}>
                                                <SvgIcon sx={{color: "#FFFFFF"}}>
                                                    <Close/>
                                                </SvgIcon>
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>
                            }
                        </>
                    })}
                    <div className={"mb-2"}>
                        <TextAreaInput classNameLabel={"text-end mr-2 pr-5"} required={false} name={"commentaire"}
                                       flex={"row"} placeholder={"Commentaire"} value={activite.commentaire}
                                       objet={activite} setObjet={setActivite} label={"Commentaire"}></TextAreaInput>
                    </div>
                </>}
            </div>
                <div id="modal-modal-footer" className={"d-flex-column p-2 border-top d-flex justify-content-between"}>
                    <div>
                            <button className={"btn btn-danger"} onClick={()=> {
                                setIsOpen(false)
                                if(activite.recurrence == true) {
                                    setIsOpenDelete(true)
                                }else {
                                    setIsOpenConfirmDelete(true)
                                }
                                // handleDelete().then(fetchPlanningActivite)
                            }}>
                                Supprimer la plannification
                            </button>
                    </div>
                    <div>
                        {toValidate === false ?
                            <div className={"d-flex justify-content-end gap-3"}>
                            <button className={"btn btn-secondary"} onClick={() => {
                                setActivite({})
                                setIsOpen(false)
                                setLoading(true)
                                setToValidate(false)
                                setEventSelected({});
                            }}>
                            Annuler
                            </button>
                            <button className={"btn btn-alliance"} onClick={() => {
                            setIsOpen(false)
                            setActivite({nombreSalarie : 0})
                            handleSubmit().then(fetchPlanningActivite)}
                        }>
                            <SvgIcon sx={{color: Color.white, height: 1}}>
                            <Save/>
                            </SvgIcon>
                            Sauvegarder malgré les avertissements
                            </button>
                            </div>
                            :
                            <div className={"d-flex justify-content-end gap-3 mt-3"}>
                            <button className={"btn btn-secondary"} onClick={() => {
                            setActivite({})
                            setIsOpen(false)
                            setToValidate(false)
                                setEventSelected({});

                            }}>
                            Annuler
                            </button>
                            <button className={"btn btn-alliance"} onClick={() => {
                            setIsOpen(false)
                            setActivite({nombreSalarie : 0})
                                setEventSelected({});

                                handleSubmit().then(fetchPlanningActivite)
                        }}>
                            <SvgIcon sx={{color: Color.white, height: 1}}>
                            <Save/>
                            </SvgIcon>
                            Sauvegarder
                            </button>
                            </div>
                        }
                    </div>
                </div>
            </>}
        </Box>
    </Modal>
        <Modal open={isOpenConfirmDelete}
               onClose={() => {
                   setIsOpenConfirmDelete(!isOpenConfirmDelete)
                   setToValidate(false)
               }}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description">
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h5" component="h2" className={"d-flex justify-content-center p-2 border-bottom"}>
                    Suppresion d'une plannification
                </Typography>
                <div id="modal-modal-body">
                    Etes vous sure de vouloir supprimer la plannification
                </div>
                <div id="modal-modal-footer" className={"d-flex-column p-2 border-top d-flex justify-content-between"}>
                    <button className={"btn btn-secondary"} onClick={()=> {
                        setIsOpenConfirmDelete(false)
                        setIsOpen(true)
                    }}>
                        Non
                    </button>
                    <div className={"d-flex gap-3"}>
                        <button className={"btn btn-alliance"} onClick={()=> {
                            setIsOpenConfirmDelete(false)
                            setEventSelected({});
                            setActivite({nombreSalarie : 0})
                            handleDelete().then(fetchPlanningActivite)
                        }}>
                            Oui
                        </button>
                    </div>
                </div>
            </Box>
        </Modal>
    <Modal open={isOpenDelete}
           onClose={() => {
               setIsOpenDelete(!isOpenDelete)
               setToValidate(false)
           }}
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h5" component="h2" className={"d-flex justify-content-center p-2 border-bottom"}>
                Planifier une Activité
            </Typography>
                <div id="modal-modal-body">
                    Voulez-vous supprimer tous les évenements suivants
                </div>
                <div id="modal-modal-footer" className={"d-flex-column p-2 border-top d-flex justify-content-between"}>
                    <button className={"btn btn-secondary"} onClick={()=> {
                        setIsOpenDelete(false)
                        setIsOpen(true)
                    }}>
                            Annuler
                    </button>
                    <div className={"d-flex gap-3"}>
                        <button className={"btn btn-danger"} onClick={()=> {
                            setIsOpenDelete(false)
                            setActivite({nombreSalarie : 0})
                            setEventSelected({});
                            deleteOnePlanning().then(fetchPlanningActivite)
                        }}>
                            Non
                        </button>
                        <button className={"btn btn-alliance"} onClick={()=> {
                            setIsOpenDelete(false)
                            setEventSelected({});
                            setActivite({nombreSalarie : 0})
                            handleDeleteRecurrence().then(fetchPlanningActivite)
                        }}>
                            Oui
                        </button>
                    </div>


                </div>
        </Box>
    </Modal>
    <ModalEmployePlanificationEdit isOpen={isOpenPlanifEmploye} setIsOpen={setIsOpenPlanifEmploye} setIsOpenModalActivite={setIsOpen} activite={activite}  setActivite={setActivite} heuresFin={heuresFin} heuresDebut={heuresDebut} setErrorMessages={setErrorMessages}/>

</>
};

export default ModalActiviteEdit;