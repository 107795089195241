import React, {useEffect, useState} from 'react';
import "../../Styles/planning.css";
import {Accordion, AccordionDetails, AccordionSummary, CircularProgress, IconButton, Tooltip} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import GenericDataTable from "../../Components/DataTables/GenericDataTable";
import {Visibility} from "@mui/icons-material";
import Header2PlanningVerification from "../../Components/Headers/Header2PlanningVerification";
import {EmployeAPI} from "../../Services/EmployeAPI";
import {toast} from "react-toastify";
import moment from "moment";
import Modal from '@mui/material/Modal';
import {modalStyle} from "../../Styles/ModalStyle";
import Box from "@mui/material/Box";
import configFunction from '../../config/index';

const Verification = () => {
    const [dateDebut, setDateDebut] = useState(null);
    const [dateFin, setDateFin] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [edit, setEdit] = useState(false);
    const [dataGroups, setDataGroups] = useState([]);
    const [dataGroupsHours, setDataGroupsHours] = useState([]);
    const [userDetails, setUserDetails] = useState(null);

    const showGroup = async (id) => {
        try {
            const employe = await EmployeAPI.getEmployeById(id);
            setUserDetails(employe);
            setIsOpen(true);
        } catch (e) {
            console.error("Erreur lors de la récupération des informations de l'employé:", error);
        }
    };

    const [columnGroups, setColumnGroups] = useState([
        {
            name: "Nom",
            selector: row => row.em_nom + " " + row.em_prenom
        },{
            name: "Temps non planifié",
            selector: row => {
                return row.tempsrestant.hours >= 0 ? moment(moment().format("YYYY-MM-DD") + ' '+ row.tempsrestant.hours +':'+row.tempsrestant.minutes).format("HH:mm") : row.tempstravail ? moment(moment().format("YYYY-MM-DD") + ' '+ "00" +':'+row.tempstravail.minutes).format("HH:mm") : "00:00"
            }
        },
        {
            name: "Actions",
            selector: row => <div className="d-flex justify-content-start">
                <Tooltip title={"Voir l'utilisateur"} placement={"right"}>
                    <IconButton onClick={() => showGroup(row.id)}>
                        <Visibility color="warning"/>
                    </IconButton>
                </Tooltip>
            </div>
        }
    ]);

    const [columnGroupsHeures, setColumnGroupsHeures] = useState([
        {
            name: "Salariés",
            selector: row => row.salarie
        },
        {
            name: "Heures",
            selector: (row) => {
                let minutes = row.diff.minutes.toString().length < 2 ? row.diff.minutes.toString() + '0' : row.diff.minutes
                let hours = row.diff.hours.toString().length < 2 ? '0' + row.diff.hours.toString() : row.diff.hours
                return hours + ":" + minutes
            }
        },
        {
            name: "Actions",
            selector: row => <div className="d-flex justify-content-start">
                <Tooltip title={"Voir l'utilisateur"} placement={"right"}>
                    <IconButton onClick={() => showGroup(row.id)}>
                        <Visibility className={"action-icon"} color="warning"/>
                    </IconButton>
                </Tooltip>
            </div>
        }
    ]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const fetchEmploye = async () => {
        if (dateDebut != undefined && dateFin != undefined) {
            try {
                const res = await EmployeAPI.getEmployesNonPlanifier(moment(dateDebut).format("YYYY-MM-DD"), moment(dateFin).format("YYYY-MM-DD"));
                setDataGroups(res.data.sort((a, b) => a.em_nom.localeCompare(b.em_nom)));
                setLoading(false);
            } catch (e) {
                console.log(e);
            }
        } else {
            toast.error("Veuillez sélectionner vos dates");
        }
    };

    const fetchHoursEmploye = async () => {
        if (dateDebut != undefined && dateFin != undefined) {
            try {
                const res = await EmployeAPI.getEmployesHeuresPlanifie(moment(dateDebut).format("YYYY-MM-DD"), moment(dateFin).format("YYYY-MM-DD"));
                setDataGroupsHours(res.data.sort((a, b) => a.salarie.localeCompare(b.salarie)));
                setLoading(false);
            } catch (e) {
                console.log(e);
            }
        } else {
            toast.error("Veuillez sélectionner vos dates");
        }
    };

    useEffect(() => {
        if (expanded !== false) {
            setLoading(true);
            if (expanded === 'salaries') {
                fetchEmploye().then(r => '');
            } else if (expanded === 'heuresPlanifie') {
                fetchHoursEmploye().then(r => '');
            }
        }
    }, [expanded, dateDebut, dateFin]);

    return (
        <>
            <Header2PlanningVerification dateFin={dateFin} setDateFin={setDateFin} dateDebut={dateDebut}
                                         setDateDebut={setDateDebut}/>
            <div className="col-11 mx-auto content-accordion">
                <Accordion expanded={expanded === 'salaries'} onChange={handleChange('salaries')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{width: '33%', flexShrink: 0}}>
                            Salariés non planifiés
                        </Typography>
                        <Typography sx={{color: 'text.secondary'}}>Liste des salariés non planifiés</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        {loading ? (
                            <div className="text-center"><CircularProgress color={"primary"}/></div>
                        ) : (
                            <GenericDataTable noDataText={"Aucun employes trouvé"} setIsOpen={setIsOpen}
                                              columnItem={columnGroups} data={dataGroups} setReload={setReload}/>
                        )}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'heuresPlanifie'} onChange={handleChange('heuresPlanifie')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <Typography sx={{width: '33%', flexShrink: 0}}>Heures planifiées</Typography>
                        <Typography sx={{color: 'text.secondary'}}>Liste des employés avec les heures
                            effectuées</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {loading ? (
                            <div className="text-center"><CircularProgress color={"primary"}/></div>
                        ) : (
                            <GenericDataTable noDataText={"Aucun employes trouvé"} setIsOpen={setIsOpen}
                                              columnItem={columnGroupsHeures} data={dataGroupsHours}
                                              setReload={setReload}/>
                        )}
                    </AccordionDetails>
                </Accordion>
            </div>

            {userDetails && (
                <Modal open={isOpen} onClose={() => setIsOpen(false)} aria-labelledby="modal-modal-title"
                       aria-describedby="modal-modal-description">
                    <Box sx={modalStyle}>
                        <Typography id="modal-modal-title" variant="h5" component="h2"
                                    className={"d-flex justify-content-center p-2 border-bottom"}>
                            Détails de l'Utilisateur
                        </Typography>
                        <div className={"form-modal flex flex-column gap-2"}>
                            <div className="user-details">
                                <h2>{userDetails.nom}</h2>
                                <p>{userDetails.adresse}</p>
                                <p>{userDetails.ville}</p>
                                <p>{userDetails.codePostal}</p>
                                <p>{userDetails.numero}</p>
                                <p>{userDetails.mail}</p>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button onClick={() => setIsOpen(false)}>Fermer</button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            )}
        </>
    );
};

export default Verification;
