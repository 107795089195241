import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Input from "../../FormsComponents/Input";
import {CircularProgress} from "@mui/material";
import {toast} from "react-toastify";
import {TypeEmployeAPI} from "../../../Services/TypeEmployeAPI";
import {EmployeAPI} from "../../../Services/EmployeAPI";
import {modalStyle} from "../../../Styles/ModalStyle";
import DatePicker from "../../DatePicker";
import moment from "moment";
import SelectInput from "../../FormsComponents/SelectInput";

const ModalUser = ({
                       isOpen,
                       setIsOpen,
                       fetchUtilisateurs
                   }) => {
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [typeEmploye, setTypeEmploye] = useState([]);
    const [optionCivilite, setOptionCivilite] = useState([
        {value : "Mr", label : "Mr"},
        {value : "Mme", label : "Mme"}
    ]);
    const handleCloseModal = () => {
        setIsOpen(!isOpen);
        setUser({});
    }
    const fetchUser = async (id) => {
        let data = await EmployeAPI.getEmployeById(id);
        setUser(data);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (user.numeroSecuriteSocial.length != 15) {
            toast.error("Le numéro de sécurité sociale doit contenir 15 caractères")
            return;
        } else {
            try {
                user.isActif = true;
                await EmployeAPI.createEmploye(user);
                toast.success("L'employé a bien été créé");
                handleCloseModal();
                fetchUtilisateurs()
            } catch (e) {
                toast.error("Une erreur est survenue");
            }
        }

    }

    const fetchTypeEmployes = async () => {
        let data = await TypeEmployeAPI.getAllTypeEmploye();
        return data.data;

    }

    const setOptionsFetchEmploye = (typeEmploye) => {
        let options = [];
        typeEmploye.map((e) => {
            options.push({value: e, label: e.libelle})
        });
        setTypeEmploye(options)
    }

    useEffect(() => {
        fetchTypeEmployes().then(res => {
            setOptionsFetchEmploye(res)
        })
    }, []);


    return (
        <Modal
            open={isOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h5" component="h2"
                            className={"d-flex justify-content-center p-2 border-bottom"}>
                    Créer un employé
                </Typography>
                <div className={"form-modal flex flex-column gap-2"}>
                    {loading ? (
                        <div className={"text-center"}>
                            <CircularProgress color={"primary"}/>
                        </div>
                    ) : (
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className="my-2">
                                    <Input
                                        objet={user}
                                        setObjet={setUser}
                                        required={true}
                                        type={"text"}
                                        name={"nom"}
                                        label={"Nom"}
                                        value={user.nom || ''}
                                        placeholder={"Nom de l'employé"}
                                        onChange={(e) => setUser({...user, nom: e.target.value})}
                                        toUppercase={true}
                                    />
                                </div>
                                <div className="my-2">
                                    <Input
                                        objet={user}
                                        setObjet={setUser}
                                        required={true}
                                        type={"text"}
                                        name={"prenom"}
                                        label={"Prénom"}
                                        value={user.prenom || ''}
                                        placeholder={"Prénom de l'employé"}
                                        onChange={(e) => setUser({...user, prenom: e.target.value})}
                                        toUppercase={true}
                                    />
                                </div>
                                <div className="my-2">
                                    <SelectInput label={"Civilité"} options={optionCivilite} objet={user} setObjet={setUser} value={user.civilite && {value : user.civilite, label : user.civilite}} placeholder={"Civilite  de l'employé"} name={"civilite"} />
                                </div>
                                <div className="my-2">
                                    <Input
                                        objet={user}
                                        setObjet={setUser}
                                        required={true}
                                        type={"text"}
                                        name={"adresse"}
                                        label={"Adresse"}
                                        value={user.adresse || ''}
                                        placeholder={"Adresse de l'employé"}
                                        onChange={(e) => setUser({...user, adresse: e.target.value})}
                                        toUppercase={true}
                                    />
                                </div>
                                <div className="my-2">
                                    <Input
                                        objet={user}
                                        setObjet={setUser}
                                        required={false}
                                        type={"text"}
                                        name={"complementAdresse"}
                                        label={"Complement Adresse"}
                                        value={user.complementAdresse || ''}
                                        placeholder={"Complement Adresse de l'employé"}
                                        toUppercase={true}
                                    />
                                </div>
                                <div className="my-2">
                                    <Input
                                        objet={user}
                                        setObjet={setUser}
                                        required={true}
                                        type={"text"}
                                        name={"ville"}
                                        label={"Ville"}
                                        value={user.ville || ''}
                                        placeholder={"Ville de l'employé"}
                                        onChange={(e) => setUser({...user, ville: e.target.value})}
                                        toUppercase={true}
                                    />
                                </div>
                                <div className="my-2">
                                    <Input
                                        objet={user}
                                        setObjet={setUser}
                                        required={true}
                                        type={"number"}
                                        name={"codePostal"}
                                        label={"Code postal"}
                                        value={user.codePostal || ''}
                                        placeholder={"Code postal de l'employé"}
                                        onChange={(e) => setUser({...user, codePostal: e.target.value})}
                                        toUppercase={true}
                                    />
                                </div>
                                <div className="my-2">
                                    <Input
                                        objet={user}
                                        setObjet={setUser}
                                        required={false}
                                        type={"mail"}
                                        name={"mail"}
                                        label={"Mail"}
                                        value={user.mail || ''}
                                        placeholder={"Adresse mail de l'employé"}
                                        onChange={(e) => setUser({...user, mail: e.target.value})}
                                    />
                                </div>
                                <div className="my-2">
                                    <Input
                                        objet={user}
                                        setObjet={setUser}
                                        required={true}
                                        type={"number"}
                                        name={"numeroSecuriteSocial"}
                                        label={"Numéro de sécurité sociale"}
                                        value={user.numeroSecuriteSocial || ''}
                                        placeholder={"Numéro de sécurité social de l'employé"}
                                        onChange={(e) => {
                                            setUser({...user, numeroSecuriteSocial: e.target.value})
                                        }
                                        }
                                    />
                                </div>
                                <div className={"mb-2 form-group d-flex"}>
                                    <label htmlFor={"dateNaissance"} className={"form-label mt-1 text-start col-5"}
                                           // style={{width: "41.66666667%"}}
                                    >{"Date de naissance"} * :</label>
                                    <DatePicker label={"Date de Naissance"} name={"dateNaissance"}
                                                style={{width: "58.4444443%"}}
                                                className={"col-12"}
                                                objet={user}
                                                setObjet={setUser}
                                                value={user.dateNaissance != undefined ? moment(user.dateNaissance) : null}
                                                objectIsMoment={false}/>
                                </div>
                                <div className="my-2">
                                    <SelectInput
                                        objet={user}
                                        setObjet={setUser}
                                        required={true}
                                        name={"typeEmploye"}
                                        label={"Fonction"}
                                        value={user.typeEmploye && {
                                            value: user.typeEmploye,
                                            label: user.typeEmploye.libelle
                                        }}
                                        options={typeEmploye}
                                        placeholder={"Fonction de l'employé"}
                                    />
                                </div>
                                <div className={"mb-2 form-group d-flex"}>
                                    <label htmlFor={"dateEntree"} className={"form-label mt-1 text-start col-5"}
                                        // style={{width: "41.66666667%"}}
                                    >{"Date d'entrée"} * :</label>
                                    <DatePicker label={"Date d'entrée"} name={"dateEntree"}
                                                style={{width: "58.4444443%"}}
                                                className={"col-12"}
                                                objet={user}
                                                setObjet={setUser}
                                                value={user.dateEntree != undefined ? moment(user.dateEntree) : null}
                                                objectIsMoment={false}/>
                                </div>
                                <div className={"mb-2 form-group d-flex"}>
                                    <label htmlFor={"dateSortie"} className={"form-label mt-1 text-start col-5"}
                                        // style={{width: "41.66666667%"}}
                                    >{"Date de sortie"}  :</label>
                                    <DatePicker label={"Date de sortie"} name={"dateSortie"}
                                                style={{width: "58.4444443%"}}
                                                className={"col-12"}
                                                objet={user}
                                                setObjet={setUser}
                                                value={user.dateSortie != undefined ? moment(user.dateSortie) : null}
                                                objectIsMoment={false}/>
                                </div>
                                <div className={"d-flex justify-content-end"}>
                                    <div className="d-flex justify-content-between col-3 me-2 mt-2">
                                        <button className={"btn btn-secondary m-2"} onClick={handleCloseModal}>
                                            Annuler
                                        </button>
                                        <button className={"btn btn-add-activity m-2"} type={"submit"}>Enregistrer
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </Box>
        </Modal>
    );
};

export default ModalUser;
