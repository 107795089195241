import React, {useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import '../../../Styles/ModalForm.css';
import {modalStyle} from "../../../Styles/ModalStyle";
import SelectInput from "../../FormsComponents/SelectInput";
import {MobileTimePicker} from "@mui/x-date-pickers";
import moment from "moment";

const ModalPlanningHoraireAddDay = ({isOpen, setIsOpen, planning, updatePlanning}) => {
    const [heuresDebut, setHeuresDebut] = useState(null);
    const [heuresFin, setHeuresFin] = useState(null)
    const [jour, setJour] = useState({});
    const [options, setOptions] = useState([
        {value : "lundi", label : "Lundi"},
        {value : "mardi", label : "Mardi"},
        {value : "mercredi", label : "Mercredi"},
        {value : "jeudi", label : "Jeudi"},
        {value : "vendredi", label : "Vendredi"},
        {value : "samedi", label : "Samedi"},
        {value : "dimanche", label : "Dimanche"}
    ]);
    const handleCloseModal = () => {
        setIsOpen(!isOpen);
    }

    const handleChangeDateDebut = (e) => {
        setHeuresDebut(e);
        const date = e["_d"]
        setJour({...jour, heureDebut: moment(date).format("HH:mm")})
    }

    const handleChangeDateFin = (e) => {
        setHeuresFin(e);
        const date = e["_d"]
        setJour({...jour, heureFin: moment(date).format("HH:mm")})
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            let newPlanning = {};
            let planningData = JSON.parse(planning.jours)
            planningData.push(jour);
            newPlanning.id = planning.id
            newPlanning.jours = JSON.stringify(planningData)
            newPlanning.heureDebut = null
            newPlanning.heureFin = null
            updatePlanning(newPlanning)
            setIsOpen(false)
        }catch (e){
            console.log(e)
        }
    }

    return <Modal open={isOpen}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h5" component="h2"
                        className={"d-flex justify-content-center p-2 border-bottom"}>
                Créer un Planning Horaire
            </Typography>
            <div className={"form-modal flex flex-column gap-2"}>
                <form onSubmit={handleSubmit}>
                <SelectInput classNameLabel={"text-end mr-2 pr-5"} required={true} label={"Jour"}
                             placeholder={"Jour"} flex={"row"} options={options}
                             name={"jour"} value={jour.jour != undefined && {value : jour.jour, label : jour.jour[0].toUpperCase() + jour.jour.slice(1)}} objet={jour}
                             setObjet={setJour} />
                <div className={"mb-2 form-group flex"}>
                    <label htmlFor={"recurrence"} className={"form-label mt-1 text-end col-5 pr-5"}
                           style={{width: "41.66666667%"}}>Heure Debut :</label>
                    <MobileTimePicker label={'Heures'} openTo="hours" className={"datePicker"}
                                      onChange={handleChangeDateDebut} ampm={false} value={heuresDebut } />
                </div>
                <div className={"mb-2 form-group flex"}>
                    <label htmlFor={"recurrence"} className={"form-label mt-1 text-end col-5 pr-5"}
                           style={{width: "41.66666667%"}}>Heure Fin :</label>
                    <MobileTimePicker label={'Heures'} openTo="hours" className={"datePicker"}
                                      onChange={handleChangeDateFin} ampm={false} value={heuresFin }/>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="d-flex justify-content-between col-3 me-2 mt-2">
                        <button className={"btn btn-secondary m-2"} onClick={handleCloseModal}>
                            Annuler
                        </button>
                        <button className={"btn btn-add-activity m-2"} type={"submit"}>
                            Enregistrer
                        </button>
                    </div>
                </div>
                </form>
            </div>
        </Box>
    </Modal>;
};

export default ModalPlanningHoraireAddDay;