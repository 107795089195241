import axios from "axios";
import {CLIENT_API_URL} from "../Config/url";

export class ClientAPI {
    static async fetchAll() {
        return axios.get(CLIENT_API_URL);
    }

    static async fetchClientByName(name) {
        return axios.get(CLIENT_API_URL, name);
    }


    static async getClientById(id) {
        return axios.get(CLIENT_API_URL + '/' + id);
    }

    static async createClient(client) {
        return axios.post(CLIENT_API_URL, client);
    }

    static async updateClient(id, client) {
        return axios.put(CLIENT_API_URL + '/' + id, client);
    }

    static async delete(id) {
        return axios.delete(CLIENT_API_URL + '/' + id);
    }

    static async fetchClientByGroupActivite(id){
        return axios.get(CLIENT_API_URL +"/"+ id + "/groupe_activite");
    }
}

function fetchAll() {
    return axios.get(CLIENT_API_URL);
}

export default {
    fetchAll,
}