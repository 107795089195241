import axios from "axios";
import jwtDecode from "jwt-decode"
import {LOGIN_CHECK} from "../Config/url";

export class Auth {

    constructor() {
    }

    authenticate(credentials) {
        return axios
            .post(LOGIN_CHECK, credentials, {
                headers: {
                    "Content-Type": "application/json",
                    'Accept': 'application/json'
                }
            })
            .then(response => response.data)
            .then(data => {
                //Je stock le token dans le localeStorage
                window.localStorage.setItem("authToken", data.token);
                //On previent axios que on a maintenant un header sur toutes nos futures requêtes http
                this.setAxiosToken(data.token);
                return data;
            })
    }

    logout() {
        window.localStorage.removeItem("authToken");
        delete axios.defaults.headers['Authorization'];
    }

    setAxiosToken(token) {
        axios.defaults.headers['Authorization'] = "Bearer " + token;
    }

    setup() {
        const token = window.localStorage.getItem("authToken");
        if (token) {
            const {exp: expiration} = jwtDecode(token);
            if ((expiration * 1000) > new Date().getTime()) {
                this.setAxiosToken(token);
            } else {
                this.logout();
            }
        } else {
            this.logout();
        }
    }

    getRoles() {
        const token = window.localStorage.getItem("authToken");
        if (token) {
            const {roles} = jwtDecode(token);
            return roles;
        } else {
            return ['ROLE_USER']
        }
    }

    isAuthenticated() {
        const token = window.localStorage.getItem("authToken");
        if (token) {
            const {exp: expiration} = jwtDecode(token);
            if ((expiration * 1000) > new Date().getTime()) {
                return true;
            }
            return false;

        } else {
            return false;
        }
    }

    getUser() {
        const token = window.localStorage.getItem("authToken");
        if (token) {
            const {iss} = jwtDecode(token);
            return iss;
        } else {
            return null
        }
    }
}

export default Auth;
