import React from 'react';
import "./index.css";
import SvgIcon from "@mui/material/SvgIcon";
import Color from '../../../Styles';


const Input = ({
                   type,
                   name,
                   setObjet,
                   label,
                   placeholder,
                   value,
                   objet,
                   icon,
                   flex,
                   required,
                   classNameLabel,
                   disabled,
                   setRef = () => {
                   },
                   toUppercase = false
               }) => {

    const handleChange = ({currentTarget}) => {
        if (toUppercase == true){
            setObjet({...objet, [name]: currentTarget.value.toUpperCase()})
        }else{
            setObjet({...objet, [name]: currentTarget.value})
        }
    }

    return <>
        {flex == "column" ? <div className={"form-group d-flex-column"}>
            {(label != "" && label != undefined) && <>
                <label htmlFor={name} className={"form-label mt-1 " + classNameLabel}
                       key={name}>{label}{required && <> *</>} :</label>
            </>}
            {icon != null && icon != undefined ? <div className={"group-input"}>
                    <div className={"input-icon col-2"}>
                        <SvgIcon sx={{color: Color.grey, height: 1}}>
                            {icon}
                        </SvgIcon>
                    </div>
                    <input ref={refe => {
                        setRef(refe)
                    }} className={"form-input col-11"} key={name + "1"} name={name} id={name} onChange={handleChange}
                           type={type} placeholder={placeholder} value={value} required={required ? required : false}
                           disabled={disabled ? disabled : false}/>
                </div> :
                <input ref={refe => {
                    setRef(refe)
                }} className={"form-input col-12"} key={name + "1"} name={name} id={name} onChange={handleChange}
                       type={type} placeholder={placeholder} value={value} required={required ? required : false}
                       disabled={disabled ? disabled : false}/>
            }
        </div> : <div className={"form-group d-flex"}>
            {(label != "" && label != undefined) && <>
                <label htmlFor={name} className={"form-label col-5 mt-1 " + classNameLabel}
                       key={name}>{label}{required && <> *</>} :</label>

            </>}
            {icon != null && icon != undefined ? <div className={"group-input"}>
                    <div className={"input-icon col-2"}>
                        <SvgIcon sx={{color: Color.grey, height: 1}}>
                            {icon}
                        </SvgIcon>
                    </div>
                    <input ref={refe => {
                        setRef(refe)
                    }} className={"form-input col-11"} key={name + "1"} name={name} id={name} onChange={handleChange}
                           type={type} placeholder={placeholder} value={value} required={required ? required : false}
                           disabled={disabled ? disabled : false}/>
                </div> :
                <input ref={refe => {
                    setRef(refe)
                }} className={"form-input col-12"} key={name + "1"} name={name} id={name} onChange={handleChange}
                       type={type} placeholder={placeholder} value={value} required={required ? required : false}
                       disabled={disabled ? disabled : false}/>
            }
        </div>}
    </>
}

export default Input;