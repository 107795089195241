import axios from "axios";
import {PLANNING_ACTIVITE_API} from "../Config/url";

function fetchAll() {
    return axios.get(PLANNING_ACTIVITE_API);
}

function add(planningActivite) {
    return axios.post(PLANNING_ACTIVITE_API, planningActivite,  {
        headers: {
            'Content-Encoding': 'gzip',
            'Content-Type': 'application/json',
        }
    })
}

function getPlanningByUser(idUser) {
    return axios.get(PLANNING_ACTIVITE_API+'/'+ idUser+"/employe")
}

function getPlanningById(id) {
    return axios.get(PLANNING_ACTIVITE_API+'/'+ id)
}
function update(planningActivite) {
    return axios.put(PLANNING_ACTIVITE_API+"/"+planningActivite.id, planningActivite)
}

function deleteP(id) {
    return axios.delete(PLANNING_ACTIVITE_API+'/'+ id)
}

function deleteRecurrence(id) {
    return axios.delete(PLANNING_ACTIVITE_API+'/'+ id+'/recurrence')
}


function recurrence(recurrence, activite){
    return axios.post(PLANNING_ACTIVITE_API+'/recurrence', {recurrence : recurrence, activite : activite}, {
        headers : {
            "Content-Type" : "application/json;charset=UTF-8"
        }
    })
}

function fetchAllByDate(date){
    return axios.post(PLANNING_ACTIVITE_API+'/date', {date : date}, {
        headers : {
            "Content-Type" : "application/json;charset=UTF-8"
        }
    })
}
export default {
    fetchAll,
    add,
    getPlanningByUser,
    getPlanningById,
    update,
    deleteP,
    deleteRecurrence,
    recurrence,
    fetchAllByDate
}