import React, {useState} from 'react';
import {IconButton, Menu, MenuItem} from "@mui/material";
import DeleteModal from "../../Modal/DeleteModal/deleteModal";
import {EmployeAPI} from "../../../Services/EmployeAPI";
import {Delete} from "@mui/icons-material";
import ModalEditDateSortieEmploye from "../../Modal/ModalEditDateSortieEmploye";

const DotMenuEmployeDataTable = ({idUser, user,fetchUtilisateur}) => {
    const options = [
        {
            key: 1,
            value: 'Rendre l\'employé inactif'
        },
        {
            key: 2,
            value: 'Ajouter une date de sortie'
        },
    ];
    const [id, setId] = useState(idUser)
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [isOpenDateSortie, setIsOpenDateSortie] = useState(false);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSelect = (key) => {
        setAnchorEl(null);
        switch (key) {
            case 1:
                // Modal pour confirmer la suppression de l'utilisateur
                setIsOpenDelete(!isOpenDelete)
                break;
            case 2:
                // Modal pour confirmer la suppression de l'utilisateur
                setIsOpenDateSortie(!isOpenDelete)
                break;
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <Delete color={"error"}/>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {options.map((option) => (
                    <MenuItem key={option.key} onClick={() => handleSelect(option.key)}>
                        {option.value}
                    </MenuItem>
                ))}
            </Menu>

            <DeleteModal id={id} setIsOpen={setIsOpenDelete} setId={setId} isOpen={isOpenDelete}
                         api={EmployeAPI} fetchUtilisateur={fetchUtilisateur}/>
            <ModalEditDateSortieEmploye setIsOpen={setIsOpenDateSortie} isOpen={isOpenDateSortie} user={user} fetchUtilisateur={fetchUtilisateur}/>
        </div>
    );
};

export default DotMenuEmployeDataTable;