// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-color {
    font-weight: bold;
    font-size: 16px;
    color: var(--orange-color);

}

.align-right {
    display: flex;
    justify-content: flex-end;
}

.custom-indicator {
    color: var(--orange-color);
}`, "",{"version":3,"sources":["webpack://./src/Styles/infos.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,eAAe;IACf,0BAA0B;;AAE9B;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".info-color {\r\n    font-weight: bold;\r\n    font-size: 16px;\r\n    color: var(--orange-color);\r\n\r\n}\r\n\r\n.align-right {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n}\r\n\r\n.custom-indicator {\r\n    color: var(--orange-color);\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
