import React, {useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import '../../../Styles/ModalForm.css';
import Input from "../../FormsComponents/Input";
import Button from 'react-bootstrap/Button'
import {toast} from "react-toastify";
import {groupeActiviteAPI} from "../../../Services/groupeActiviteAPI";
import {modalStyle} from "../../../Styles/ModalStyle";
import ActiviteAPI, {activiteAPI} from "../../../Services/activiteAPI";

const ModalAjoutActiviteParent = ({isOpen, setIsOpen, activite, setActivite}) => {
    const [activiteC, setActiviteC] = useState({});
    const handleCloseModal = () => {
        setIsOpen(!isOpen);
        setActiviteC({});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (activite.parent4 != undefined){
                activiteC.parent = activite.parent4;
            }else if (activite.parent3 != undefined){
                activiteC.parent = activite.parent3;
            }else if (activite.parent2 != undefined){
                activiteC.parent = activite.parent2;
            }else if(activite.parent1 != undefined){
                activiteC.parent = activite.parent1;
            }
            activiteC.groupeActivite = activite.groupeActivite
            // console.log(activiteC)
            const response = await activiteAPI.createActivite(activiteC);

            if(activite.parent1 == undefined){
                setActivite({...activite, ['parent1'] : response.data})
            }else if(activite.parent2 == undefined){
                setActivite({...activite, ['parent2'] : response.data})
            }else if(activite.parent3 == undefined){
                setActivite({...activite, ['parent3'] : response.data})
            }else if (activite.parent4 == undefined){
                setActivite({...activite, ['parent4'] : response.data})
            }
            handleCloseModal();
            toast.success("Votre élément à été ajouté")
        } catch (e) {
            toast.error("Une erreur est survenue");
        }
    }

    return <Modal open={isOpen}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h5" component="h2"
                        className={"d-flex justify-content-center p-2 border-bottom"}> Créer un élément
            </Typography>
            <div className={"form-modal flex flex-column gap-2"}>
                <form onSubmit={handleSubmit}>
                    <div className="my-2">
                        <Input required={true} type={"text"} name={"libelle"} label={"Nom de l'élément"}
                               value={activiteC.libelle}
                               placeholder={"Nom de l'élément"} objet={activiteC} setObjet={setActiviteC}/>
                    </div>

                    <div className="d-flex justify-content-end">
                        <div className="d-flex justify-content-between col-3 me-2 mt-2">
                            <Button variant={"outlined"} className={"btn btn-danger"} color={"danger"}
                                    onClick={handleCloseModal} type="button">Annuler</Button>
                            <Button variant={"outlined"} className={"btn btn-alliance"} type="submit">Enregistrer</Button>
                        </div>
                    </div>
                </form>
            </div>
        </Box>
    </Modal>;
};

export default ModalAjoutActiviteParent;