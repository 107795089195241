// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.version{
    padding-left: 10px;
    max-height: 85vh;
    overflow: auto;
}

.version > ul {
    list-style: circle;
}
menu, ol, ul{
    list-style: circle;

}
`, "",{"version":3,"sources":["webpack://./src/Styles/version.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;;AAEtB","sourcesContent":[".version{\r\n    padding-left: 10px;\r\n    max-height: 85vh;\r\n    overflow: auto;\r\n}\r\n\r\n.version > ul {\r\n    list-style: circle;\r\n}\r\nmenu, ol, ul{\r\n    list-style: circle;\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
