import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import '../../../Styles/ModalForm.css';
import Input from "../../FormsComponents/Input";
import Button from 'react-bootstrap/Button'
import {toast} from "react-toastify";
import {groupeActiviteAPI} from "../../../Services/groupeActiviteAPI";
import {modalStyle} from "../../../Styles/ModalStyle";

const ModalUpdateGroup = ({isOpen, setIsOpen, groupeActivite, fetch, setGroupeActivite}) => {
    const [currentGroupe, setCurrentGroupe] = useState({});
    const handleCloseModal = () => {
        setIsOpen(!isOpen);
        setCurrentGroupe({});
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            await groupeActiviteAPI.updateGroup(currentGroupe.id, currentGroupe);
            toast.success("Le groupe a bien été modifié");
            fetch().then(ga => {setGroupeActivite(ga)})
            handleCloseModal();
        } catch (e) {
            console.log(e)
            toast.error("Une erreur est survenue");
        }
    }

    useEffect(() => {
        setCurrentGroupe(groupeActivite)
    }, [groupeActivite]);

    return <Modal open={isOpen}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h5" component="h2"
                        className={"d-flex justify-content-center p-2 border-bottom"}> Modifier un Groupe
            </Typography>
            <div className={"form-modal flex flex-column gap-2"}>
                <form onSubmit={handleSubmit}>

                    <div className="my-2">
                        <Input required={true} type={"text"} name={"libelle"} label={"Nom du groupe"}
                               value={currentGroupe.libelle}
                               placeholder={"Nom du groupe"} objet={currentGroupe} setObjet={setCurrentGroupe}/>
                    </div>

                    <div className="d-flex justify-content-end">
                        <div className="d-flex justify-content-between col-3 me-2 mt-2">
                            <Button variant={"outlined"} className={"btn btn-danger"} color={"danger"}
                                    onClick={handleCloseModal} type="button">Annuler</Button>
                            <Button variant={"outlined"} className={"btn btn-alliance"} type="submit">Enregistrer</Button>
                        </div>
                    </div>
                </form>
            </div>
        </Box>
    </Modal>;
};

export default ModalUpdateGroup;