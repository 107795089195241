// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-accordion {
    margin-top: 2rem !important;
}

.top-column {
    font-weight: bold;
    font-size: 15px;
    margin-right: 2px;
}
.high-hover{
    max-height: 60vh;
    overflow-y: scroll;
}

.high-hover:hover {
    color: var(--orange-color);
    overflow-y: scroll;

}

.rdt_TableHeadRow {
    height: 7em !important;
    justify-content: center;
    gap: 2px;
    background-color: var(--color-tableau-en-tete) !important;
    color: var(--white-color);

}
.rdt_TableHead{
    position: sticky;
    top: 0;
    z-index: 2;
}

.sc-kFCrIq {
    font-weight: bold;
    font-size: 1em;
}`, "",{"version":3,"sources":["webpack://./src/Styles/utilisateurs.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;IAC1B,kBAAkB;;AAEtB;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,QAAQ;IACR,yDAAyD;IACzD,yBAAyB;;AAE7B;AACA;IACI,gBAAgB;IAChB,MAAM;IACN,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".content-accordion {\r\n    margin-top: 2rem !important;\r\n}\r\n\r\n.top-column {\r\n    font-weight: bold;\r\n    font-size: 15px;\r\n    margin-right: 2px;\r\n}\r\n.high-hover{\r\n    max-height: 60vh;\r\n    overflow-y: scroll;\r\n}\r\n\r\n.high-hover:hover {\r\n    color: var(--orange-color);\r\n    overflow-y: scroll;\r\n\r\n}\r\n\r\n.rdt_TableHeadRow {\r\n    height: 7em !important;\r\n    justify-content: center;\r\n    gap: 2px;\r\n    background-color: var(--color-tableau-en-tete) !important;\r\n    color: var(--white-color);\r\n\r\n}\r\n.rdt_TableHead{\r\n    position: sticky;\r\n    top: 0;\r\n    z-index: 2;\r\n}\r\n\r\n.sc-kFCrIq {\r\n    font-weight: bold;\r\n    font-size: 1em;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
