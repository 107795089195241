import React, {useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {CircularProgress} from "@mui/material";
import {toast} from "react-toastify";
import {EmployeAPI} from "../../../Services/EmployeAPI";
import {modalStyle} from "../../../Styles/ModalStyle";
import DatePicker from "../../DatePicker";
import moment from "moment";

const ModalUser = ({
                       isOpen,
                       setIsOpen,
                        user,
                       fetchUtilisateur
                   }) => {
    const [loading, setLoading] = useState(false);
    const [dateSortie, setDateSortie] = useState({});

    const handleCloseModal = () => {
        setIsOpen(!isOpen);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            user.dateSortie = dateSortie.value
            await EmployeAPI.updateEmploye(user.id, user);
            toast.success("L'employé a bien été modifié");
            handleCloseModal();
            fetchUtilisateur();
        } catch (e) {
            toast.error("Une erreur est survenue");
        }

    }

    return (
        <Modal
            open={isOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h5" component="h2"
                            className={"d-flex justify-content-center p-2 border-bottom"}>
                    Ajouter une date de sortie
                </Typography>
                <div className={"form-modal flex flex-column gap-2"}>
                    {loading ? (
                        <div className={"text-center"}>
                            <CircularProgress color={"primary"}/>
                        </div>
                    ) : (
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className={"mb-2 form-group d-flex"}>
                                    <label htmlFor={"dateSortie"} className={"form-label mt-1 text-start col-5"}
                                        // style={{width: "41.66666667%"}}
                                    >{"Date de sortie"}  :</label>
                                    <DatePicker label={"Date de sortie"} name={"value"}
                                                style={{width: "58.4444443%"}}
                                                className={"col-12"}
                                                objet={dateSortie}
                                                setObjet={setDateSortie}
                                                value={dateSortie.value != undefined ? moment(dateSortie.value) : null}
                                                objectIsMoment={false} />
                                </div>
                                <div className={"d-flex justify-content-end"}>
                                    <div className="d-flex justify-content-between col-3 me-2 mt-2">
                                        <button className={"btn btn-secondary m-2"} onClick={handleCloseModal}>
                                            Annuler
                                        </button>
                                        <button className={"btn btn-add-activity m-2"} type={"submit"}>Enregistrer
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </Box>
        </Modal>
    );
};

export default ModalUser;
