import React, {useState} from 'react';
import {Close} from '@mui/icons-material';
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from '@mui/material/Tooltip';
import DatePicker from "../../DatePicker";

const Header2PlanningVerification = ({
                                         dateDebut,
                                         setDateDebut,
                                         dateFin,
                                         setDateFin
                                     }) => {

    const [pickerKeyDateDebut, setPickerKeyDateDebut] = useState(null);
    const [pickerKeyDateFin, setPickerKeyDateFin] = useState(null);

    const handleReset = () => {
        setPickerKeyDateFin(Math.random().toString());
        setPickerKeyDateDebut(Math.random().toString());
    }
    return <div className={"header2"}>
        <div className={"entete"}>
            <h1 className={"m-2"}>Planning Vérification</h1>
        </div>
        <div className={"box"}>
            <div className={"d-flex justify-content-between"}>
                <div className={"d-flex col-10 justify-content-around"}>
                    <div className={"col-2 mr-1"}>
                        <DatePicker label={"Date Début"} name={"date"}
                                    objet={dateDebut}
                                    setObjet={setDateDebut} key={pickerKeyDateDebut}/>
                    </div>
                    <div className={"col-2 mr-1"}>
                        <DatePicker label={"Date Fin"} name={"date"}
                                    objet={dateFin}
                                    setObjet={setDateFin} key={pickerKeyDateFin}/>
                    </div>
                </div>
                <div className={"d-flex col-2 justify-content-end"}>
                    <Tooltip title={"Vider les filtres"}>
                        <button className="btn btn-refresh mr-3" onClick={() => handleReset()}>
                            <SvgIcon sx={{color: "#FFFFFF"}}>
                                <Close/>
                            </SvgIcon>
                        </button>
                    </Tooltip>
                </div>
            </div>
        </div>

    </div>
};

export default Header2PlanningVerification;