import React, {useState} from 'react';
import {IconButton, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModalRole from "../../Modal/ModalRole";

const DotMenuUserDataTable = ({idUser, setReload}) => {
    const options = [
        {
            key: 1,
            value: 'Modifier le Rôle'
        },
    ];
    const [id, setId] = useState(idUser)
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenRole, setIsOpenRole] = useState(false);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSelect = (key) => {
        setAnchorEl(null);
        switch (key) {
            case 1:
                // Modal pour la modification du rôle
                setIsOpenRole(!isOpenRole)
                break;
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {options.map((option) => (
                    <MenuItem key={option.key} onClick={() => handleSelect(option.key)}>
                        {option.value}
                    </MenuItem>
                ))}
            </Menu>

            <ModalRole id={id} setId={setId} isOpen={isOpenRole} setIsOpen={setIsOpenRole} setReload={setReload}/>
        </div>
    );
};

export default DotMenuUserDataTable;