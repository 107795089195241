import React from 'react';
import {MDXProvider} from '@mdx-js/react';
import Markdown from "../versionning.mdx"
import "../Styles/version.css"

const Versionning = () => {

    return <div className={"version"}>
        <MDXProvider>
            <Markdown />
        </MDXProvider>
    </div>;
};

export default Versionning;