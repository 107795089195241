import axios from "axios";
import {HABILITATION_URL} from "../Config/url";

export class HabilitationAPI {
    static async getAll() {
        return axios.get(HABILITATION_URL);
    }

    static async getHabilitationById(id) {
        return axios.get(HABILITATION_URL + '/' + id);
    }

    static async createHabilitation(habilitation) {
        return axios.post(HABILITATION_URL, habilitation);
    }

    static async modifyHabilitation(id, habilitation) {
        return axios.put(HABILITATION_URL + '/' + id, habilitation);
    }

    static async delete(id) {
        return axios.delete(HABILITATION_URL + '/' + id);
    }
}