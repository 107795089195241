import React from 'react';
import SvgIcon from "@mui/material/SvgIcon";
import Color from "../../../Styles";

const TextAreaInput = ({
                           flex,
                           label,
                           classNameInput,
                           name,
                           required,
                           icon,
                           classNameLabel,
                           value,
                           setObjet,
                           objet,
                           placeholder
                       }) => {

    const handleChange = ({currentTarget}) => {
        setObjet({...objet, [name]: currentTarget.value})
    }

    return <>
        {flex == "column" ? <div className={"form-group d-flex-column"}>
            {(label != "" && label != undefined) && <>
                <label htmlFor={name} className={"form-label mt-1 " + classNameLabel}
                       key={name}>{label}{required && <> *</>} :</label>
            </>}
            {icon != null && icon != undefined ? <div className={"group-input"}>
                    <div className={"input-icon col-2"}>
                        <SvgIcon sx={{color: Color.grey, height: 1}}>
                            {icon}
                        </SvgIcon>
                    </div>
                    <textarea className={"form-input col-11 " + classNameInput} key={name + "1"} name={name} id={name}
                              onChange={handleChange} placeholder={placeholder} value={value}
                              required={required ? required : false}/>
                </div> :
                <textarea className={"form-input col-12 " + classNameInput} key={name + "1"} name={name} id={name}
                          onChange={handleChange} placeholder={placeholder} value={value}
                          required={required ? required : false}/>
            }
        </div> : <div className={"form-group d-flex"}>
            {(label != "" && label != undefined) && <>
                <label htmlFor={name} className={"form-label col-5 mt-1 " + classNameLabel}
                       key={name}>{label}{required && <> *</>} :</label>
            </>}
            {icon != null && icon != undefined ? <div className={"group-input"}>
                    <div className={"input-icon col-2"}>
                        <SvgIcon sx={{color: Color.grey, height: 1}}>
                            {icon}
                        </SvgIcon>
                    </div>
                    <textarea className={"form-input col-11 " + classNameInput} key={name + "1"} name={name} id={name}
                              onChange={handleChange} placeholder={placeholder} value={value}
                              required={required ? required : false}/>
                </div> :
                <textarea className={"form-input col-12 " + classNameInput} key={name + "1"} name={name} id={name}
                          onChange={handleChange} placeholder={placeholder} value={value}
                          required={required ? required : false}/>
            }
        </div>}
    </>;
};

export default TextAreaInput;