import * as React from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/fr"
import {frFR} from '@mui/x-date-pickers/locales';
import {DatePicker as DT} from '@mui/x-date-pickers/DatePicker';
import moment from "moment";

const frLocal = frFR.components.MuiLocalizationProvider.defaultProps.localeText;
export default function DatePicker({
                                       label, name, setObjet, objet, setRef = () => {
    }, value = null, objectIsMoment = true, style = "", className=""
                                   }) {

    const handleChange = (e) => {
        if (objectIsMoment == false) {
            setObjet({...objet, [name]: moment(e['_d']).format("YYYY-MM-DD")})
        } else {
            setObjet(moment(e))
        }
    }
    //
    // const test = () => {
    //     console.log(document.getElementById("dt").children)
    // }
    //
    // useEffect(() => {
    //     test()
    // }, [document.getElementById("dt")]);


    return <>
        <div className={"group-input "+ className} id={"dt"} styles={style}>
            <LocalizationProvider className={" form-input col-11 "} dateAdapter={AdapterMoment} locale={'fr'}
                                  localeText={frLocal} >
                <DT
                    ref={refe => {
                        setRef(refe)
                    }}
                    className={"datePicker col-12"}
                    label={label}
                    format="DD/MM/YYYY"
                    onChange={handleChange}
                    value={value}
                    dayOfWeekFormatter={(_day) => {
                        switch (_day){
                            case "Sun":
                                return "L";
                                break;
                            case "Mon":
                                return "M";
                                break;
                            case "Tue":
                                return "M"
                                break;
                            case "Wed":
                                return "J"
                                break;
                            case "Thu":
                                return "V"
                                break;
                            case "Fri":
                                return "S"
                                break;
                            case "Sat":
                                return "D"
                                break;
                        }
                    }}
                />
            </LocalizationProvider>
        </div>


    </>;
}