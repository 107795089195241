import moment from "moment";

function getDatesForDayOfWeek(startDate, endDate, dayOfWeek, heureDebut, heureFin) {
    const allDates = [];
    while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
        dayOfWeek.map(d => {
            if (startDate.day() === d) {
                allDates.push({
                    dateDebut: moment(startDate.format('YYYY-MM-DD') + " " + heureDebut).format('YYYY-MM-DDTHH:mm:ssZ'),
                    dateFin: moment(startDate.format('YYYY-MM-DD') + " " + heureFin).format('YYYY-MM-DDTHH:mm:ssZ')
                });
            }
        })
        startDate.add(1, 'days');
    }
    return allDates;
}
function calculerRecurrence(debut, fin, frequence, intervalle, joursDeRecurrence, heureDebut, heureFin) {
    var datesRecurrence = [];
    var currentDate = moment(debut);
    var endDate = moment(fin);

    // Calculer les dates de récurrence
    while (currentDate <= endDate) {
        // Pour chaque jour de récurrence spécifié
        joursDeRecurrence.forEach(jour => {
            // Trouver le prochain jour spécifique de la semaine
            var nextDay = moment(currentDate).isoWeekday(jour);
            // Si le prochain jour de récurrence est après la date de fin, sortir de la boucle
            if (nextDay > endDate) {
                return;
            }
            // Ajouter la date de récurrence si elle est dans la plage spécifiée
            if (nextDay.isBetween(moment(debut), moment(fin), null, '[]')) {
                datesRecurrence.push({
                    dateDebut: moment(nextDay.format('YYYY-MM-DD') + " " + heureDebut).format('YYYY-MM-DDTHH:mm:ssZ'),
                    dateFin: moment(nextDay.format('YYYY-MM-DD') + " " + heureFin).format('YYYY-MM-DDTHH:mm:ssZ')
                });
            }
        });
        currentDate.add(intervalle, frequence);
    }

    return datesRecurrence;
}

export default {
    getDatesForDayOfWeek,
    calculerRecurrence
};