import React from 'react';
import {Add} from "@mui/icons-material";
import DataTable, {createTheme} from "react-data-table-component";
import '../../../Styles/utilisateurs.css';

const GenericDataTable = ({
                              data,
                              setIsOpen = null,
                              columnItem,
                              noDataText,
                              btnText = null,
                              defaultSortAsc = false,
                              defaultSortFieldId = null,
                              text = null,
                              conditionalRowStyles = () => {},
                              paginationRowsPerPageOptions = [25, 50],
                                paginationPerPage=25,
                              handleRowClicked = ()=>{}
                          }) => {

    const paginationOptions = {
        rowsPerPageText: "Lignes par page",
        rangeSeparatorText: "sur"
    }

    const modalCreateItem = () => {
        if (setIsOpen) {
            setIsOpen(true)
        }
    }

    createTheme('solarized', {
        background: {
            default: 'white',
        },
        striped: {
            default: '#EBEBEB',
        },
        action: {
            hover: 'rgb(234 91 12)', // c'est var(--orange-color)

        },
    });
    return <>
        {text && <div className={"d-flex justify-content-end mb-2"}>
            {text}
        </div>
        }
        {btnText && <div className={"d-flex justify-content-end mb-2"}>
            <button className={"btn btn-add-activity m-2"} onClick={modalCreateItem}><Add color={"white"}
                                                                                          className={"mr-2"}/>{btnText}
            </button>
        </div>
        }

        <DataTable
            pagination
            columns={columnItem}
            data={data}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            paginationPerPage={paginationPerPage}
            paginationComponentOptions={paginationOptions}
            theme={'solarized'}
            striped={true}
            noDataComponent={noDataText}
            className={"high-hover"}
            onRowMouseEnter={(row, event) => {
                if (event.currentTarget.className.includes('hefZMz')) {
                    event.currentTarget.className = "sc-jsEeA-d hefZMz rdt_TableRow hovered";
                } else if (event.currentTarget.className.includes('hraivy')) {
                    event.currentTarget.className = "sc-jsEeA-d hraivy rdt_TableRow hovered";
                }
            }}
            onRowMouseLeave={(row, event) => {
                if (event.currentTarget.className.includes('hefZMz')) {
                    event.currentTarget.className = "sc-jsEeA-d hefZMz rdt_TableRow";
                } else if (event.currentTarget.className.includes('hraivy')) {
                    event.currentTarget.className = "sc-jsEeA-d hraivy rdt_TableRow";
                }
            }}
            defaultSortAsc={defaultSortAsc}
            defaultSortFieldId={defaultSortFieldId}
            conditionalRowStyles={conditionalRowStyles}
            onRowClicked={handleRowClicked}
        />
    </>;
};

export default GenericDataTable;