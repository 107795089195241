import React from 'react';
import Header2Generique from "../../Components/Headers/Header2Generique";

const Statistique = () => {
    return (
        <div>
            <Header2Generique title={"Statistiques des activités"}/>
        </div>
    );
};

export default Statistique;