import React, {useContext} from 'react';
import './index.css';
import {Link} from 'react-router-dom';
import SideBarContext from "../../../Context/SideBarContext";

const SubMenu = ({route, setRoute, open, roles}) => {
    const {routeSelected, setRouteSelected} = useContext(SideBarContext);

    return <>
        <Link key={"sub" + route.name}
              className={(open && route.name == routeSelected) ? "sideBarLink active" : "sideBarLink"} to={route.link}
              onClick={route.collapse && (() => {
                  setRoute(route.name)
                  setRouteSelected(route.name)
              })}>
            <div key={"div1" + route.name}>
                <span>{route.icon}</span>
                <span className={"sideBarLabel"}>{route.name}</span>
            </div>
            <div key={"div2" + route.name}>
                {route.collapse && open ? route.iconOpen : route.collapse ? route.iconClosed : null}
            </div>
        </Link>
        {open && route.collapse.map((r, index) => {
            if (r.visible == true  &&( r.roles.includes(roles.replaceAll('[' , '').replaceAll(']', '')) || r.roles.length === 0)) {
                return <>
                    <Link className={r.name == routeSelected ? "dropDownLink active" : "dropDownLink"} to={r.link}
                          key={"subDrop" + index} onClick={() => {
                        setRouteSelected(r.name)
                    }}>
                        <span>{r.icon}</span>
                        <span className={"sideBarLabel"}>{r.name}</span>
                    </Link>
                </>
            }
        })};
    </>

};

export default SubMenu;