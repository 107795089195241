import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import '../../../Styles/ModalForm.css';
import Input from "../../FormsComponents/Input";
import {CircularProgress} from "@mui/material";
import {toast} from "react-toastify";
import {ClientAPI} from "../../../Services/clientAPI";
import {modalStyle} from "../../../Styles/ModalStyle";


const ModalClient = ({isOpen, setIsOpen, id = null, setId = () => {}, edit = false, setEdit = () => {}, setReload = () => {}}) => {
    const [client, setClient] = useState({});
    const [loading, setLoading] = useState(false);

    const handleCloseModal = () => {
        setIsOpen(!isOpen);
        setEdit(false);
        setId(null);
        setClient({});
        setReload(true);
    }
    const fetchClient = async id => {
        let data = await ClientAPI.getClientById(id);
        setClient(data.data);
        setLoading(false);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            if (edit) {
                await ClientAPI.updateClient(id, client);
                toast.success("Le client à bien été modifié")
            } else {
                await ClientAPI.createClient(client);
                toast.success("Le client à bien été créé")
            }
            handleCloseModal();
        } catch (e) {
            console.log(e);
            toast.error("Une erreur est survenue");
        }
    }

    useEffect(() => {
        if (id) {
            setLoading(true)
            fetchClient(id).then(r => '')
        }
    }, [id])

    return <Modal open={isOpen}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h5" component="h2"
                        className={"d-flex justify-content-center p-2 border-bottom"}>
                {edit ? "Modifier" : "Créer"} un Client
            </Typography>
            <div className={"form-modal flex flex-column gap-2"}>
                {(loading) && <div className={"text-center"}><CircularProgress color={"primary"}/></div>
                    || <>
                        <form onSubmit={handleSubmit}>

                            <div className="my-2">
                                <Input required={true} type={"text"} name={"nom"} label={"Nom"} value={client.nom}
                                       placeholder={"Nom du Client"} objet={client} setObjet={setClient}/>
                            </div>
                            <div className="my-2">
                                <Input required={true} type={"text"} name={"adresse"} label={"Adresse"}
                                       value={client.adresse}
                                       placeholder={"Adresse du Client"} objet={client} setObjet={setClient}/>
                            </div>
                            <div className="my-2">
                                <Input required={false} type={"text"} name={"adresse2"} label={"Adresse 2"}
                                       value={client.adresse2}
                                       placeholder={"Adresse 2 du Client"} objet={client} setObjet={setClient}/>
                            </div>
                            <div className="my-2">
                                <Input required={true} type={"text"} name={"ville"} label={"Ville"}
                                       value={client.ville}
                                       placeholder={"Ville du client"} objet={client}
                                       setObjet={setClient}/>
                            </div>
                            <div className="my-2">
                                <Input required={true} type={"number"} name={"codePostal"} label={"Code Postal"}
                                       value={client.codePostal}
                                       placeholder={"Code Postal du Client"} objet={client} setObjet={setClient}/>
                            </div>
                            <div className="my-2">
                                <Input required={true} type={"number"} name={"numero"} label={"Téléphone"}
                                       value={client.numero}
                                       placeholder={"Téléphone du Client"} objet={client} setObjet={setClient}/>
                            </div>
                            <div className="my-2">
                                <Input required={true} type={"email"} name={"mail"} label={"Mail Client"}
                                       value={client.mail}
                                       placeholder={"Mail du Client"} objet={client} setObjet={setClient}/>
                            </div>
                            <div className="mt-5">
                                <p>Veuillez remplir tous les champs obligatoires marqués d'un «*».
                                </p>
                            </div>
                            <div className="d-flex justify-content-end">
                                <div className="d-flex justify-content-between col-3 me-2 mt-2">
                                    <button className={"btn btn-secondary m-2"} onClick={handleCloseModal}>
                                        Annuler
                                    </button>
                                    <button className={"btn btn-add-activity m-2"} type={"submit"}>Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    </>
                }
            </div>
        </Box>
    </Modal>;
};

export default ModalClient;