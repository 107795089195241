import React, {useEffect, useState} from 'react';
import Loading from "../../Components/Loading";
import {activiteAPI} from "../../Services/activiteAPI";
import GenericDataTable from "../../Components/DataTables/GenericDataTable";
import Header2PlanningActivite from "../../Components/Headers/Header2PlanningActivite";
import ModalAjoutActivite from "../../Components/Modal/ModalAjoutActivite";
import {IconButton, Tooltip} from "@mui/material";
import {Delete, Visibility} from "@mui/icons-material";
import ModalSuppressionActivite from "../../Components/Modal/ModalSuppressionActivite";
import ModalEditActivite from "../../Components/Modal/ModalEditActivite";
import TextField from "@mui/material/TextField";
import Header2Generique from "../../Components/Headers/Header2Generique";
import axios from "axios";
import {URL_API} from "../../Config/url";

const Activite = () => {
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [loading, setLoading] = useState(true);
    const [dataActivite, setDataActivite] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [selected, setSelected] = useState({});
    const [selectedID, setSelectedID] = useState(null);
    const [searchValue, setSearchValue] = useState({});
    const [filteredActivites, setFilteredActivites] = useState([]);
    const [file,setFile] = useState();

    const [columnUtilisateurs, setColumnUtilisateurs] = useState([
        {
            name: <span className="top-column">Groupe</span>,
            selector: row => row.groupeactivite,
            sortable: true
        },
        {
            name: <span className="top-column">Client</span>,
            selector: row => row.client,
            sortable: true
        },
        {
            name: <span className="top-column">Activité</span>,
            selector: row => row.activite,
            sortable: true
        },
        {
            name: <span className="top-column">Sous-activité 1</span>,
            selector: row => row.sousactivite1,
            sortable: true
        },
        {
            name: <span className="top-column">Sous-activité 2</span>,
            selector: row => row.sousactivite2,
            sortable: true
        },
        {
            name: <span className="top-column">Sous-activité 3</span>,
            selector: row => row.sousactivite3,
            sortable: true
        },
        {
            name: <span className="top-column">Coordinateur</span>,
            selector: row => row.coordinateur,
            sortable: true
        },
        {
            name: <span className="top-column">Localisation</span>,
            selector: row => row.localisation,
            sortable: true
        },
        {
            name: <span className="top-column">Nombre salariés théorique</span>,
            selector: row => row["Nom de salarié théorique"],
            sortable: true
        },
        {
            name: <span className="top-column">Actions</span>,
            selector: row => <div className="d-flex justify-content-evenly">
                <Tooltip title={"Voir l'activité"}>
                    <IconButton onClick={() => showActivite(row.id)}>
                        <Visibility color="warning"/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Supprimer l'activité"}>
                    <IconButton onClick={() => deleteActivite(row)}>
                        <Delete color={"error"}/>
                    </IconButton>
                </Tooltip>
            </div>
        }
    ]);

    const deleteActivite = (row) => {
        setSelected(row);
        setIsOpenDelete(true)
    }

    const showActivite = (id) => {
        console.log(id)
        setSelectedID(id);
        setIsOpenEdit(true);
    }

    const fetchData = async () => {
        let data = await activiteAPI.getAllActiviteGeneralTab();
        setDataActivite(data.data);
        setFilteredActivites(data.data)
        setLoading(false);
    }
    const fetchActiviteByName = async () => {
        if (searchValue.value){
            setFilteredActivites(dataActivite.filter((act) => {
                const activite = act.activite ? act.activite.toLowerCase() : "";
                const client = act.client ? act.client.toLowerCase() : "";
                const coordinateur = act.coordinateur ? act.coordinateur.toLowerCase() : "";
                const groupeactivite = act.groupeactivite != null ? act.groupeactivite.toLowerCase() : "";
                const localisation = act.localisation ? act.localisation.toLowerCase() : "";
                const prestataire =  act.prestataire ? act.prestataire.toLowerCase() : "";
                const sousactivite1 = act.sousactivite1 != null ? act.sousactivite1.toLowerCase() : "";
                const sousactivite2 = act.sousactivite2 ? act.sousactivite2.toLowerCase() : "";
                const sousactivite3 = act.sousactivite3 ? act.sousactivite3.toLowerCase() : "";
                const searchTermLower = searchValue.value.toLowerCase();
                return activite.includes(searchTermLower) || client.includes(searchTermLower) ||
                    coordinateur.includes(searchTermLower) ||
                    prestataire.includes(searchTermLower) || sousactivite1.includes(searchTermLower) ||
                    sousactivite2.includes(searchTermLower) || sousactivite3.includes(searchTermLower) ||
                    groupeactivite.includes(searchTermLower) || localisation.includes(searchTermLower)  ;
            }))
        }
    }

    const testUpload = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", file);
        axios
            .post(URL_API+"/upload_excel/activites", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
            })
            .then((response) => {
                // handle the response
                console.log(response);
                fetchData();
            })
            .catch((error) => {
                // handle errors
                console.log(error);
            });
    }

    useEffect(() => {
        setLoading(true);
        fetchData().then(r => '');
    }, []);

    useEffect(() => {
        fetchActiviteByName()
    }, [searchValue, dataActivite]);
    return <>
        <div>
            <Header2Generique title={"Liste des activités"} componentRight={<TextField
                label={"Rechercher un client : "}
                sx={{'& .MuiOutlinedInput-root.Mui-focused fieldset': {borderColor: 'var(--orange-color)'}}}
                variant={"outlined"}
                value={searchValue.value}
                name={"value"}
                onChange={(e) => {
                    const {name, value} = e.currentTarget;
                    setSearchValue({...searchValue, [name] : value})
                }}
            />}/>
            <Header2PlanningActivite data={dataActivite} setDataFiltered={setFilteredActivites}/>
            {/*<form onSubmit={testUpload} method={"post"}>*/}
            {/*    <input type={"file"} onChange={(e) => {setFile(e.target.files[0])}}  />*/}
            {/*    <input type="submit"/>*/}
            {/*</form>*/}
            <div className="col-11 m-auto mt-2">
                {loading ? <Loading/> : (
                    <GenericDataTable
                        data={filteredActivites}
                        noDataText={"Aucune données trouvée"}
                        columnItem={columnUtilisateurs}
                    />
                )}
            </div>
            {/*<button className={"btn btn-alliance"} onClick={handleAdd}>Ajouter une Activité</button>*/}
        </div>
        <ModalAjoutActivite
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            fetchActivite={fetchData}
        />
            <ModalEditActivite
                setIsOpen={setIsOpenEdit}
                isOpen={isOpenEdit}
                activiteC={selectedID}
                fetchActivite={fetchData}
            />
        <ModalSuppressionActivite
            activite={selected}
            setIsOpen={setIsOpenDelete}
            isOpen={isOpenDelete}
            fetchActivite={fetchData}
        />

    </>;
};

export default Activite;

