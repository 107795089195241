import React from "react";
import moment from "moment";

export default React.createContext({
    isAuthenticated: false,
    setIsAuthenticated: (value) => {
    },
    roles: ["ROLE_USER"],
    setRoles: (value) => {
    },
    user: null,
    setUser: (value) => {
    },
    uuid: null,
    setUuid: (value) => {
    },
    day: moment(),
    setDay: (value) => {
    },
    tabSelected : null,
    setTabSelected : (value) => {}
})
