import React, {useEffect, useState} from 'react';
import {CircularProgress, IconButton, Tooltip} from "@mui/material";
import {Delete, Visibility} from "@mui/icons-material";
import {ClientAPI} from "../../Services/clientAPI";
import Header2Generique from "../../Components/Headers/Header2Generique";
import GenericDataTable from "../../Components/DataTables/GenericDataTable";
import ModalClient from "../../Components/Modal/ModalClient";
import DeleteModal from "../../Components/Modal/DeleteModal/deleteModal";
import TextField from "@mui/material/TextField";

const Client = () => {
    const [loading, setLoading] = useState(false);
    const [dataClients, setDataClients] = useState([]);
    const [reload, setReload] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [edit, setEdit] = useState(false);
    const [searchValue, setSearchValue] = useState({});
    const [filteredClients, setFilteredClients] = useState([]);
    const [columnClient, setColumnClient] = useState([
        {
            name: <span className="top-column">Nom</span>,
            selector: row => row.nom,
            sortable: true
        },
        {
            name: <span className="top-column">Ville</span>,
            selector: row => row.ville,
            sortable: true
        },
        {
            name: <span className="top-column">Actions</span>,
            selector: row => <div className="d-flex justify-content-evenly">
                <Tooltip title={"Voir le client"}>
                    <IconButton onClick={() => showClient(row.id)}>
                        <Visibility color="warning"/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Supprimer le client"}>
                    <IconButton onClick={() => deleteClient(row.id)}>
                        <Delete color="error"/>
                    </IconButton>
                </Tooltip>
            </div>
        }
    ]);

    const deleteClient = (id) => {
        setSelectedId(id);
        setIsOpenDelete(true);
    }

    const showClient = id => {
        setSelectedId(id);
        setIsOpen(true);
        setEdit(true);
    }

    const fetchClients = async () => {
        let data = await ClientAPI.fetchAll();
        setDataClients(data.data.sort((a, b) => a.nom.localeCompare(b.nom)));
        setFilteredClients(data.data.sort((a, b) => a.nom.localeCompare(b.nom)));
        setLoading(false)
    }

    const fetchClientByName = async () => {
        setFilteredClients(dataClients.filter((cl) => {
            const nom = cl.nom.toLowerCase();
            const ville = cl.ville.toLowerCase();
            const searchTermLower = searchValue.value.toLowerCase();
            return nom.includes(searchTermLower) || ville.includes(searchTermLower);
        }))
    }

    useEffect(() => {
        setLoading(true);
        fetchClients().then(r => '');
    }, [])

    useEffect(() => {
        if (reload) {
            fetchClients().then(r => '');
            setReload(false);
        }
    }, [reload]);

    useEffect(() => {
        fetchClientByName()
    }, [searchValue]);

    return (
        <div>
            <Header2Generique title={"Liste des clients"} componentRight={<TextField
                                  label={"Rechercher un client : "}
                                  sx={{'& .MuiOutlinedInput-root.Mui-focused fieldset': {borderColor: 'var(--orange-color)'}}}
                                  variant={"outlined"}
                                  value={searchValue.value}
                                  name={"value"}
                                  onChange={(e) => {
                                      const {name, value} = e.currentTarget;
                                      setSearchValue({...searchValue, [name] : value})
                                  }}
                              />}
            />
            <div className="col-11 m-auto mt-2">
                {loading && <div className="text-center"><CircularProgress color={"primary"}/></div>
                    ||
                    <GenericDataTable data={filteredClients} setReload={setReload} setIsOpen={setIsOpen}
                                      noDataText={"Aucun Client Trouvé"} btnText={"Ajouter un client"}
                                      columnItem={columnClient}/>
                }
            </div>
            <ModalClient isOpen={isOpen} setIsOpen={setIsOpen} id={selectedId} setId={setSelectedId} edit={edit}
                         setEdit={setEdit} setReload={setReload}/>
            <DeleteModal id={selectedId} setId={setSelectedId} setReload={setReload} setIsOpen={setIsOpenDelete}
                         isOpen={isOpenDelete} api={ClientAPI} fetchUtilisateur={fetchClients}/>
        </div>
    );
};

export default Client;