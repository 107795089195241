// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background: var(--background-menu);
    width: 100%;
    height: 4vh;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    right: 0;
}


.copyright {
    margin-top: 1vh;
    font: var(--font-family-copyright);
    color: var(--font-color-copyright)
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/index.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,WAAW;IACX,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,SAAS;IACT,QAAQ;AACZ;;;AAGA;IACI,eAAe;IACf,kCAAkC;IAClC;AACJ","sourcesContent":[".footer {\r\n    background: var(--background-menu);\r\n    width: 100%;\r\n    height: 4vh;\r\n    display: flex;\r\n    justify-content: center;\r\n    position: fixed;\r\n    bottom: 0;\r\n    right: 0;\r\n}\r\n\r\n\r\n.copyright {\r\n    margin-top: 1vh;\r\n    font: var(--font-family-copyright);\r\n    color: var(--font-color-copyright)\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
