import axios from "axios";
import {PRESTATAIRE_API} from "../Config/url";


export class prestataireAPI {
    static async fetchAll() {
        return axios.get(PRESTATAIRE_API);
    }

    static async fetchPrestataireById(id) {
        return axios.get(PRESTATAIRE_API + '/' + id);
    }

    static async updatePrestataire(id, presta) {
        return axios.put(PRESTATAIRE_API + '/' + id, presta);
    }

    static async createPrestataire(presta) {
        return axios.post(PRESTATAIRE_API, presta);
    }

    static async delete(id) {
        return axios.delete(PRESTATAIRE_API + "/" + id);
    }
}

function fetchAll() {
    return axios.get(PRESTATAIRE_API);
}

export default {
    fetchAll,
}