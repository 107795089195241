import axios from "axios";
import {PLANNING_HORAIRE_API} from "../Config/url";

function fetchAll() {
    return axios.get(PLANNING_HORAIRE_API);
}

function add(planningActivite) {
    return axios.post(PLANNING_HORAIRE_API, planningActivite)
}
function update(planningHoraire) {
    return axios.put(PLANNING_HORAIRE_API+"/"+planningHoraire.id, planningHoraire)
}
function fetchByEmploye(id) {
    return axios.get(PLANNING_HORAIRE_API+"/"+id+"/employes")
}

export default {
    fetchAll,
    add,
    update,
    fetchByEmploye
}