import React from 'react';

const Parametre = () => {
    return (
        <div>
            Parametre
        </div>
    );
};

export default Parametre;