import React, {useEffect, useState} from 'react';
import {Delete, Visibility} from "@mui/icons-material";
import {IconButton, Tooltip} from "@mui/material";
import {toast} from "react-toastify";
import {indisponibiliteAPI} from "../../Services/IndisponibiliteAPI";
import moment from "moment";
import GenericDataTable from "../DataTables/GenericDataTable";
import ModalAddIndisponibilite from "../Modal/ModalIndisponibilite";
import ModalDeleteIndisponibilite from "../Modal/ModalIndisponibilite/delete";
import ModalEditIndisponibilite from "../Modal/ModalIndisponibilite/edit";

const IndisponibiliteEmployeTab = ({user, setUser}) => {
    const [indisponibiliteSelected, setIndisponibiliteSelected] = useState({});
    const [indisponibilites, setIndisponibilites] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const [column, setColumn] = useState([
        {
            name: <span className="top-column">Date Debut</span>,
            selector: row => moment.unix(row.dateDebut.toString().substring(0, 10)).format("DD/MM/YYYY HH:mm"),
            sortable: true
        },
        {
            name: <span className="top-column">Date Fin</span>,
            selector: row => moment.unix(row.dateFin.toString().substring(0, 10)).format("DD/MM/YYYY HH:mm"),
            sortable: true
        },
        {
            name: <span className="top-column">Nombre de jour(s)</span>,
            selector: row => row.nombreJours,
            sortable: true
        },
        {
            name: <span className="top-column">Type Indiponibilite</span>,
            selector: row => row.typeIndisponibilite.label,
            sortable: true
        },
        {
            name: <span className="top-column">Actions</span>,
            selector: row => <div className="d-flex justify-content-evenly">
                <Tooltip title={"Voir l'indisponibilite"}>
                    <IconButton onClick={() => {setIndisponibiliteSelected(row); setUpdateModal(true)}}>
                        <Visibility color="warning"/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Supprimer l'indisponibilite"}>
                    <IconButton onClick={() =>{setIndisponibiliteSelected(row); setDeleteModal(true)}}>
                        <Delete color="error"/>
                    </IconButton>
                </Tooltip>
            </div>
        }
    ]);

    const fetchIndisponibilite = async () => {
        if (user.id != undefined){
            try {
                const response = await indisponibiliteAPI.fetchIndiponibiliteByEmploye(user.id)
                setIndisponibilites(response.data)
                // console.log(response)
            } catch (e) {
                toast.error("Une erreur est survenue")
            }
        }
    }

    useEffect(() => {
        fetchIndisponibilite()
    }, []);

    useEffect(() => {
        fetchIndisponibilite()
    }, [user]);

    return <>
        <div className={"flex justify-content-end"}>
            <button className={"btn btn-add-activity mb-3"} onClick={() => setAddModal(true)}>
                Ajouter une indisponibilité
            </button>
        </div>
        <GenericDataTable
            defaultSortFieldId={"nom"}
            defaultSortAsc={true}
            data={indisponibilites}
            columnItem={column}
            noDataText={"Aucune Indisponibilité trouvé"} theme={"solarized"}
        />
        <ModalAddIndisponibilite user={user} isOpen={addModal} setIsOpen={setAddModal} fetchIndisponibilite={fetchIndisponibilite}/>
        <ModalDeleteIndisponibilite indisponibilite={indisponibiliteSelected} setIsOpen={setDeleteModal} isOpen={deleteModal} fetchIndisponibilite={fetchIndisponibilite} />
        <ModalEditIndisponibilite indisponibilite={indisponibiliteSelected} setIsOpen={setUpdateModal} isOpen={updateModal} fetchIndisponibilite={fetchIndisponibilite} setIndisponibilite={setIndisponibiliteSelected}/>
    </>;
};

export default IndisponibiliteEmployeTab;