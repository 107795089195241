// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-group {
    align-content: center;
    width: 70%;
}

.form-input:focus {
    border: 1px solid var(--orange-color);
    outline: none;
}

.group-input:focus-within .form-input {
    border-right: 1px solid var(--orange-color);
    border-top: 1px solid var(--orange-color);
    border-bottom: 1px solid var(--orange-color);
    outline: none;

}

.group-input:focus-within .input-icon {
    border: 1px solid var(--orange-color);
    outline: none;
}

.form-input {
    /*margin: 2px;*/
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--grey-color);
    font-family: var(--font-family);
}

.input-icon {
    display: flex;
    justify-content: center;
    border: 1px solid var(--grey-color);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: var(--white-color);
}

.group-input .form-input {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.group-input {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Components/FormsComponents/Input/index.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,qCAAqC;IACrC,aAAa;AACjB;;AAEA;IACI,2CAA2C;IAC3C,yCAAyC;IACzC,4CAA4C;IAC5C,aAAa;;AAEjB;;AAEA;IACI,qCAAqC;IACrC,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,mCAAmC;IACnC,+BAA+B;AACnC;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mCAAmC;IACnC,2BAA2B;IAC3B,8BAA8B;IAC9B,8BAA8B;AAClC;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".form-group {\r\n    align-content: center;\r\n    width: 70%;\r\n}\r\n\r\n.form-input:focus {\r\n    border: 1px solid var(--orange-color);\r\n    outline: none;\r\n}\r\n\r\n.group-input:focus-within .form-input {\r\n    border-right: 1px solid var(--orange-color);\r\n    border-top: 1px solid var(--orange-color);\r\n    border-bottom: 1px solid var(--orange-color);\r\n    outline: none;\r\n\r\n}\r\n\r\n.group-input:focus-within .input-icon {\r\n    border: 1px solid var(--orange-color);\r\n    outline: none;\r\n}\r\n\r\n.form-input {\r\n    /*margin: 2px;*/\r\n    padding: 5px;\r\n    border-radius: 5px;\r\n    border: 1px solid var(--grey-color);\r\n    font-family: var(--font-family);\r\n}\r\n\r\n.input-icon {\r\n    display: flex;\r\n    justify-content: center;\r\n    border: 1px solid var(--grey-color);\r\n    border-top-left-radius: 5px;\r\n    border-bottom-left-radius: 5px;\r\n    background: var(--white-color);\r\n}\r\n\r\n.group-input .form-input {\r\n    border-left: none;\r\n    border-top-left-radius: 0;\r\n    border-bottom-left-radius: 0;\r\n}\r\n\r\n.group-input {\r\n    display: flex;\r\n    flex-direction: row;\r\n    margin-bottom: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
