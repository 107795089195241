import React from "react";

export default React.createContext({
    isOpen: true,
    setIsOpen: (value) => {
    },
    routeSelected: null,
    setRouteSelected: (value) => {
    }
})
