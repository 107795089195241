function getDay(day){
    switch (day){
        case 0 :
            return "dimanche";
            break;
        case 1 :
            return "lundi";
            break;
        case 2 :
            return "mardi";
            break;
        case 3 :
            return "mercredi";
            break;
        case 4 :
            return "jeudi";
            break;
        case 5 :
            return "vendredi";
            break;
        case 6 :
            return "samedi";
            break;
    }
}
/**
 * Convertit un objet JSON en instance de FormData.
 * @param {Object} json - L'objet JSON à convertir.
 * @param {FormData} [formData] - Instance de FormData à compléter (facultatif).
 * @param {String} [parentKey] - Clé parent utilisée pour la récursion (facultatif).
 * @returns {FormData} - L'instance de FormData résultante.
 */
function jsonToFormData(json, formData = new FormData(), parentKey = '') {
    if (json && typeof json === 'object' && !(json instanceof Date) && !(json instanceof File)) {
        Object.keys(json).forEach(key => {
            const value = json[key];
            const formKey = parentKey ? `${parentKey}[${key}]` : key;

            if (Array.isArray(value)) {
                value.forEach((element, index) => {
                    jsonToFormData(element, formData, `${formKey}[${index}]`);
                });
            } else if (typeof value === 'object' && value !== null) {
                jsonToFormData(value, formData, formKey);
            } else {
                formData.append(formKey, value);
            }
        });
    } else {
        formData.append(parentKey, json);
    }
    return formData;
}
export default {
    getDay,
    jsonToFormData
}