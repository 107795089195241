import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Color from '../../../Styles';
import '../../../Styles/ModalForm.css';
import Input from "../../FormsComponents/Input";
import SelectInput from "../../FormsComponents/SelectInput";
import TextAreaInput from "../../FormsComponents/TextAreaInput";
import SvgIcon from "@mui/material/SvgIcon";
import {Close, Print, Save} from "@mui/icons-material";
import activiteAPI from "../../../Services/activiteAPI";
import moment from "moment";
import {modalStyle} from "../../../Styles/ModalStyle";
import {Button, Switch} from '@mui/material'
import {MobileTimePicker} from "@mui/x-date-pickers";
import 'moment/locale/fr';
import DatePicker from "../../DatePicker";
import {EmployeAPI} from "../../../Services/EmployeAPI";
import PlanningActiviteAPI from "../../../Services/planningActiviteAPI";
import Badge from 'react-bootstrap/Badge';
import {toast} from "react-toastify";
import RecurrenceFunc from "../../../Services/recurrenceFunc";
import Stack from 'react-bootstrap/Stack';
import {ClientAPI} from "../../../Services/clientAPI";
import ModalEmployePlanification from "../ModalEmployePlanification";
import Tooltip from "@mui/material/Tooltip";
import planningActiviteAPI from "../../../Services/planningActiviteAPI";
import recurrenceFunc from "../../../Services/recurrenceFunc";
import pako from 'pako';

const   ModalActivite = ({
                           isOpen,
                           setIsOpen,
                           groupeActivites,
                           prestataires,
                           updateActivite,
                           activites,
                           updateClient,
                           fetchPlanningActivite
                       }) => {
    const [sousActivite1, setSousActivite1] = useState([]);
    const [sousActivite2, setSousActivite2] = useState([]);
    const [sousActivite3, setSousActivite3] = useState([]);
    const [activite, setActivite] = useState({nombreSalarie : 0})
    const [faites, setFaites] = useState([]);
    const [localisation, setLocalisation] = useState([
        {value: {value: "EXTERNE", label: "Externe"}, label: "Externe"},
        {value: {value: "INTERNE", label: "Interne"}, label: "Interne"}
    ])
    const [encadrants, setEncadrants] = useState([]);
    const [frequence, setFrequence] = useState([
        {value: {value: "days", label: "Jours"}, label: "Jours"},
        {value: {value: "weeks", label: "Semaine"}, label: "Semaine"},
        {value: {value: "months", label: "Mois"}, label: "Mois"},
        {value: {value: "years", label: "An"}, label: "An"}
    ])
    const [recurrence, setRecurrence] = useState({periodicite : 1});
    const [edit, setEdit] = useState(false);
    const [toValidate, setToValidate] = useState(false);
    const [clients, setClients] = useState([]);
    const [salarie, setSalarie] = useState([]);
    const [salarieDispo, setSalarieDispo] = useState([]);
    const [allSalarie, setAllSalarie] = useState([]);
    const [salarierRef, setSalarierRef] = useState(null);
    const [coordinateur, setCoordinateur] = useState(null);
    const [isRecurrente, setIsRecurrente] = useState(false);
    const [heuresDebut, setHeuresDebut] = useState(null);
    const [heuresFin, setHeuresFin] = useState(null);
    const [daysSelected, setDaysSelected] = useState([]);
    const [changeDays, setChangeDays] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [isOpenPlanifEmploye, setIsOpenPlanifEmploye] = useState(false);
    const fetchChildren = async (parent, setSousActivite) => {
        if (parent != undefined && parent != null) {
            parent = parent.id
            try {
                let response = await activiteAPI.fetchChildren(parent);
                let options = [];
                response.data.map((sousA) => {
                    options.push({value: sousA, label: sousA.libelle})
                })
                setSousActivite(options.sort((a, b) => a.label.localeCompare(b.label)))
            } catch (e) {
                console.log(e)
            }
        }
    }


    const fetchClient = async () => {
        if (activite.groupeActivite != undefined){
            try {
                const response = await ClientAPI.fetchClientByGroupActivite(activite.groupeActivite.id);
                return response.data;
            }catch (e){
                console.log(e)
            }
        }

    }

    const fetchEncadrant = async () => {
        try {
            let response = await EmployeAPI.getEmployeByType("Encadrant");
            return response.data;
        } catch (e) {
            toast.error("Une erreur est survenue");
            console.log(e);
        }
    }

    // const fetchErrorMessage = async (employeID) => {
    //
    //     try {
    //         let activiteId;
    //         if (activite.activite != undefined){
    //             if (activite.sousactivite3 != null && activite.sousactivite3 != undefined) {
    //                 activiteId = activite.sousactivite3.id;
    //             } else if (activite.sousactivite2 != null && activite.sousactivite2 != undefined) {
    //                 activiteId = activite.sousactivite2.id;
    //             } else if (activite.sousactivite1 != null && activite.sousactivite1 != undefined) {
    //                 activiteId = activite.sousactivite1.id;
    //             } else {
    //                 activiteId = activite.activite.id;
    //             }
    //         }
    //         let dateDebut;
    //         let dateFin;
    //         if (isRecurrente){
    //             dateDebut = moment(moment(recurrence.dateDebut).format("YYYY-MM-DD") + "T" + moment(heuresDebut["_d"]).locale("fr").format("HH:mm:ss")).locale('fr').format("YYYY-MM-DD HH:mm:ss")
    //             dateFin = moment(moment(recurrence.dateFin).format("YYYY-MM-DD") + "T" + moment(heuresFin["_d"]).locale("fr").format("HH:mm:ss")).locale('fr').format("YYYY-MM-DD HH:mm:ss")
    //         }else {
    //             dateDebut = moment(moment(activite.dateDebut).format("YYYY-MM-DD") + "T" + moment(heuresDebut["_d"]).locale("fr").format("HH:mm:ss")).locale('fr').format("YYYY-MM-DD HH:mm:ss")
    //             dateFin = moment(moment(activite.dateFin).format("YYYY-MM-DD") + "T" + moment(heuresFin["_d"]).locale("fr").format("HH:mm:ss")).locale('fr').format("YYYY-MM-DD HH:mm:ss")
    //         }
    //
    //
    //         const response = await EmployeAPI.getErrorMessage(dateDebut, dateFin,activiteId, employeID.value.id )
    //         if (response.data != undefined && response.data != null){
    //             return response.data[0];
    //         }else{
    //             return null;
    //         }
    //
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }


    const setOptionEncadrant = (data) => {
        let options = [];
        data.map((e) => {
            options.push({value: e, label: e.nom +" " + e.prenom})
        });
        setEncadrants(options.sort((a, b) => a.label.localeCompare(b.label)))
    }

    const setOptionClient = async (e) => {
        if (e != undefined) {
            let options = [];
            e.map((em) => {
                options.push({value: em, label: em.nom})
            })
            setClients(options.sort((a, b) => a.label.localeCompare(b.label)))
        }
    }
    const handleChangeDateDebut = (e) => {
        setHeuresDebut(e);
        const hours = moment(e["_d"]).format('HH')
        const minutes = moment(moment(e["$m"]).format('mm')).format('mm')
        setActivite({...activite, heureDebut: [hours, minutes]})

    }
    const handleChangeDateFin = (e) => {
        setHeuresFin(e)
        const hours = moment(e["_d"]).format('HH')
        const minutes = moment(e["$m"]).format('mm')
        setActivite({...activite, heureFin: [hours, minutes]})
    }
    const handleChangeRecurrenceDebut = (e) => {
        setHeuresDebut(e);
        const hours = moment(e["_d"]).format('HH')
        const minutes = moment(moment(e["$m"]).format('mm')).format('mm')
        setRecurrence({...recurrence, ["heureDebut"]: ""+hours+ ":"+minutes+""})
    }
    const handleChangeRecurrenceFin = (e) => {
        setHeuresFin(e)
        const hours = moment(e["_d"]).format('HH')
        const minutes = moment(e["$m"]).format('mm')
        setRecurrence({...recurrence, ["heureFin"]: ""+hours+ ":"+minutes+""})
    }

    const onSearch = (event) => {
        if (event != ""){
            let sals = allSalarie.filter(sal => (sal.nom + " " + sal.prenom).toLowerCase().includes(event.toLowerCase()));
            setEmployeOptions(sals)
        }else{
            setEmployeOptions(salarieDispo)
        }
    }

    const removeEmployesForPlannif = (rowSelected) => {
        activite.employes = activite.employes.filter(emp => emp.em_numerosecuritesocial != rowSelected.em_numerosecuritesocial);
        setActivite({...activite, nombreSalarie: activite.employes.length})
        setErrorMessages(activite.employes)
    }

    function addRecurrence(recurrence, days) {
        let dayOfWeek = [];
        let heureDebut = moment(heuresDebut["_d"]).format('HH:mm:ss')
        let heureFin = moment(heuresFin["_d"]).format('HH:mm:ss')

        let dateDebut  = moment(moment(recurrence.dateDebut).format("YYYY-MM-DD") + "T" + moment(heuresDebut["_d"]).locale("fr").format("HH:mm:ss")).locale('fr')
        let dateFin = moment(moment(recurrence.dateFin).format("YYYY-MM-DD") + "T" + moment(heuresFin["_d"]).locale("fr").format("HH:mm:ss")).locale('fr')

        if (recurrence.frequence.value == "days") {
            days.map((j) => {
                if (j == 'lundi') {
                    dayOfWeek.push(1)
                }
                if (j == 'mardi') {
                    dayOfWeek.push(2)
                }
                if (j == 'mercredi') {
                    dayOfWeek.push(3)
                }
                if (j == 'jeudi') {
                    dayOfWeek.push(4)
                }
                if (j == 'vendredi') {
                    dayOfWeek.push(5)
                }
                if (j == 'samedi') {
                    dayOfWeek.push(6)
                }
                if (j == 'dimanche') {
                    dayOfWeek.push(0)
                }
            })
            // console.log(RecurrenceFunc.getDatesForDayOfWeek(dateDebut, dateFin, dayOfWeek, heureDebut, heureFin))
            return RecurrenceFunc.getDatesForDayOfWeek(dateDebut, dateFin, dayOfWeek, heureDebut, heureFin)
        } else {
            days.map((j) => {
                if (j == 'lundi') {
                    dayOfWeek.push(1)
                }
                if (j == 'mardi') {
                    dayOfWeek.push(2)
                }
                if (j == 'mercredi') {
                    dayOfWeek.push(3)
                }
                if (j == 'jeudi') {
                    dayOfWeek.push(4)
                }
                if (j == 'vendredi') {
                    dayOfWeek.push(5)
                }
                if (j == 'samedi') {
                    dayOfWeek.push(6)
                }
                if (j == 'dimanche') {
                    dayOfWeek.push(0)
                }
            })
            // console.log(RecurrenceFunc.getDatesForDayMensuelle(dateDebut, dateFin, recurrence.periodicite, dayOfWeek, heureDebut, heureFin))
            return RecurrenceFunc.calculerRecurrence(dateDebut, dateFin,recurrence.frequence.value, recurrence.periodicite, dayOfWeek, heureDebut, heureFin)
        }

        return [];
    }

    const handleSubmit = async () => {
        try {
            let newAct = {}
            newAct.localisation = activite.localisation;
            newAct.groupeActivite = {
                id : activite.groupeActivite.id,
                libelle : activite.groupeActivite.libelle
            };
            newAct.client = {
                id : activite.client.id,
                nom: activite.client.id,
                numero: activite.client.id,
                adresse: activite.client.id,
                adresse2: activite.client.id,
                codePostal: activite.client.id,
                mail: activite.client.id,
                ville: activite.client.id
            };
            newAct.nbrsalarietheorique = activite.nbrsalarietheorique;
            newAct.prestataire = activite.prestataire;
            newAct.encadrant = activite.encadrant;
            newAct.employes = [];
            newAct.nombreSalarie = activite.nombreSalarie;
            newAct.dateDebut = moment(moment(activite.dateDebut).format("YYYY-MM-DD") + "T" + moment(heuresDebut["_d"]).locale("fr").format("HH:mm:ss")).locale('fr').format("YYYY-MM-DDTHH:mm:ssZ")
            newAct.dateFin = moment(moment(activite.dateFin).format("YYYY-MM-DD") + "T" + moment(heuresFin["_d"]).locale("fr").format("HH:mm:ss")).locale('fr').format("YYYY-MM-DDTHH:mm:ssZ")
            if (toValidate === true){
                newAct.etat = "ok";
            }else{
                newAct.etat = "danger";
            }
            if (activite.sousactivite3 != null && activite.sousactivite3 != undefined) {
                newAct.activites = activite.sousactivite3;
            } else if (activite.sousactivite2 != null && activite.sousactivite2 != undefined) {
                newAct.activites = activite.sousactivite2;
            } else if (activite.sousactivite1 != null && activite.sousactivite1 != undefined) {
                newAct.activites = [];
                activite.sousactivite1.map((acti) => {
                    newAct.activites.push(acti.value);
                })
            } else {
                newAct.activites = [activite.activite];
            }

            if (activite.employes != undefined && activite.employes != null && activite.employes.length > 0) {
                newAct.employes = await EmployeAPI.getEmployesByIDS(activite.employes);
                if (newAct.employes == null && newAct.employes.length <= 0) {
                    newAct.nombreSalarie = 0;
                }
            }else{
                newAct.nombreSalarie = 0;
            }

            setTimeout(async () => {
                if (isRecurrente == true) {
                    newAct.recurrence = true;
                    newAct.dateDebut = moment(moment(recurrence.dateDebut).format("YYYY-MM-DD") + "T" + moment(heuresDebut["_d"]).locale("fr").format("HH:mm:ss")).locale('fr').format('YYYY-MM-DDTHH:mm:ssZ')
                    newAct.dateFin = moment(moment(recurrence.dateDebut).format("YYYY-MM-DD") + "T" + moment(heuresFin["_d"]).locale("fr").format("HH:mm:ss")).locale('fr').format('YYYY-MM-DDTHH:mm:ssZ')
                    let recurrenceToAdd = addRecurrence(recurrence, daysSelected);
                    PlanningActiviteAPI.recurrence(recurrenceToAdd, newAct).then(() => {
                        fetchPlanningActivite();
                    })
                    toast.success("L'activité a bien été planifiée")
                } else {
                    newAct.is_recurente = false;
                    console.log(newAct);
                    let response = await PlanningActiviteAPI.add(pako.gzip(JSON.stringify(newAct)));
                    toast.success("L'activité a bien été planifiée")
                    setTimeout(async () => {
                        fetchPlanningActivite();
                    }, 500)
                }
            }, 500)

        } catch (e) {
            toast.error("L'activité n'a pas bien été planifiée")
            console.log(e)
        }
    }

    const handleChangeDays = (days) => {
        if (daysSelected.includes(days)) {
            let index = daysSelected.indexOf(days);
            if (index !== -1) {
                daysSelected.splice(index, 1);
            }
        } else {
            daysSelected.push(days)
        }
        setDaysSelected(daysSelected);
    }


    useEffect(() => {
        if (activite.groupeActivite && activite.client ){
            updateActivite(activite.client, activite.groupeActivite)
            setActivite({groupeActivite: activite.groupeActivite, client: activite.client })
        }

    }, [activite.client]);

    useEffect(() => {
        fetchClient().then(res => setOptionClient(res))
        setActivite({groupeActivite : activite.groupeActivite})
    }, [activite.groupeActivite]);

    useEffect(() => {
        if (activite.activite != undefined){
            setFaites(activite.activite.faites)
            fetchChildren(activite.activite, setSousActivite1)
            setActivite({groupeActivite: activite.groupeActivite, client: activite.client, activite : activite.activite })
        }
    }, [activite.activite]);

    useEffect(() => {
        if (activite.sousactivite1 && activite.sousactivite1.length > 0 ){
            setCoordinateur(activite.sousactivite1[0].value.coordinateur)
            setFaites(activite.sousactivite1[0].value.faites);
            setActivite({groupeActivite: activite.groupeActivite,
                sousactivite1: activite.sousactivite1,
                client: activite.client,
                activite : activite.activite,
                nbrsalarietheorique: activite.sousactivite1[0].value.faites[0].nombreSalarie,
                localisation : activite.sousactivite1[0].value.localisation})
        }
    }, [activite.sousactivite1]);

    useEffect(() => {
        if (activite.sousactivite2){
            setFaites(activite.sousactivite2.faites)
            fetchChildren(activite.sousactivite2, setSousActivite3)
            setActivite({groupeActivite: activite.groupeActivite, client: activite.client, activite : activite.activite, sousactivite1 : activite.sousactivite1, sousactivite2 : activite.sousactivite2})
        }
    }, [activite.sousactivite2]);

    useEffect(() => {
        if (activite.sousactivite3){
            setFaites(activite.sousactivite3.faites)
            setActivite({groupeActivite: activite.groupeActivite, client: activite.client, activite : activite.activite, sousactivite1 : activite.sousactivite1, sousactivite2 : activite.sousactivite2, sousactivite3 : activite.sousactivite3})
        }
    }, [activite.sousactivite3]);

    useEffect(() => {
        if (faites != null) {
            let res = [];
            if (activite.sousactivite3){
                res = faites.filter((faite) => {
                    return faite.activite.id == activite.sousactivite3.id && activite.client.id == faite.client.id;
                })
            }else if(activite.sousactivite2){
                res = faites.filter((faite) => {
                    return faite.activite.id == activite.sousactivite2.id && activite.client.id == faite.client.id;
                })
            }else if(activite.sousactivite1){
                res = faites.filter((faite) => {
                    return faite.activite.id == activite.sousactivite1[0].value.id && activite.client.id == faite.client.id;
                })
            }else{
                res = faites.filter((faite) => {
                    return faite.activite.id == activite.activite.id && activite.client.id == faite.client.id;
                })
            }

            if (res.length > 0) {
                setActivite({...activite, ["nbrsalarietheorique"]: res[0].nombreSalarie, ["localisation"] : res[0].activite.localisation})
                setCoordinateur(res[0].activite.coordinateur)
            }
        }
    }, [faites]);




    useEffect(() => {
        let error = false;
        errorMessages.map(err => {
            if (err.dejaplanifie != "false" || err.isindisponible != "false" || err.donthavehabilitation != "false" || err.workplanning != "false" ){
                error = true;
            }
        })
        if (activite.nombreSalarie != undefined && activite.nbrsalarietheorique != undefined) {
            if (activite.nombreSalarie >= activite.nbrsalarietheorique && error == false){
                setToValidate(true)
            }else{
                setToValidate(false)
            }
        }
    }, [activite.nombreSalarie, errorMessages]);



    useEffect(() => {
        setActivite({...activite, ["dateFin"] : activite.dateDebut})
        fetchEncadrant().then(res => setOptionEncadrant(res))
    }, [activite.dateDebut]);

    return <><Modal open={isOpen}
                  onClose={() => {
                      setIsOpen(!isOpen)
                      setToValidate(false)
                  }}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h5" component="h2"
                        className={"d-flex justify-content-center p-2 border-bottom"}>
                Planifier une Activité
            </Typography>
            <div className={"form-modal flex flex-column gap-2"}>
                <div className={"mb-2"}>
                    <SelectInput classNameLabel={"text-end mr-2 pr-5"} required={true} label={"Groupe Activité"}
                                 placeholder={"Groupe Activité"} flex={"row"} options={groupeActivites}
                                 name={"groupeActivite"} value={activite.groupeActivite ? {
                        value: activite.groupeActivite,
                        label: activite.groupeActivite.libelle
                    } : null} objet={activite}
                                 setObjet={setActivite}/>
                </div>
                {activite.groupeActivite && <>
                    <div className={"mb-2"}>
                        <SelectInput classNameLabel={"text-end mr-2 pr-5"} required={true} type={"select"}
                                     name={"client"} flex={"row"} placeholder={"Client"} options={clients}
                                     value={activite.client ? {value: activite.client, label: activite.client.nom} : null}
                                     objet={activite} setObjet={setActivite}
                                     label={"Client"}></SelectInput>
                    </div>
                </>}
                {activite.client && <>
                    <div className={"mb-2"}>
                        <SelectInput classNameLabel={"text-end mr-2 pr-5"} required={true} type={"select"}
                                     name={"activite"} flex={"row"} placeholder={"Activite"}
                                     value={activite.activite ? {
                                         value: activite.activite,
                                         label: activite.activite.libelle
                                     } : null}
                                     objet={activite} setObjet={setActivite} label={"Activite"}
                                     options={activites}></SelectInput>
                    </div>
                </>}
                {activite.activite && sousActivite1.length >= 1 && <>
                    <div className={"mb-2"}>
                        {/*<SelectInput classNameLabel={"text-end mr-2 pr-5"} required={false} type={"select"}*/}
                        {/*             name={"sousactivite1"} flex={"row"} placeholder={"Sous-Activité niv1"}*/}
                        {/*             value={activite.sousactivite1 ? {*/}
                        {/*                 value: activite.sousactivite1,*/}
                        {/*                 label: activite.sousactivite1.libelle*/}
                        {/*             } : null} objet={activite} setObjet={setActivite}*/}
                        {/*             label={"Sous-Activité niv1"} options={sousActivite1} isMulti={true}></SelectInput>*/}
                        <SelectInput isMulti={true} classNameLabel={"text-end mr-2 pr-5"} required={true}
                                     type={"select"}
                                     name={"sousactivite1"} flex={"row"} placeholder={"Sous-Activité niv1"}
                                     objet={activite} setObjet={setActivite}
                                     label={"Sous-Activité niv1"} options={sousActivite1}
                        ></SelectInput>
                    </div>
                </>}
                {/*{activite.sousactivite1 && sousActivite2.length >= 1 && <>*/}
                {/*    <div className={"mb-2"}>*/}
                {/*        <SelectInput classNameLabel={"text-end mr-2 pr-5"} required={false} type={"select"}*/}
                {/*                     name={"sousactivite2"} flex={"row"} placeholder={"Sous-Activité niv2"}*/}
                {/*                     value={activite.sousactivite2 ? {*/}
                {/*                         value: activite.sousactivite2,*/}
                {/*                         label: activite.sousactivite2.libelle*/}
                {/*                     } : null} objet={activite} setObjet={setActivite}*/}
                {/*                     label={"Sous-Activité niv2"} options={sousActivite2}></SelectInput>*/}
                {/*    </div>*/}
                {/*</>}*/}
                {activite.sousactivite2 && sousActivite3.length >= 1 && <>
                    <div className={"mb-2"}>
                        <SelectInput classNameLabel={"text-end mr-2 pr-5"} required={false} type={"select"}
                                     name={"sousactivite3"} flex={"row"} placeholder={"Sous-Activité niv3"}
                                     value={activite.sousactivite3 ? {
                                         value: activite.sousactivite3,
                                         label: activite.sousactivite3.libelle
                                     } : null} objet={activite} setObjet={setActivite}
                                     label={"Sous-Activité niv3"} options={sousActivite3} isMulti={true}></SelectInput>
                    </div>
                </>}
                {activite.activite && <>
                    <div className={"mb-2"}>
                        <SelectInput classNameLabel={"text-end mr-2 pr-5"} required={false} type={"select"}
                                     name={"prestataire"} flex={"row"} placeholder={"Prestataire"}
                                     value={activite.prestataire ? {
                                         value: activite.prestataire,
                                         label: activite.prestataire.nom
                                     } : null} objet={activite} setObjet={setActivite}
                                     label={"Prestataire"} options={prestataires} ></SelectInput>
                        {/*<Input classNameLabel={"text-end mr-2 pr-5"} required={false} type={"text"}*/}
                        {/*       name={"prestataire"} flex={"row"} placeholder={"Prestataire"}*/}
                        {/*       value={activite.prestataire} objet={activite} setObjet={setActivite}*/}
                        {/*       label={"Préstataire"} disabled={true}></Input>*/}
                    </div>
                    <div className={"mb-2"}>
                        <SelectInput classNameLabel={"text-end mr-2 pr-5"} required={false} type={"select"}
                                     name={"localisation"} flex={"row"} placeholder={"Localisation"}
                                     value={activite.localisation ? {
                                         value: activite.localisation,
                                         label: activite.localisation
                                     } : null} objet={activite} setObjet={setActivite}
                                     label={"Localisation"} options={localisation} disabled={true}></SelectInput>
                    </div>
                </>}
                {activite.localisation && <>
                    <div className={"mb-2"}>
                        <Input classNameLabel={"text-end mr-2 pr-5"} required={false} type={"number"}
                               name={"nbrsalarietheorique"} flex={"row"} placeholder={"Nb Salariés théorique"}
                               value={activite.nbrsalarietheorique} objet={activite} setObjet={setActivite}
                               label={"Nb Salariés théorique"} disabled={true}></Input>
                    </div>
                    <div className={"mb-2"}>
                        <Input classNameLabel={"text-end mr-2 pr-5"} required={false} type={"text"}
                               name={"coordinateur"} flex={"row"} placeholder={"Coordinateur"}
                               value={coordinateur != undefined && coordinateur.nom + " " + coordinateur.prenom}
                               label={"Coordinateur"} disabled={true}></Input>
                    </div>
                </>}

                {(isRecurrente == false && activite.localisation) && <>
                    <div className={"mb-2 form-group flex"}>
                        <label htmlFor={"dateDebut"} className={"form-label mt-1 text-end col-5 pr-5"}
                               style={{width: "41.66666667%"}}>{"Date Début"} * :</label>
                        <div className={"pl-2 col-6"} id={"dateDebut"}>
                            <DatePicker name={"dateDebut"} objet={activite}
                                        setObjet={setActivite} objectIsMoment={false}
                                        value={(activite.dateDebut != null && activite.dateDebut != undefined) ? moment(activite.dateDebut) : null}/>
                        </div>
                        <div className={"pl-2 col-6 justify-content-end"}>
                            <MobileTimePicker label={'Heures'} openTo="hours" className={"datePicker"}
                                              onChange={handleChangeDateDebut} ampm={false} value={heuresDebut}/></div>
                    </div>
                    <div className={"mb-2 form-group flex"}>
                        <label htmlFor={"dateFin"} className={"form-label mt-1 text-end col-5 pr-5"}
                               style={{width: "41.66666667%"}}>{"Date fin"} * :</label>
                        <div className={"pl-2 col-6"} id={"dateFin"}>
                            <DatePicker name={"dateFin"} objet={activite}
                                        setObjet={setActivite} objectIsMoment={false}
                                        value={(activite.dateFin != null && activite.dateFin != undefined) ? moment(activite.dateFin) : null}/>
                        </div>
                        <div className={"pl-2 col-6 justify-content-end"}>
                            <MobileTimePicker label={'Heures'} openTo="hours" className={"datePicker"}
                                              onChange={handleChangeDateFin} ampm={false} value={heuresFin}/>
                        </div>
                    </div>
                </>}
                {(activite.dateDebut || recurrence.dateDebut) && <>
                    <div className={"mb-2 form-group flex"}>
                        <label htmlFor={"recurrence"} className={"form-label mt-1 text-end col-5 pr-5"}
                               style={{width: "41.66666667%"}}>Recurrence :</label>
                        <Switch id={"recurrence"} checked={isRecurrente} onChange={(e) => {
                            if (e.target.checked === true) {
                                setRecurrence({
                                    ...recurrence,
                                    ["dateDebut"]: moment(activite.dateDebut).format("YYYY-MM-DD"),
                                    ["dateFin"]: moment(activite.dateFin).format("YYYY-MM-DD"),
                                    ["heureDebut"]: activite.heureDebut,
                                    ["heureFin"]: activite.heureFin
                                })
                                setIsRecurrente(!isRecurrente)
                                setActivite({...activite, ['dateDebut']: null, ['dateFin']: null})
                            } else if (e.target.checked === false) {
                                setActivite({
                                    ...activite,
                                    ["dateDebut"]: moment(recurrence.dateDebut).format("YYYY-MM-DD"),
                                    ["dateFin"]: moment(recurrence.dateFin).format("YYYY-MM-DD"),
                                    ["heureDebut"]: recurrence.heureDebut,
                                    ["heureFin"]: recurrence.heureFin
                                })
                                setRecurrence({...recurrence, ['dateDebut']: null, ['dateFin']: null})
                                setIsRecurrente(!isRecurrente)
                            }
                        }}/>
                    </div>
                    {/*<div className={"mb-2"}>*/}
                    {/*    <Switch />*/}
                    {/*    /!*<Input classNameLabel={"text-end mr-2 pr-5"} required={false} type={"text"} name={"recurrence"}*!/*/}
                    {/*    /!*       flex={"row"} placeholder={"Recurrence"} value={activite.recurrence} objet={activite}*!/*/}
                    {/*    /!*       setObjet={setActivite} label={"Recurrence"}></Input>*!/*/}
                    {/*</div>*/}
                </>}
                {isRecurrente && <>
                    <div className={"mb-2 form-group flex"}>
                        <label htmlFor={"dateDebut"} className={"form-label mt-1 text-end col-5 pr-5"}
                               style={{width: "41.66666667%"}}>{"Date Début"} * :</label>
                        <div className={"pl-2 col-6"} id={"dateDebut"}>
                            <DatePicker name={"dateDebut"} objet={recurrence}
                                        setObjet={setRecurrence} value={moment(recurrence.dateDebut)} objectIsMoment={false}/>
                        </div>
                        <div className={"pl-2 col-6 justify-content-end"}>
                            <MobileTimePicker label={'Heures Debut'} openTo="hours" className={"datePicker"}
                                              onChange={handleChangeRecurrenceDebut} ampm={false} value={heuresDebut}/>
                        </div>
                    </div>
                    <div className={"mb-2 form-group flex"}>
                        <label htmlFor={"frequence"} className={"form-label mt-1 text-end col-5 pr-5"}
                               style={{width: "41.66666667%"}}>{"Répetez chaque"} * :</label>
                        <div className={"pl-2 col-6"} id={"dateFin"}>
                            <Input classNameLabel={"text-end mr-2 pr-5"} required={false} type={"number"}
                                   name={"periodicite"} flex={"row"} placeholder={"Periodicite"}
                                   objet={recurrence} setObjet={setRecurrence}
                                   value={recurrence.periodicite != undefined && recurrence.periodicite}></Input>
                        </div>
                        <div className={"pl-2 col-6 justify-content-end"}>
                            <SelectInput
                                classNameLabel={"text-end mr-2 pr-5"} required={false} type={"select"}
                                name={"frequence"} flex={"row"}
                                value={recurrence.frequence && {
                                    value: recurrence.frequence,
                                    label: recurrence.frequence.label
                                }} objet={recurrence} setObjet={setRecurrence}
                                options={frequence} />
                        </div>
                    </div>

                    <div className={"mb-2 form-group flex"}>
                        <label htmlFor={"dateFin"} className={"form-label mt-1 text-end col-5 pr-5"}
                               style={{width: "41.66666667%"}}>{"Date fin"} * :</label>
                        <div className={"pl-2 col-6"} id={"dateFin"}>
                            <DatePicker name={"dateFin"} objet={recurrence}
                                        setObjet={setRecurrence} value={moment(recurrence.dateFin)} objectIsMoment={false}/>
                        </div>
                        <div className={"pl-2 col-6 justify-content-end"}>
                            <MobileTimePicker label={'Heures Fin'} openTo="hours" className={"datePicker"}
                                              onChange={handleChangeRecurrenceFin} ampm={false} value={heuresFin}/>
                        </div>
                    </div>
                <div className={"d-flex col-12"}>
                    <div  className={"form-label mt-1 text-end col-5 pr-5"}
                       style={{width: "41.66666667%"}}></div>
                    <Stack direction="horizontal" gap={2} >
                        <Badge pill bg={daysSelected.includes('lundi') ? "primary" : "secondary"} className={"badge-days z-2"} onClick={() => {handleChangeDays("lundi"); setChangeDays(!changeDays)}}>
                            L
                        </Badge>
                        <Badge pill bg={daysSelected.includes('mardi') ? "primary" : "secondary"} className={"badge-days"} onClick={() => {handleChangeDays("mardi"); setChangeDays(!changeDays)}}>
                            M
                        </Badge>
                        <Badge pill bg={daysSelected.includes('mercredi') ? "primary" : "secondary"} className={"badge-days"} onClick={() => {handleChangeDays("mercredi"); setChangeDays(!changeDays)}}>
                            M
                        </Badge>
                        <Badge pill bg={daysSelected.includes('jeudi') ? "primary" : "secondary"} className={"badge-days"} onClick={() => {handleChangeDays("jeudi"); setChangeDays(!changeDays)}}>
                            J
                        </Badge>
                        <Badge pill bg={daysSelected.includes('vendredi') ? "primary" : "secondary"} className={"badge-days"} onClick={() => {handleChangeDays("vendredi"); setChangeDays(!changeDays)}}>
                            V
                        </Badge>
                        <Badge pill bg={daysSelected.includes('samedi') ? "primary" : "secondary"} className={"badge-days"} onClick={() => {handleChangeDays("samedi"); setChangeDays(!changeDays)}}>
                            S
                        </Badge>
                        <Badge pill bg={daysSelected.includes('dimanche') ? "primary" : "secondary"} className={"badge-days"} onClick={() => {handleChangeDays("dimanche"); setChangeDays(!changeDays) }}>
                            D
                        </Badge>
                    </Stack>
                </div>
                    {/*<div>*/}
                    {/*    <div  className={"form-label mt-1 text-end col-5 pr-5"}*/}
                    {/*       style={{width: "41.66666667%"}}></div>*/}
                        {/*<span>A lieu </span>*/}
                    {/*</div>*/}
                </>}
                {((activite.dateDebut != undefined && activite.dateFin != undefined) || (recurrence.dateDebut != undefined && recurrence.dateFin != undefined)) && <>
                    {/* Encadrant */}
                    <SelectInput
                        classNameLabel={"text-end mr-2 pr-5"}
                        label="Encadrant"
                        name={"encadrant"}
                        options={encadrants}
                        value={activite.encadrant && {value: activite.encadrant, label: activite.encadrant.nom + ' ' +activite.encadrant.prenom }}
                        objet={activite}
                        setObjet={setActivite}
                        required={true}
                    />
                    <div className={"mb-2"}>
                        <div className={"d-flex justify-content-end"}>
                            <button className={"btn btn-alliance"} onClick={() => {
                                setIsOpenPlanifEmploye(true);
                                setIsOpen(false)
                            }}>
                                Planifier les employés
                            </button>
                        </div>
                        {/*<SelectInput isMulti={true} classNameLabel={"text-end mr-2 pr-5"} required={false}*/}
                        {/*             type={"select"}*/}
                        {/*             name={"employes"} flex={"row"} placeholder={"Liste des Salariés"}*/}
                        {/*    //value={activite.salaries && {value : activite.salaries, label: activite.salaries.nom + " " + activite.salaries.prenom}}*/}
                        {/*             objet={activite} setObjet={setActivite}*/}
                        {/*             label={"Liste des Salariés"} options={salarie}*/}
                        {/*             setSelectRef={setSalarierRef} onInputChange={onSearch}></SelectInput>*/}
                        <div className={"flex justify-content-center"}>
                            {(activite.nombreSalarie < activite.nbrsalarietheorique) ? <>
                                <Badge bg={"danger"}
                                       pill>{activite.nombreSalarie + ' planifié sur ' + activite.nbrsalarietheorique + " théorique"}</Badge>
                            </> : <>
                                <Badge bg={"success"}
                                       pill>{activite.nombreSalarie + ' planifié sur ' + activite.nbrsalarietheorique + " théorique"}</Badge>
                            </>}
                        </div>
                    </div>
                    {errorMessages.length > 0 && errorMessages.map(err => {
                        return err.dejaplanifie == "false" && err.isindisponible == "false" && err.donthavehabilitation == "false"&& err.workplanning == "false" ? <div className={"d-flex "}>
                            <Badge key={err.em_nom+"success"} bg={"success"} className={"col-11 mr-3"} pill>{err.em_nom +" " + err.em_prenom }</Badge>
                            <div className="col-1">
                                <Tooltip title={"Retirer cette employé de la planification"}>
                                    <button className="btn-sm btn-refresh ml-1 rounded" onClick={() => {removeEmployesForPlannif(err)}}>
                                        <SvgIcon sx={{color: "#FFFFFF"}}>
                                            <Close />
                                        </SvgIcon>
                                    </button>
                                </Tooltip>
                            </div>
                        </div> : <>
                        {err.dejaplanifie != "false" && <div className={"d-flex "}>
                            <Badge key={err.dejaplanifie} bg={"danger"} className={"col-11 mr-3"}
                                   pill>{err.em_nom + " " + err.em_prenom + " => " + err.dejaplanifie}</Badge>
                            <div className="col-1">
                                <Tooltip title={"Retirer cette employé de la planification"}>
                                    <button className="btn-sm btn-refresh ml-1 rounded" onClick={() => {
                                        removeEmployesForPlannif(err)
                                    }}>
                                        <SvgIcon sx={{color: "#FFFFFF"}}>
                                            <Close/>
                                        </SvgIcon>
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                        }
                        {
                            err.isindisponible != "false" && <div className={"d-flex "}>
                                <Badge key={err.isindisponible} bg={"danger"} className={"col-11 mr-3"}
                                       pill>{err.em_nom + " " + err.em_prenom + " => " + err.isindisponible}</Badge>
                                <div className="col-1">
                                    <Tooltip title={"Retirer cette employé de la planification"}>
                                        <button className="btn-sm btn-refresh ml-1 rounded" onClick={() => {
                                            removeEmployesForPlannif(err)
                                        }}>
                                            <SvgIcon sx={{color: "#FFFFFF"}}>
                                                <Close/>
                                            </SvgIcon>
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                        }
                        {
                            err.donthavehabilitation != "false" && <div className={"d-flex "}>
                                <Badge key={err.donthavehabilitation} bg={"danger"} className={"col-11 mr-3"}
                                       pill>{err.em_nom + " " + err.em_prenom + " => " + err.donthavehabilitation}</Badge>
                                <div className="col-1">
                                    <Tooltip title={"Retirer cette employé de la planification"}>
                                        <button className="btn-sm btn-refresh ml-1 rounded" onClick={() => {
                                            removeEmployesForPlannif(err)
                                        }}>
                                            <SvgIcon sx={{color: "#FFFFFF"}}>
                                                <Close/>
                                            </SvgIcon>
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                    }
                        {
                            err.workplanning != "false" && <div className={"d-flex "}>
                                <Badge key={err.workplanning} bg={"danger"} className={"col-11 mr-3"}
                                       pill>{err.em_nom + " " + err.em_prenom + " => " + err.workplanning}</Badge>
                                <div className="col-1">
                                    <Tooltip title={"Retirer cette employé de la planification"}>
                                        <button className="btn-sm btn-refresh ml-1 rounded" onClick={() => {
                                            removeEmployesForPlannif(err)
                                        }}>
                                            <SvgIcon sx={{color: "#FFFFFF"}}>
                                                <Close/>
                                            </SvgIcon>
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                    }
                    </>
                    })}
                </>}
                {(activite.employes != undefined && activite.employes.length > 0) && <>
                    <div className={"mb-2"}>
                        <TextAreaInput classNameLabel={"text-end mr-2 pr-5"} required={false} name={"commentaire"}
                                       flex={"row"} placeholder={"Commentaire"} value={activite.commentaire}
                                       objet={activite} setObjet={setActivite} label={"Commentaire"}></TextAreaInput>
                    </div>
                    {/*<div className={"mb-2"}>*/}
                    {/*    <Input classNameLabel={"text-end mr-2 pr-5"} required={false} name={"controle"} flex={"row"}*/}
                    {/*           placeholder={"Contrôle de cohérence"} value={activite.controle} objet={activite}*/}
                    {/*           setObjet={setActivite} label={"Contrôle de cohérence"} disabled={true}></Input>*/}
                    {/*</div>*/}
                </>}

                <div className={"d-flex justify-content-end"}>
                    <p>Les champs marqués * sont obligatoires</p>
                </div>
                {edit &&
                    <div className={"d-flex justify-content-between"}>
                        <button className={"btn btn-danger"}>
                            Supprimer la planification
                        </button>
                        <button className={"btn btn-alliance rounded-circle"} onClick={() => {

                        }}>
                            <SvgIcon sx={{color: Color.white, height: 1}}>
                                <Print/>
                            </SvgIcon>
                        </button>
                    </div>
                }
            </div>
            <div id="modal-modal-footer" className={"d-flex-column p-2 border-top justify-content-end"}>
                {toValidate === false ?
                    <div className={"d-flex justify-content-end gap-3"}>
                        <button className={"btn btn-secondary"} onClick={() => {
                            setActivite({})
                            setIsOpen(false)
                            setToValidate(false)
                            setErrorMessages([])

                        }}>
                            Annuler
                        </button>
                        <button className={"btn btn-alliance"} onClick={() => {
                            setRecurrence({})
                            setIsRecurrente(false)
                            setIsOpen(false)
                            setActivite({nombreSalarie : 0})
                            setErrorMessages([])
                            handleSubmit().then(() => {
                                setTimeout(() => {
                                    fetchPlanningActivite()
                                }, 500)
                            })}
                        }>
                            <SvgIcon sx={{color: Color.white, height: 1}}>
                                <Save/>
                            </SvgIcon>
                            Sauvegarder malgré les avertissements
                        </button>
                    </div>
                    :
                    <div className={"d-flex justify-content-end gap-3 mt-3"}>
                        <button className={"btn btn-secondary"} onClick={() => {
                            setActivite({})
                            setIsOpen(false)
                            setToValidate(false)
                            setErrorMessages([])

                        }}>
                            Annuler
                        </button>
                        <button className={"btn btn-alliance"} onClick={() => {
                            setRecurrence({})
                            setIsRecurrente(false)
                            setIsOpen(false)
                            setActivite({nombreSalarie : 0})
                            setErrorMessages([])
                            handleSubmit().then(() => {
                                setTimeout(() => {
                                    fetchPlanningActivite()
                                }, 500)
                            })
                        }}>
                            <SvgIcon sx={{color: Color.white, height: 1}}>
                                <Save/>
                            </SvgIcon>
                            Sauvegarder
                        </button>
                    </div>
                }
            </div>
        </Box>
    </Modal>;
    <ModalEmployePlanification isOpen={isOpenPlanifEmploye} setIsOpen={setIsOpenPlanifEmploye} setIsOpenModalActivite={setIsOpen} activite={activite}  setActivite={setActivite} recurrence={recurrence} heuresFin={heuresFin} heuresDebut={heuresDebut} setErrorMessages={setErrorMessages}/>
    </>
};

export default ModalActivite;