import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './Styles/index.css';
import {HashRouter} from 'react-router-dom';
import {createTheme, ThemeProvider} from "@mui/material/styles";

const newTheme = (theme) => createTheme({
    ...theme,
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    height: "2.5em",
                    borderRadius: 8,
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid black`
                    },
                    "&.Mui-focused": {
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: `1px solid #EA5B0C`
                        }
                    }
                }
            }
        }
    }
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HashRouter>
        <ThemeProvider theme={newTheme}>
            <App/>
        </ThemeProvider>
    </HashRouter>
);
