// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
    display: flex;
    flex-direction: column;
    padding: 2%;
    /*margin: 0% 3% 3% 3%;*/
    border-radius: 10px;
}

.header2 {
    background: var(--background-header-2);
}

.header2 .entete {
    display: flex;
    justify-content: space-between;
}

.css-t3ipsp-control:hover {
    border-color: var(--orange-color) !important;
}

.css-t3ipsp-control {
    border-color: var(--orange-color) !important;
    box-shadow: var(--orange-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/Headers/Header2Generique/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,4CAA4C;AAChD;;AAEA;IACI,4CAA4C;IAC5C,0CAA0C;AAC9C","sourcesContent":[".box {\r\n    display: flex;\r\n    flex-direction: column;\r\n    padding: 2%;\r\n    /*margin: 0% 3% 3% 3%;*/\r\n    border-radius: 10px;\r\n}\r\n\r\n.header2 {\r\n    background: var(--background-header-2);\r\n}\r\n\r\n.header2 .entete {\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n\r\n.css-t3ipsp-control:hover {\r\n    border-color: var(--orange-color) !important;\r\n}\r\n\r\n.css-t3ipsp-control {\r\n    border-color: var(--orange-color) !important;\r\n    box-shadow: var(--orange-color) !important;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
