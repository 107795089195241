import React, {useContext, useState} from 'react';
import './index.css';
import {Link} from 'react-router-dom';
import SideBarContext from "../../../Context/SideBarContext";

const SubMenuCollapse = ({route, roles}) => {
    const [subnav, setSubNav] = useState(false);
    const {routeSelected, setRouteSelected} = useContext(SideBarContext);

    return <div>
        <Link className={"sideBarLinkCollapse"} key={route.name} to={route.link} onClick={route.collapse && (() => {
            setSubNav(!subnav)
            setRouteSelected(route.name)
        })}>
            <div key={route.name + "a"}>
                <span>{route.icon}</span>
            </div>
            {/*<div>*/}
            {/*    {route.collapse && subnav ? route.iconOpen : route.collapse ? route.iconClosed : null}*/}
            {/*</div>*/}
            <div className={"collapsePerso"}>
                {route.collapse != undefined && route.collapse.map((r, index) => {
                    if (r.visible == true && ( r.roles.includes(roles.replaceAll('[' , '').replaceAll(']', '')) || r.roles.length === 0)) {
                        return <Link className={"dropDownLinkCollapse"} to={r.link} key={r.name} onClick={() => {
                            setRouteSelected(r.name)
                        }}>
                            <span>{r.icon}</span>
                            <span className={"sideBarLabelCollapse"}>{r.name}</span>
                        </Link>
                    }
                })}
            </div>
        </Link>
    </div>

};

export default SubMenuCollapse;