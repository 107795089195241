import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SelectInput from "../../FormsComponents/SelectInput";
import Input from "../../FormsComponents/Input";
import Button from "react-bootstrap/Button"
import {toast} from "react-toastify";
import {modalStyle} from "../../../Styles/ModalStyle";
import {groupeActiviteAPI} from "../../../Services/groupeActiviteAPI";
import {ClientAPI} from "../../../Services/clientAPI";
import {HabilitationAPI} from "../../../Services/HabilitationAPI";
import client from "../../../Page/Client";
import PrestataireAPI from "../../../Services/prestataireAPI";
import ActiviteAPI, {activiteAPI} from "../../../Services/activiteAPI";
import {FaiteAPI} from "../../../Services/FaiteAPI";
import {EmployeAPI} from "../../../Services/EmployeAPI";
import ModalGroups from "../ModalGroups";
import ModalClient from "../ModalClient";
import ModalPrestataire from "../ModalPrestataire";
import ModalHabilitation from "../ModalHabilitation";
import {Delete, Edit} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import ModalDeleteGroup from "../ModalGroups/delete";
import ModalUpdateGroup from "../ModalGroups/update";
import ModalAjoutActiviteParent from "../ModalAjoutActiviteParent";

const ModalAjoutActivite = ({isOpen, setIsOpen, fetchActivite}) => {
    const [activite, setActivite] = useState({
        codeCouleur : "#000000",
        couleurText : "#FFFFFF"
    });
    const [activites, setActivites] = useState([]);
    const [activites2, setActivites2] = useState([]);
    const [activites3, setActivites3] = useState([]);
    const [activites4, setActivites4] = useState([]);
    const [faite, setFaite] = useState({});
    const [clients, setClients] = useState([]);
    const [groupeActivites, setGroupeActivites] = useState([]);
    const [groupeActivite, setGroupeActivite] = useState({});
    const [habilitations, setHabilitations] = useState([]);
    const [prestataires, setPrestataires] = useState([]);
    const [habilitationAct, setHabilitationAct] = useState([]);
    const [coordinateur, setCoordinateur] = useState([]);
    const [ajoutGroups, setAjoutGroups] = useState(false);
    const [ajoutClient, setAjoutClient] = useState(false);
    const [ajoutPrestataire, setAjoutPrestataire] = useState(false);
    const [ajoutHabilitation, setAjoutHabilitation] = useState(false);
    const [nivSousActivite, setNivSousActivite] = useState(0);
    const [isOpenDeleteGroup, setIsOpenDeleteGroup] = useState(false);
    const [isOpenUpdateGroup, setIsOpenUpdateGroup] = useState(false);
    const [groupeActForAdd, setGroupeActForAdd] = useState([]);
    const [isRequiered, setIsRequiered] = useState(false);
    const [ajoutActivite, setAjoutActivite] = useState(false);

    const fetchGroupeActivite = async () => {
        try {
            let response = await groupeActiviteAPI.fetchAll();
            setGroupeActForAdd(response.data)
            return response.data;
        } catch (e) {
            toast.error("Une erreur est survenue");
            console.log(e);
        }
    };
    const fetchClients = async () => {
        try {
            let response = await ClientAPI.fetchAll();
            return response.data;
        } catch (e) {
            toast.error("Une erreur est survenue");
            console.log(e);
        }
    }

    const fetchHabilitations = async () => {
        try {
            let response = await HabilitationAPI.getAll();
            return response.data;
        } catch (e) {
            toast.error("Une erreur est survenue");
            console.log(e);
        }
    }

    const fetchActiviteByGroup = async () => {
        try {
            const response = await ActiviteAPI.fetchAllByGroupeActiviteAndParentIsNull(activite.groupeActivite.id);
            return response.data;
        } catch (e) {
            console.log(e)
        }
    }

    // const fetchPrestataire = async () => {
    //     try {
    //         let response = await PrestataireAPI.fetchAll();
    //         return response.data;
    //     } catch (e) {
    //         toast.error("Une erreur est survenue");
    //         console.log(e);
    //     }
    // }

    const fetchCoordinateur = async () => {
        try {
            let response = await EmployeAPI.getEmployeByType("Coordinateur");
            return response.data;
        } catch (e) {
            toast.error("Une erreur est survenue");
            console.log(e);
        }
    }

    const setOptionClients = (data) => {
        let options = [];
        data.map((e) => {
            options.push({value: e, label: e.nom})
        })
        options.push({value : null, label: <Button className={"col-12 btn-alliance"} variant={"outlined"} onClick={()=>{setAjoutClient(!ajoutClient)}}>Ajouter un client</Button>})
        setClients(options)
    }
    const setOptionHabilitation = (data) => {
        let options = [];
        data.map((e) => {
            options.push({value: e, label: e.libelle})
        });
        options.push({value : null, label: <Button className={"col-12 btn-alliance"} variant={"outlined"} onClick={()=>{setAjoutHabilitation(!ajoutHabilitation)}}>Ajouter une Habilitation</Button>})
        setHabilitations(options)
    }
    const setGroupeOptionActivite = (data) => {
        let options = [];
        data.map((e) => {
            options.push({value: e, label:<div className={"d-flex justify-content-between"}> {e.libelle}  <div className="d-flex ">
                        <IconButton onClick={() => updateActivite(e)}>
                            <Edit color="warning"/>
                        </IconButton>
                        <IconButton onClick={() => deleteActivite(e)}>
                            <Delete color={"error"}/>
                        </IconButton>
                </div></div>})
        });
        options.push({value : null, label: <Button className={"col-12 btn-alliance"} variant={"outlined"} onClick={()=>{setAjoutGroups(!ajoutGroups)}}>Ajouter un groupe d'activite</Button>})
        setGroupeActivites(options)
    }
    const setOptionActivite = (data) => {
        let options = [];
        data.map((e) => {
            options.push({value: e, label: e.libelle})
        });
        options.push({value : null, label: <Button className={"col-12 btn-alliance"} variant={"outlined"} onClick={()=>{setAjoutActivite(!ajoutActivite)}}>Ajouter une Activité</Button>})

        setActivites(options)
    }
    const setOptionActivite2 = (data) => {
        if (data !=undefined){
            let options = [];
            data.enfants.map((e) => {
                options.push({value: e, label: e.libelle})
            });
            options.push({value : null, label: <Button className={"col-12 btn-alliance"} variant={"outlined"} onClick={()=>{setAjoutActivite(!ajoutActivite)}}>Ajouter une Activité</Button>})
            setActivites2(options)
        }
    }

    const setOptionActivite3 = async (data) => {
        if (data != undefined && data != null) {
            data = data.id
            try {
                let response = await activiteAPI.fetchChildren(data);
                let options = [];
                response.data.map((sousA) => {
                    options.push({value: sousA, label: sousA.libelle})
                })
                options.push({value : null, label: <Button className={"col-12 btn-alliance"} variant={"outlined"} onClick={()=>{setAjoutActivite(!ajoutActivite)}}>Ajouter une Activité</Button>})
                setActivites3(options)
            } catch (e) {
                console.log(e)
            }
        }
    }

    const setOptionActivite4 = async (data) => {
        if (data != undefined && data != null) {
            data = data.id
            try {
                let response = await activiteAPI.fetchChildren(data);
                let options = [];
                response.data.map((sousA) => {
                    options.push({value: sousA, label: sousA.libelle})
                })
                options.push({value : null, label: <Button className={"col-12 btn-alliance"} variant={"outlined"} onClick={()=>{setAjoutActivite(!ajoutActivite)}}>Ajouter une Activité</Button>})
                setActivites4(options)
            } catch (e) {
                console.log(e)
            }
        }
    }

    const setOptionCoordinateur = (data) => {
        let options = [];
        data.map((e) => {
            options.push({value: e, label: e.nom})
        });
        setCoordinateur(options)
    }

    const deleteActivite = (e) => {
        setGroupeActivite(e)
        setIsOpenDeleteGroup(true)
    }

    const updateActivite = (e) => {
        setGroupeActivite(e)
        setIsOpenUpdateGroup(true)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {

            let act;
            if (activite.parent4 != undefined){
                act = activite.parent4
                faite.activite = activite.parent4;
            }else if (activite.parent3 != undefined){
                act = activite.parent3
                faite.activite = activite.parent3;
            }else if (activite.parent2 != undefined){
                act = activite.parent2
                faite.activite = activite.parent2;
            }else if(activite.parent1 != undefined){
                act = activite.parent1
                faite.activite = activite.parent1;
            }
            act.habilitations = [];
            habilitationAct.map(h => {
                act.habilitations.push(h.value);
            })
            act.coordinateur = activite.coordinateur;
            act.localisation = activite.localisation;
            act.codeCouleur = activite.codeCouleur;
            act.couleurText = activite.couleurText;
            console.log(activite)
            console.log(act)
            toast.success("Votre activité à bien été ajouté")
            await activiteAPI.update(act);
            FaiteAPI.create(faite).then(async () => {
                setIsOpen(false);
                window.location.reload();
                // await fetchActivite
                return res.data;
            });

        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchClients().then(res => setOptionClients(res))
        fetchHabilitations().then(res => setOptionHabilitation(res))
        fetchGroupeActivite().then(res => setGroupeOptionActivite(res))
        // fetchPrestataire().then(res => setOptionPrestataire(res))
        fetchCoordinateur().then(res => setOptionCoordinateur(res))
    }, []);

    useEffect(() => {
        fetchGroupeActivite().then(res => {setGroupeOptionActivite(res)})
        setActivite({...activite, groupeActivite : null})
    }, [ajoutGroups, isOpenUpdateGroup, isOpenDeleteGroup]);

    useEffect(() => {
        fetchClients().then(res => setOptionClients(res))
    }, [ajoutClient]);

    // useEffect(() => {
    //     fetchPrestataire().then(res => setOptionPrestataire(res))
    // }, [ajoutPrestataire]);

    useEffect(() => {
        fetchHabilitations().then(res => setOptionHabilitation(res))
        setHabilitationAct([])
    }, [ajoutHabilitation]);

    useEffect(() => {
        if(activite.groupeActivite != undefined){
            fetchActiviteByGroup().then(res => setOptionActivite(res))
        }
    }, [activite.groupeActivite]);

    useEffect(() => {
        if (activite.parent1 != undefined){
            setOptionActivite2(activite.parent1)
            setIsRequiered(true);
        }
    }, [activite.parent1]);

    useEffect(() => {
        setOptionActivite3(activite.parent2)
    }, [activite.parent2]);

    useEffect(() => {
        setOptionActivite4(activite.parent3)
    }, [activite.parent3]);



    return <>
        <Modal open={isOpen} onClose={() => {
            setIsOpen(!isOpen);
        }}
               aria-labelledby={"modal-modal-title"}
               aria-describedby={"modal-modal-description"}
        >
            <Box sx={modalStyle}>
                <Typography variant="h5" component="h2" className="d-flex justify-content-center p-2 border-bottom">
                    Ajouter une Activité
                </Typography>
                <form method={"POST"} onSubmit={handleSubmit}>
                <div className="form-modal flex flex-column gap-2">
                    {/* Groupe d'activité */}
                    <SelectInput
                        classNameLabel={"text-end mr-2 pr-5"}
                        required={true}
                        label={"Groupe Activité"}
                        placeholder={"Groupe Activité"}
                        flex={"row"}
                        options={groupeActivites}
                        name={"groupeActivite"}
                        objet={activite}
                        setObjet={setActivite}
                        value={activite.groupeActivite && {
                            value: activite.groupeActivite.id,
                            label: activite.groupeActivite.libelle
                        }}
                    />

                    {/* Parent */}
                    <SelectInput
                        classNameLabel={"text-end mr-2 pr-5"}
                        required={false}
                        name={"parent1"}
                        flex={"row"}
                        placeholder={"Activité"}
                        options={activites}
                        value={activite.parent1 && {value: activite.parent1, label: activite.parent1.libelle}}
                        objet={activite}
                        setObjet={setActivite}
                        label={"Activité"}
                    />
                    {activite.parent1 &&
                    <SelectInput
                        classNameLabel={"text-end mr-2 pr-5"}
                        required={false}
                        name={"parent2"}
                        flex={"row"}
                        placeholder={"Sous activité niv 1"}
                        options={activites2}
                        value={activite.parent2 && {value: activite.parent2, label: activite.parent2.libelle}}
                        objet={activite}
                        setObjet={setActivite}
                        label={"Sous activité niv 1"}
                    />
                    }
                    {activite.parent2 &&
                    <SelectInput
                        classNameLabel={"text-end mr-2 pr-5"}
                        required={false}
                        name={"parent3"}
                        flex={"row"}
                        placeholder={"Sous activité niv 2"}
                        options={activites3}
                        value={activite.parent3 && {value: activite.parent3, label: activite.parent3.libelle}}
                        objet={activite}
                        setObjet={setActivite}
                        label={"Sous activité niv 2"}
                    />
                    }
                    {activite.parent3 &&
                    <SelectInput
                        classNameLabel={"text-end mr-2 pr-5"}
                        required={false}
                        name={"parent4"}
                        flex={"row"}
                        placeholder={"Sous activité niv 3"}
                        options={activites4}
                        value={activite.parent4 && {value: activite.parent4, label: activite.parent4.libelle}}
                        objet={activite}
                        setObjet={setActivite}
                        label={"Sous activité niv 3"}
                    />
                    }
                    {/* Client */}
                    <SelectInput
                        classNameLabel={"text-end mr-2 pr-5"}
                        required={true}
                        name={"client"}
                        flex={"row"}
                        placeholder={"Client"}
                        options={clients}
                        value={faite.client && {value: faite.client, label: faite.client.nom}}
                        objet={faite}
                        setObjet={setFaite}
                        label={"Client"}
                    />
                    {/* Nom de l'activité */}
                    {/*<Input*/}
                    {/*    classNameLabel={"text-end mr-2 pr-5"}*/}
                    {/*    required={true}*/}
                    {/*    type={"text"}*/}
                    {/*    name={'libelle'}*/}
                    {/*    label="Nom de l'activité"*/}
                    {/*    value={activite.libelle}*/}
                    {/*    objet={activite}*/}
                    {/*    setObjet={setActivite}*/}
                    {/*    placeholder={"Nom de l'activité"}*/}
                    {/*/>*/}
                    {/*/!* Prestataire *!/*/}
                    {/*<SelectInput*/}
                    {/*    classNameLabel={"text-end mr-2 pr-5"}*/}
                    {/*    label="Prestataire"*/}
                    {/*    name={"prestataire"}*/}
                    {/*    options={prestataires}*/}
                    {/*    value={activite.prestataire && {value: activite.prestataire, label: activite.prestataire.nom}}*/}
                    {/*    objet={activite}*/}
                    {/*    setObjet={setActivite}*/}
                    {/*    required={isRequiered}*/}
                    {/*/>*/}

                    {/* Coordinateur */}
                    <SelectInput
                        classNameLabel={"text-end mr-2 pr-5"}
                        label="Coordinateur"
                        name={"coordinateur"}
                        options={coordinateur}
                        value={activite.coordinateur && {
                            value: activite.coordinateur,
                            label: activite.coordinateur.nom
                        }}
                        objet={activite}
                        setObjet={setActivite}
                        required={isRequiered}
                    />
                    {/* Localisation */}
                    <SelectInput
                        classNameLabel={"text-end mr-2 pr-5"}
                        label="localisation"
                        name={"localisation"}
                        value={activite.localisation && {value: activite.localisation, label: activite.localisation}}
                        options={[
                            {value: "EXTERNE", label: "Externe"},
                            {value: "INTERNE", label: "Interne"}
                        ]}
                        objet={activite}
                        setObjet={setActivite}
                        required={isRequiered}
                    />

                    {/* Nombre de salariés théoriques */}
                    <Input
                        classNameLabel={"text-end mr-2 pr-5"}
                        type={"number"}
                        name={'nombreSalarie'}
                        label="Nombre de salariés théoriques"
                        value={faite.nombreSalarie}
                        objet={faite}
                        setObjet={setFaite}
                        placeholder={"Nombre de salariés théoriques"}
                        required={isRequiered}
                    />
                    {/* Habilitations */}
                    <SelectInput
                        classNameLabel={"text-end mr-2 pr-5"}
                        label="Habilitations"
                        options={habilitations}
                        value={habilitationAct}
                        setObjet={setHabilitationAct}
                        objet={habilitationAct}
                        isMulti={true}
                        required={isRequiered}
                    />
                    <Input
                        classNameLabel={"text-end mr-2 pr-5"}
                        name={"codeCouleur"}
                        type={"color"}
                        label={"Couleur Fond"}
                        placeholder={"Couleur de l'activité"}
                        value={activite.codeCouleur}
                        setObjet={setActivite}
                        objet={activite}
                        required={isRequiered}
                    />
                    <Input
                        classNameLabel={"text-end mr-2 pr-5"}
                        name={"couleurText"}
                        type={"color"}
                        label={"Couleur Text"}
                        placeholder={"Couleur du texte l'activité"}
                        value={activite.couleurText}
                        setObjet={setActivite}
                        objet={activite}
                        required={isRequiered}
                    />
                    <div className={"offset-4 col-8 ml-2 pl-4"} style={{background : activite.codeCouleur, color:activite.couleurText, borderRadius:'50px'}}>Preview</div>
                </div>
                <div className="d-flex justify-content-end p-2">
                    <Button className={"btn btn-alliance"} variant={"outlined"} type={"submit"}>
                        Enregistrer
                    </Button>
                </div>
            </form>
        </Box>
        </Modal>
        <ModalGroups isOpen={ajoutGroups} setIsOpen={setAjoutGroups} groupe={groupeActForAdd} setActivite={setActivite}/>
        <ModalClient setIsOpen={setAjoutClient} isOpen={ajoutClient}/>
        <ModalPrestataire setIsOpen={setAjoutPrestataire} isOpen={ajoutPrestataire} />
        <ModalHabilitation setIsOpen={setAjoutHabilitation} isOpen={ajoutHabilitation} />
        <ModalDeleteGroup  isOpen={isOpenDeleteGroup} setIsOpen={setIsOpenDeleteGroup} groupeActivite={groupeActivite} fetch={fetchGroupeActivite} setGroupeActivite={setGroupeOptionActivite}/>
        <ModalUpdateGroup  isOpen={isOpenUpdateGroup} setIsOpen={setIsOpenUpdateGroup} groupeActivite={groupeActivite} fetch={fetchGroupeActivite} setGroupeActivite={setGroupeOptionActivite}/>
        <ModalAjoutActiviteParent isOpen={ajoutActivite} setIsOpen={setAjoutActivite} activite={activite} setActivite={setActivite}/>
    </>;
};

export default ModalAjoutActivite;


