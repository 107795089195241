import React from 'react';

const PlanningAtelier = () => {
    return (
        <div>
            Planning Groupe
        </div>
    );
};

export default PlanningAtelier;