import React, {useEffect, useState} from 'react';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {modalStyle} from "../../../Styles/ModalStyle";
import Typography from "@mui/material/Typography";
import {EmployeAPI} from "../../../Services/EmployeAPI";
import moment from "moment/moment";
import {IconButton, Tooltip} from "@mui/material";
import {Visibility,PriorityHigh,Add} from "@mui/icons-material";
import DotMenuEmployeDataTable from "../../Menus/DotMenuEmployeDataTable";
import GenericDataTable from "../../DataTables/GenericDataTable";
import TextField from "@mui/material/TextField";
import Badge from "react-bootstrap/Badge";
import Color from "../../../Styles";
import SvgIcon from "@mui/material/SvgIcon";
const
    ModalEmployePlanificationEdit = ({isOpen, setIsOpen,setIsOpenModalActivite, activite, heuresDebut, heuresFin, setActivite, setErrorMessages}) => {
    const [dataUtilisateurs, setDataUtilisateurs] = useState([])
    const [filteredData, setFilteredData] = useState([]);
    const [filteredData2, setFilteredData2] = useState([]);
    const [searchValue, setSearchValue] = useState({});
    const [rowSelected, setRowSelected] = useState(null)

    const [columnUtilisateurs, setColumnUtilisateurs] = useState([
        {
            id: "nom",
            name: <span className="top-column">Employe</span>,
            selector: row => row.em_nom + " " + row.em_prenom,
            sortable: true,

        },
        {
            name: <span className="top-column">RDV CISP</span>,
            selector: row => row.cisp_date_debut ? moment.unix(row.cisp_date_debut.toString().substring(0, 10)).format("HH:mm") + ' ' + moment.unix(row.cisp_date_fin.toString().substring(0, 10)).format("HH:mm") : "",
            sortable: true
        },
        // {
        //     name: <span className="top-column">Etat au {moment().format("DD/MM/YYYY")}</span>,
        //     selector: row => row.em_isActif ? "Actif" : "Inactif",
        //     sortable: true
        // },
        {
            name: <span className="top-column">Disponibilité</span>,
            selector: row => (row.dejaplanifie != "false" || row.isindisponible != "false" || row.donthavehabilitation != "false"|| row.workplanning != "false") ? "Non Disponible" : "Disponible",
            sortable: true
        },
        {
            name: <span className="top-column">Actions</span>,
            selector: row => (
                <div className="d-flex justify-content-evenly">
                    {checkIfAdded(row)}

                    {(row.dejaplanifie != "false" || row.isindisponible != "false" || row.donthavehabilitation != "false"|| row.workplanning != "false") && <>
                        <Tooltip  style={{maxWidth : "1000px", maxHeight:"1000px"}} title={<>
                            {row.dejaplanifie != "false" && <h4><Badge key={row.dejaplanifie} bg={"danger"} pill>{row.dejaplanifie}</Badge></h4>}
                            {row.isindisponible != "false" && <h4><Badge key={row.isindisponible} bg={"danger"} pill>{row.isindisponible}</Badge></h4>}
                            {row.donthavehabilitation != "false" && <h4><Badge key={row.donthavehabilitation} bg={"danger"} pill>{row.donthavehabilitation}</Badge></h4>}
                            {row.workplanning != "false" && <h4><Badge key={row.workplanning} bg={"danger"} pill>{row.workplanning}</Badge></h4>}
                        </>} placement={"right"}>
                            <button onClick={() => {}}>
                                <SvgIcon sx={{color: Color.white}}>
                                    <PriorityHigh />
                                </SvgIcon>
                            </button>
                        </Tooltip>
                        <div className={"d-flex flex-column"}>
                            {row.dejaplanifie != "false" && <p>Déjà planifié</p>}
                            {row.isindisponible != "false" && <p>Indisponible</p>}
                            {row.donthavehabilitation != "false" && <p>Habilitation manquante</p>}
                            {row.workplanning != "false" && <p>Ne travail pas</p>}
                        </div>
                    </>}
                    {/*<DotMenuEmployeDataTable idUser={row.id} user={row} fetchUtilisateur={fetchUtilisateurs}/>*/}
                </div>
            )
        }
    ]);

    const checkIfAdded = (row) => {
        if((Array.isArray(activite.employes) && !activite.employes.includes(row)) || !Array.isArray(activite.employes)){
            return <Tooltip title={"Plannifier l'employé"} placement={"right"}>
                <button onClick={() => {setRowSelected(row)}}>
                    <SvgIcon sx={{color: Color.white}}>
                        <Add />
                    </SvgIcon>
                </button>
            </Tooltip>
        }
    }

    const addEmploye = () => {
        if (rowSelected != null){
            let updatedActivite = {...activite};
            if (Array.isArray(updatedActivite.employes)){
                if (updatedActivite.employes.filter((emp) => emp.em_numerosecuritesocial == rowSelected.em_numerosecuritesocial).length > 0){
                    updatedActivite.employes = updatedActivite.employes.filter(emp => emp.em_numerosecuritesocial != rowSelected.em_numerosecuritesocial);
                }else{
                    updatedActivite.employes.push(rowSelected);
                }
            }else {
                updatedActivite.employes = [rowSelected];
            }
            setFilteredData(dataUtilisateurs.filter(objet1 => !updatedActivite.employes.some(objet2 => objet2.em_numerosecuritesocial === objet1.em_numerosecuritesocial)));
            setFilteredData2(dataUtilisateurs.filter(objet1 => !updatedActivite.employes.some(objet2 => objet2.em_numerosecuritesocial === objet1.em_numerosecuritesocial)));
            updatedActivite.nombreSalarie = activite.employes.length;
            setActivite(updatedActivite);
            setErrorMessages(activite.employes);
            setSearchValue({value : ""});
        }
    }
    const handleCloseModal = () => {
        setIsOpen(false);
        setIsOpenModalActivite(true)
    }

    const fetchEmployeByName = async () => {
        setFilteredData(filteredData2.filter((uti) => {
            const nom = uti.em_nom.toLowerCase();
            const prenom = uti.em_prenom.toLowerCase();
            const searchTermLower = searchValue.value.toLowerCase();
            return nom.includes(searchTermLower) || prenom.includes(searchTermLower);
        }))
    }
    const fetchEmploye= async () => {
        let activiteId = [];
        let dateDebut;
        let dateFin;

        if (activite.activites != undefined){
            if(activite.activites.length > 0){
                activite.activites.map((a) => {
                    activiteId.push(a.value.id)
                })
            }else{
                activiteId.push(activite.activiteParent.value.id)
            }

        }
        if (activite.dateDebut != undefined && activite.dateFin != undefined && heuresDebut != null && heuresFin != null) {
            dateDebut = moment(moment(activite.dateDebut).format("YYYY-MM-DD") + "T" + moment(heuresDebut["_d"]).locale("fr").format("HH:mm:ss")).locale('fr').format("YYYY-MM-DD HH:mm:ss")
            dateFin = moment(moment(activite.dateFin).format("YYYY-MM-DD") + "T" + moment(heuresFin["_d"]).locale("fr").format("HH:mm:ss")).locale('fr').format("YYYY-MM-DD HH:mm:ss")

            try {
                let response = await EmployeAPI.getEmployeForPlannificationEdit(dateDebut, dateFin, activiteId, activite.id);
                if (Array.isArray(activite.employes)){
                    let tableau1 = response.data.filter(objet1 => !activite.employes.some(objet2 => objet2.em_numerosecuritesocial === objet1.em_numerosecuritesocial));
                    setFilteredData(tableau1.filter(row => row.dejaplanifie == "false" && row.isindisponible == "false" && row.workplanning == "false"));
                    setFilteredData2(tableau1.filter(row => row.dejaplanifie == "false" && row.isindisponible == "false" && row.workplanning == "false"));
                    setDataUtilisateurs(tableau1.filter(row => row.dejaplanifie == "false" && row.isindisponible == "false" && row.workplanning == "false"));
                }else{
                    setFilteredData(response.data.filter(row => row.dejaplanifie == "false" && row.isindisponible == "false" && row.workplanning == "false"))
                    setDataUtilisateurs(response.data.filter(row => row.dejaplanifie == "false" && row.isindisponible == "false" && row.workplanning == "false"));
                    setFilteredData2(response.data.filter(row => row.dejaplanifie == "false" && row.isindisponible == "false" && row.workplanning == "false"))
                }
            } catch (e) {
                console.log(e)
            }
        }
    }
    const conditionalRowStyles = [
        {
            when: row => row.dejaplanifie == "false" || row.isindisponible == "false" || row.donthavehabilitation == "false"|| row.workplanning == "false" ,
            style: {
                backgroundColor: '#198754',
                color: 'white',
            },
        },
        {
            when: row => row.dejaplanifie != "false" || row.isindisponible != "false" || row.donthavehabilitation != "false"|| row.workplanning != "false" ,
            style: {
                backgroundColor: '#dc3545',
                color: 'white',
            },
        },
    ];

    useEffect(() => {
        if (isOpen == true){
            fetchEmploye();
        }
    }, [isOpen]);

    useEffect(() => {
        fetchEmployeByName()
    }, [searchValue]);

    useEffect(() => {
        addEmploye();
    }, [rowSelected]);

    // useEffect(() => {
    //     if (Array.isArray(activite.employes)){
    //         let tableau1 = filteredData.filter(objet1 => !activite.employes.some(objet2 => objet2.em_numerosecuritesocial === objet1.em_numerosecuritesocial));
    //         setFilteredData(tableau1)
    //     }
    // }, [activite.employes]);

    return <>
        <Modal
            open={isOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h5" component="h2"
                            className={"d-flex justify-content-center p-2 border-bottom"}>
                    Choisir les employé à planifier
                </Typography>

                <div className={"form-modal flex flex-column gap-2"}>
                    <div className={"d-flex justify-content-center"}>
                        <h5>{activite.nombreSalarie} employé(s) planifié(s)</h5>
                    </div>
                    <TextField
                        label={"Rechercher un Employé : "}
                        sx={{'& .MuiOutlinedInput-root.Mui-focused fieldset': {borderColor: 'var(--orange-color)'}}}
                        variant={"outlined"}
                        value={searchValue.value}
                        name={"value"}
                        onChange={(e) => {
                            const {name, value} = e.currentTarget;
                            setSearchValue({...searchValue, [name]: value})
                        }}
                    />
                    <GenericDataTable
                        defaultSortFieldId={"em_nom"}
                        defaultSortAsc={true}
                        data={filteredData}
                        columnItem={columnUtilisateurs}
                        noDataText={"Pas d'employé à selectioner "} theme={"solarized"}
                        conditionalRowStyles={conditionalRowStyles}
                        paginationRowsPerPageOptions={[150, 300]}
                        paginationPerPage={150}
                        handleRowClicked={(e) => {
                            setRowSelected(e)
                        }}
                    />
                </div>
                <div id="modal-modal-footer" className={"d-flex-column p-2 border-top justify-content-end"}>
                <div className={"d-flex justify-content-end gap-3 mt-3"}>
                        <button className={"btn btn-alliance"} onClick={() => {
                            handleCloseModal()
                        }}>
                            Valider
                        </button>
                    </div>
                </div>
            </Box>
        </Modal>
    </>;
};

export default ModalEmployePlanificationEdit;