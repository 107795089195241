import axios from "axios";
import {GROUPE_ACTIVITE_URL} from "../Config/url";

export class groupeActiviteAPI {
    static async fetchAll() {
        return await axios.get(GROUPE_ACTIVITE_URL);
    }

    static async fetchGroupById(id) {
        return await axios.get(GROUPE_ACTIVITE_URL + "/" + id + "/groups");
    }

    static async updateGroup(id, group) {
        await axios.put(GROUPE_ACTIVITE_URL + "/" + id, group);
    }

    static async createGroup(group) {
        await axios.post(GROUPE_ACTIVITE_URL, group);
    }

    static async delete(id) {
        await axios.delete(GROUPE_ACTIVITE_URL + "/" + id)
    }
}

function fetchAll() {
    return axios.get(GROUPE_ACTIVITE_URL);
}

export default {
    fetchAll,
}