// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.h-screen {*/
/*    height: 100vh;*/
/*}*/

.oui {
    background: var(--background-main);
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
    background-color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/Styles/planning.css"],"names":[],"mappings":"AAAA,cAAc;AACd,qBAAqB;AACrB,IAAI;;AAEJ;IACI,kCAAkC;AACtC;;AAEA;IACI,kCAAkC;AACtC","sourcesContent":["/*.h-screen {*/\r\n/*    height: 100vh;*/\r\n/*}*/\r\n\r\n.oui {\r\n    background: var(--background-main);\r\n}\r\n\r\n.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {\r\n    background-color: white !important;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
