// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sideBarLinkCollapse {
    display: flex;
    color: var(--icon-menu-color);
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 60px;
    text-decoration: none;
    font-size: 18px;
}

.sideBarLinkCollapse:hover {
    background: var(--grey-color);
    border-left: 4px solid var(--color-side-bar-selected);
    cursor: pointer;
}

.sideBarLinkCollapse:hover > .collapsePerso, .sideBarLinkCollapse:active > .collapsePerso {
    display: flex;
}


.collapsePerso {
    display: none;
    flex-direction: column;
}

.sideBarLabelCollapse {
    font-size: 13px;
    margin-left: 1em;
}


.dropDownLinkCollapse {
    width: 150px;
    margin-left: 21px;
    background: #414757;
    padding: 10px;
    height: auto;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #f5f5f5;
}

.dropDownLinkCollapse:hover {
    background: var(--color-side-bar-selected);
}
`, "",{"version":3,"sources":["webpack://./src/Components/Menus/SubMenuCollapse/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,6BAA6B;IAC7B,qDAAqD;IACrD,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;;AAGA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;;AAGA;IACI,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,0CAA0C;AAC9C","sourcesContent":[".sideBarLinkCollapse {\r\n    display: flex;\r\n    color: var(--icon-menu-color);\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    padding: 20px;\r\n    list-style: none;\r\n    height: 60px;\r\n    text-decoration: none;\r\n    font-size: 18px;\r\n}\r\n\r\n.sideBarLinkCollapse:hover {\r\n    background: var(--grey-color);\r\n    border-left: 4px solid var(--color-side-bar-selected);\r\n    cursor: pointer;\r\n}\r\n\r\n.sideBarLinkCollapse:hover > .collapsePerso, .sideBarLinkCollapse:active > .collapsePerso {\r\n    display: flex;\r\n}\r\n\r\n\r\n.collapsePerso {\r\n    display: none;\r\n    flex-direction: column;\r\n}\r\n\r\n.sideBarLabelCollapse {\r\n    font-size: 13px;\r\n    margin-left: 1em;\r\n}\r\n\r\n\r\n.dropDownLinkCollapse {\r\n    width: 150px;\r\n    margin-left: 21px;\r\n    background: #414757;\r\n    padding: 10px;\r\n    height: auto;\r\n    display: flex;\r\n    align-items: center;\r\n    text-decoration: none;\r\n    color: #f5f5f5;\r\n}\r\n\r\n.dropDownLinkCollapse:hover {\r\n    background: var(--color-side-bar-selected);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
