import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import '../../../Styles/ModalForm.css';
import Button from "react-bootstrap/Button"

import {CircularProgress} from "@mui/material";
import {HabilitationAPI} from "../../../Services/HabilitationAPI";
import {modalStyle} from "../../../Styles/ModalStyle";
import SelectInput from "../../FormsComponents/SelectInput";
import ModalHabilitation from "./index";

const HabilitationUser = ({isOpen, setIsOpen, habilitationSelected, setHabilitationSelected, user, handleAdd}) => {
    const [habilitation, setHabilitation] = useState([]);
    const [loading, setLoading] = useState(false);
    const [optionHabilitation, setOptionHabilitation] = useState([]);
    const [ajoutHabilitation, setAjoutHabilitation] = useState(false);
    const [ajout, setAjout] = useState(false);

    const handleCloseModal = () => {
        setIsOpen(!isOpen);
        setHabilitation({});
    }

    const fetchHabilitation = async () => {
        let data = await HabilitationAPI.getAll();
        setHabilitation(data.data);
        setLoading(false);
        return data.data;
    }

    const setOptionsSelectHabilitation = (hab) => {
        let options = [];
        hab.map((h, index) => {
            options.push({value: h, label: h.libelle});
        });
        options.push({value : null, label: <Button className={"col-12 btn-alliance"} variant={"outlined"} onClick={()=>{setAjoutHabilitation(!ajoutHabilitation); setAjout(true)}}>Ajouter une Habilitation</Button>})
        if(user.habilitation != undefined){
            let filteredArray = options.filter(function (array_el) {
                return user.habilitations.filter(function (anotherOne_el) {
                    if (array_el.value != null && anotherOne_el.value != null){

                    return anotherOne_el.id == array_el.value.id;
                    }
                }).length == 0
            });
            setOptionHabilitation(filteredArray);
            return filteredArray;
        }else{
            setOptionHabilitation(options);
            return options;
        }
    };

    useEffect(() => {
        fetchHabilitation().then(hab => setOptionsSelectHabilitation(hab));
    }, [])

    useEffect(() => {
        if (ajoutHabilitation == false) {
            let res = [];
            fetchHabilitation().then((hab) => {
                res = setOptionsSelectHabilitation(hab)
                if (res.length > 0 && ajout == true) {
                    setHabilitationSelected({hab: res[res.length - 2].value})
                    setAjout(false)
                }
            });
        }
    }, [ajoutHabilitation]);

    return (<>
            <Modal open={isOpen}
               onClose={handleCloseModal}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description">
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h5" component="h2"
                            className={"d-flex justify-content-center p-2 border-bottom"}>
                    Ajouter une Habilitation
                </Typography>
                <div className={"form-modal flex flex-column gap-2"}>
                    {loading ? (
                        <div className={"text-center"}>
                            <CircularProgress color={"primary"}/>
                        </div>
                    ) : (
                        <>
                            <SelectInput flex={"row"} placeholder={"Habilitations"} objet={habilitationSelected}
                                         setObjet={setHabilitationSelected}
                                         name={"hab"}
                                         value={habilitationSelected.hab && {
                                             value: habilitationSelected.hab,
                                             label: habilitationSelected.hab.libelle
                                         }}
                                         options={optionHabilitation} label={"Habilitations"}/>
                            <div className="d-flex justify-content-end">
                                <div className="d-flex justify-content-between col-3 me-2 mt-2">
                                    <Button variant={"contained"} color={"error"} className={"btn m-2"}
                                            onClick={handleCloseModal} type="button">
                                        Annuler
                                    </Button>
                                    <Button className={"btn btn-add-activity m-2"} variant={"outlined"}
                                            color={"primary"} type="submit" onClick={handleAdd}>
                                        Enregistrer
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Box>
        </Modal>
        <ModalHabilitation setIsOpen={setAjoutHabilitation} isOpen={ajoutHabilitation} />
    </>
);
};

export default HabilitationUser;
