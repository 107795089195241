import axios from "axios";
import {EMPLOYE_API} from "../Config/url";

export class EmployeAPI {
    static async getAllEmploye() {
        return await axios.get(EMPLOYE_API).then(response => response.data);
    }

    static async modifyActiveEmploye(id, active) {
        await axios.patch(EMPLOYE_API + "/" + id, {data: {isActif: active}});
    }

    static async getEmployeById(id) {
        return await axios.get(EMPLOYE_API + "/" + id + "/employe").then(response => response.data);
    }

    static async getEmployeByType(type) {
        return await axios.get(EMPLOYE_API + "/" + type);
    }

    static async createEmploye(user) {
        await axios.post(EMPLOYE_API, user);
    }

    static async updateEmploye(id, user) {
        await axios.put(EMPLOYE_API + "/" + id, user)
    }

    static async getErrorMessage(dateDebut, dateFin, idActivite,employeID) {
        return await axios.post(EMPLOYE_API + "/employe_error_message", {
            dateDebut: dateDebut,
            dateFin: dateFin,
            activiteID : idActivite,
            employeID : employeID
        })
    }
    static async getEmployeForPlannification(dateDebut, dateFin, idActivite) {
        return await axios.post(EMPLOYE_API + "/employe_plannification", {
            dateDebut: dateDebut,
            dateFin: dateFin,
            activites : idActivite
        })
    }

    static async getErrorMessageForEdit(dateDebut, dateFin, idActivite,planningActiviteID) {
        return await axios.post(EMPLOYE_API + "/employe_error_message/edit", {
            dateDebut: dateDebut,
            dateFin: dateFin,
            activites : idActivite,
            planningActiviteID : planningActiviteID
        })
    }

    static async getEmployesHeuresPlanifie(dateDebut, dateFin) {
        return await axios.post(EMPLOYE_API + "/employe_heures_planifie", {
            dateDebut: dateDebut,
            dateFin: dateFin
        })
    }
    static async getEmployesNonPlanifier(dateDebut, dateFin) {
        return await axios.post(EMPLOYE_API + "/employe_non_planifie", {
            dateDebut: dateDebut,
            dateFin: dateFin,
        })
    }

    static async delete(id) {
        await axios.delete(EMPLOYE_API + "/" + id);
    }

    static getEtat(id) {
        return axios.post(EMPLOYE_API + "/etat", id);
    }

    static async getEmployeForPlannificationEdit(dateDebut, dateFin, activites,planningActiviteID) {
        return await axios.post(EMPLOYE_API + "/employe_plannification_edit", {
            dateDebut: dateDebut,
            dateFin: dateFin,
            activites : activites,
            planningActiviteID : planningActiviteID
        })
    }

    static async getEmployesByIDS(employeIDS){
        return axios.post(EMPLOYE_API +"/getEmployes", employeIDS).then(response => response.data);
    }
}