import React, {useContext, useState} from 'react';
import {Cached, Close} from '@mui/icons-material';
import './index.css';
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from '@mui/material/Tooltip';
import SelectInput from "../../FormsComponents/SelectInput";
import DatePicker from "../../DatePicker";
import AuthContext from "../../../Context/AuthContext";
import moment from "moment";

const Header2Planning = ({
                             isOpen,
                             setIsOpen,
                             groupeActivite,
                             setGroupeActivite,
                             optionsGroupeActivite,
                             activite,
                             setActivite,
                             optionsActivite,
                             client,
                             setClient,
                             optionsClient,
                             prestataire,
                             setPrestataire,
                             optionsPrestataire,
                         }) => {

    const [groupeActiviteRef, setGroupeActiviteRef] = useState(null);
    const [activiteRef, setActiviteRef] = useState(null);
    const [clientRef, setClientRef] = useState(null);
    const [prestataireRef, setPrestataireRef] = useState(null);
    const [pickerKey, setPickerKey] = useState("pickerKey");
    const {day, setDay} = useContext(AuthContext);

    const handleReset = () => {
        setPickerKey(Math.random().toString());
        groupeActiviteRef.clearValue()
        activiteRef.clearValue()
        clientRef.clearValue()
        prestataireRef.clearValue()
        setGroupeActivite({})
        setActivite({})
        setClient({})
        setPrestataire({})
        setDay(moment())
    }

    function refreshPage() {
        window.location.reload(false);
    }

    return <div className={"header2"}>
        <div className={"entete"}>
            <h1 className={"m-2"}>Planning</h1>
            <Tooltip title={"Ajout d'une activité"}>
                <button className={"btn btn-add-activity m-2"} onClick={() => setIsOpen(!isOpen)}>
                    Planifier une activité
                </button>
            </Tooltip>
        </div>
        <div className={"box"}>
            <div className={"d-flex"}>
                <div className={"col-2 mr-1"}>
                    <DatePicker label={"Date"} name={"date"}
                                className={"col-10"}
                                objet={day}
                                setObjet={setDay} value={day}/>
                </div>
                <div className={"col-2 mr-1"}>
                    <SelectInput flex={"column"} placeholder={"Groupe Activité"}
                                 setSelectRef={setGroupeActiviteRef}
                                 objet={groupeActivite}
                                 setObjet={setGroupeActivite} options={optionsGroupeActivite}></SelectInput>
                </div>
                <div className={"col-2 mr-1"}>
                    <SelectInput setSelectRef={setActiviteRef} flex={"column"} placeholder={"Activité"} objet={activite}
                                 setObjet={setActivite}
                                 options={optionsActivite}
                                 disabled={optionsActivite.length > 1 ? false : true}></SelectInput>
                </div>
                <div className={"col-2 mr-1"}>
                    <SelectInput setSelectRef={setClientRef} flex={"column"} name={"client"} placeholder={"Client"}
                                 objet={client}
                                 setObjet={setClient} options={optionsClient}></SelectInput>
                </div>
                <div className={"col-2 mr-1"}>
                    <SelectInput setSelectRef={setPrestataireRef} flex={"column"} name={"prestataire"}
                                 placeholder={"Prestataire"} objet={prestataire}
                                 setObjet={setPrestataire} options={optionsPrestataire}></SelectInput>
                </div>
                <div className={"d-flex col-2 justify-content-end"}>
                    <Tooltip title={"Vider les filtres"}>
                        <button className="btn btn-refresh mr-3" onClick={() => handleReset()}>
                            <SvgIcon sx={{color: "#FFFFFF"}}>
                                <Close/>
                            </SvgIcon>
                        </button>
                    </Tooltip>
                    <Tooltip title={"Rafraichir la page"} onClick={refreshPage}>
                        <button className="btn btn-refresh mr-3">
                            <SvgIcon sx={{color: "#FFFFFF"}}>
                                <Cached/>
                            </SvgIcon>
                        </button>
                    </Tooltip>
                </div>
            </div>
        </div>

    </div>
};

export default Header2Planning;