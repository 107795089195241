// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sideBarLink {
    display: flex;
    color: #e1e9fc;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 60px;
    text-decoration: none;
    font-size: 18px;
}

.sideBarLink:hover {
    background: var(--grey-color);
    border-left: 4px solid var(--color-side-bar-selected);
    cursor: pointer;
}

.sideBarLabel {
    margin-left: 16px;
}

.dropDownLink .sideBarLabel {
    font-size: 15px;
}

.dropDownLink {
    background: #414757;
    height: 60px;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    /*border-left: 5px solid var(--color-side-bar-selected);*/
}

.dropDownLink:hover {
    background: var(--color-side-bar-selected);
}

.active {
    border-left: 4px solid var(--color-side-bar-selected);
}`, "",{"version":3,"sources":["webpack://./src/Components/Menus/SubMenu/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;IACd,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,6BAA6B;IAC7B,qDAAqD;IACrD,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,cAAc;IACd,eAAe;IACf,yDAAyD;AAC7D;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,qDAAqD;AACzD","sourcesContent":[".sideBarLink {\r\n    display: flex;\r\n    color: #e1e9fc;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    padding: 20px;\r\n    list-style: none;\r\n    height: 60px;\r\n    text-decoration: none;\r\n    font-size: 18px;\r\n}\r\n\r\n.sideBarLink:hover {\r\n    background: var(--grey-color);\r\n    border-left: 4px solid var(--color-side-bar-selected);\r\n    cursor: pointer;\r\n}\r\n\r\n.sideBarLabel {\r\n    margin-left: 16px;\r\n}\r\n\r\n.dropDownLink .sideBarLabel {\r\n    font-size: 15px;\r\n}\r\n\r\n.dropDownLink {\r\n    background: #414757;\r\n    height: 60px;\r\n    padding-left: 3rem;\r\n    display: flex;\r\n    align-items: center;\r\n    text-decoration: none;\r\n    color: #f5f5f5;\r\n    font-size: 18px;\r\n    /*border-left: 5px solid var(--color-side-bar-selected);*/\r\n}\r\n\r\n.dropDownLink:hover {\r\n    background: var(--color-side-bar-selected);\r\n}\r\n\r\n.active {\r\n    border-left: 4px solid var(--color-side-bar-selected);\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
