import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SelectInput from "../../FormsComponents/SelectInput";
import Input from "../../FormsComponents/Input";
import Button from "react-bootstrap/Button"
import {toast} from "react-toastify";
import {modalStyle} from "../../../Styles/ModalStyle";
import {groupeActiviteAPI} from "../../../Services/groupeActiviteAPI";
import {ClientAPI} from "../../../Services/clientAPI";
import {HabilitationAPI} from "../../../Services/HabilitationAPI";
import client from "../../../Page/Client";
import PrestataireAPI from "../../../Services/prestataireAPI";
import ActiviteAPI, {activiteAPI} from "../../../Services/activiteAPI";
import {FaiteAPI} from "../../../Services/FaiteAPI";
import {EmployeAPI} from "../../../Services/EmployeAPI";
import ModalGroups from "../ModalGroups";
import ModalClient from "../ModalClient";
import ModalPrestataire from "../ModalPrestataire";
import ModalHabilitation from "../ModalHabilitation";

const ModalEditActivite = ({isOpen, setIsOpen, fetchActivite, activiteC}) => {
    const [activites, setActivites] = useState([]);
    const [activite, setActivite] = useState({});
    const [faite, setFaite] = useState({});
    const [clients, setClients] = useState([]);
    const [groupeActivites, setGroupeActivites] = useState([]);
    const [habilitations, setHabilitations] = useState([]);
    const [habilitationAct, setHabilitationAct] = useState([]);
    const [coordinateur, setCoordinateur] = useState([]);
    const [ajoutGroups, setAjoutGroups] = useState(false);
    const [ajoutClient, setAjoutClient] = useState(false);
    const [ajoutPrestataire, setAjoutPrestataire] = useState(false);
    const [ajoutHabilitation, setAjoutHabilitation] = useState(false);
    const [activiteParent, setActiviteParent] = useState({});

    const fetchActiviteById = async () => {
        try {
            console.log(activite)
            const response = await activiteAPI.getActiviteByID(activiteC)
            setFaite(response.data.faites.filter(fa => fa.activite.id == response.data.id)[0]);
            setActivite(response.data);
            console.log(response.data)
            let habilitations = [];
            response.data.habilitations.map(hab => {
                habilitations.push({value : hab, label : hab.libelle})
            })
            setHabilitationAct(habilitations)
            const res = await activiteAPI.getActiviteByID(response.data.parent.id)
            setActiviteParent(res.data)
        } catch (e) {
            console.log(e)
        }
    }

    const fetchGroupeActivite = async () => {
        try {
            let response = await groupeActiviteAPI.fetchAll();
            return response.data;
        } catch (e) {
            toast.error("Une erreur est survenue");
            console.log(e);
        }
    };
    const fetchClients = async () => {
        try {
            let response = await ClientAPI.fetchAll();
            return response.data;
        } catch (e) {
            toast.error("Une erreur est survenue");
            console.log(e);
        }
    }

    const fetchHabilitations = async () => {
        try {
            let response = await HabilitationAPI.getAll();
            return response.data;
        } catch (e) {
            toast.error("Une erreur est survenue");
            console.log(e);
        }
    }

    const fetchCoordinateur = async () => {
        try {
            let response = await EmployeAPI.getEmployeByType("Coordinateur");
            return response.data;
        } catch (e) {
            toast.error("Une erreur est survenue");
            console.log(e);
        }
    }

    const fetchActiviteByGroup = async () => {
        try {
            const response = await ActiviteAPI.fetchAllByGroupeActivite(activite.groupeActivite.id);
            return response.data;
        } catch (e) {
            console.log(e)
        }
    }

    const setOptionClients = (data) => {
        let options = [];
        data.map((e) => {
            options.push({value: e, label: e.nom})
        })
        options.push({value : null, label: <Button className={"col-12 btn-alliance"} variant={"outlined"} onClick={()=>{setAjoutClient(!ajoutClient)}}>Ajouter un client</Button>})
        setClients(options)
    }
    const setOptionHabilitation = (data) => {
        let options = [];
        data.map((e) => {
            options.push({value: e, label: e.libelle})
        });
        options.push({value : null, label: <Button className={"col-12 btn-alliance"} variant={"outlined"} onClick={()=>{setAjoutHabilitation(!ajoutHabilitation)}}>Ajouter une Habilitation</Button>})
        setHabilitations(options)
    }
    const setGroupeOptionActivite = (data) => {
        let options = [];
        data.map((e) => {
            options.push({value: e, label: e.libelle})
        });
        options.push({value : null, label: <Button className={"col-12 btn-alliance"} variant={"outlined"} onClick={()=>{setAjoutGroups(!ajoutGroups)}}>Ajouter un groupe d'activite</Button>})
        setGroupeActivites(options)
    }
    const setOptionCoordinateur = (data) => {
        let options = [];
        data.map((e) => {
            options.push({value: e, label: e.nom})
        });
        setCoordinateur(options)
    }
    const setOptionActivite = (data) => {
        let options = [];
        data.map((e) => {
            options.push({value: e, label: e.libelle})
        });
        setActivites(options)
    }


    const handleSubmit = async () => {
        try {
            activite.habilitations = [];
            habilitationAct.map(h => {
                activite.habilitations.push(h.value);
            })
            const response = await activiteAPI.update(activite);
            faite.activite = response.data;
            const res = await FaiteAPI.update(faite);
            setIsOpen(false);
            toast.success("L'activite à bien été ajoutée")
            fetchActivite()
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchClients().then(res => setOptionClients(res))
        fetchHabilitations().then(res => setOptionHabilitation(res))
        fetchGroupeActivite().then(res => setGroupeOptionActivite(res))
        fetchCoordinateur().then(res => setOptionCoordinateur(res))
    }, []);

    useEffect(() => {
        if(activite.groupeActivite != undefined){
            fetchActiviteByGroup().then(res => setOptionActivite(res))
        }
    }, [activite]);

    useEffect(() => {
        if (activiteC != null && activiteC != undefined){
            fetchActiviteById()
        }
    }, [activiteC]);

    useEffect(() => {
        fetchGroupeActivite().then(res => setGroupeOptionActivite(res))
    }, [ajoutGroups]);

    useEffect(() => {
        fetchClients().then(res => setOptionClients(res))
    }, [ajoutClient]);

    useEffect(() => {
        fetchHabilitations().then(res => setOptionHabilitation(res))
    }, [ajoutHabilitation]);

    return <>
        <Modal open={isOpen} onClose={() => {
            setIsOpen(!isOpen);
        }}
               aria-labelledby={"modal-modal-title"}
               aria-describedby={"modal-modal-description"}
        >
            <Box sx={modalStyle}>
                <Typography variant="h5" component="h2" className="d-flex justify-content-center p-2 border-bottom">
                    Modification d'une Activité
                </Typography>
                <div className="form-modal flex flex-column gap-2">
                    {/* Groupe d'activité */}
                    <SelectInput
                        classNameLabel={"text-end mr-2 pr-5"}
                        required={true}
                        label={"Groupe Activité"}
                        placeholder={"Groupe Activité"}
                        flex={"row"}
                        options={groupeActivites}
                        name={"groupeActivite"}
                        objet={activite}
                        setObjet={setActivite}
                        onChange={(value) => {
                            setActivite({...activite, groupeActivite: {id: value.value, nom: value.label}});
                        }}
                        value={activite.groupeActivite && {
                            value: activite.groupeActivite.id,
                            label: activite.groupeActivite.libelle
                        }}
                    />

                    {/* Client */}
                    <SelectInput
                        classNameLabel={"text-end mr-2 pr-5"}
                        required={true}
                        name={"client"}
                        flex={"row"}
                        placeholder={"Client"}
                        options={clients}
                        value={faite.client && {value: faite.client, label: faite.client.nom}}
                        objet={faite}
                        setObjet={setFaite}
                        label={"Client"}
                    />
                    {/*<SelectInput*/}
                    {/*    classNameLabel={"text-end mr-2 pr-5"}*/}
                    {/*    required={true}*/}
                    {/*    name={"parent"}*/}
                    {/*    flex={"row"}*/}
                    {/*    placeholder={"Activité"}*/}
                    {/*    options={activites}*/}
                    {/*    value={activiteParent.parent && {value: activiteParent.parent, label: activiteParent.parent.libelle}}*/}
                    {/*    objet={activite}*/}
                    {/*    setObjet={setActivite}*/}
                    {/*    label={"Activité"}*/}
                    {/*    disabled={true}*/}
                    {/*/>*/}
                    <SelectInput
                        classNameLabel={"text-end mr-2 pr-5"}
                        required={true}
                        name={"parent"}
                        flex={"row"}
                        placeholder={"Sous-Activité-1"}
                        options={activites}
                        value={activite.parent && {value: activite.parent, label: activite.parent.libelle}}
                        objet={activite}
                        setObjet={setActivite}
                        label={"Activité Parent"}
                    />
                    {/* Nom de l'activité */}
                    <Input
                        classNameLabel={"text-end mr-2 pr-5"}
                        required={true}
                        type={"text"}
                        name={'libelle'}
                        label="Nom de l'activité"
                        value={activite.libelle}
                        objet={activite}
                        setObjet={setActivite}
                        placeholder={"Nom de l'activité"}
                    />
                    {/* Coordinateur */}
                    <SelectInput
                        classNameLabel={"text-end mr-2 pr-5"}
                        label="Coordinateur"
                        name={"coordinateur"}
                        options={coordinateur}
                        value={activite.coordinateur && {
                            value: activite.coordinateur,
                            label: activite.coordinateur.nom
                        }}
                        objet={activite}
                        setObjet={setActivite}
                    />
                    {/* Localisation */}
                    <SelectInput
                        classNameLabel={"text-end mr-2 pr-5"}
                        label="localisation"
                        name={"localisation"}
                        value={activite.localisation && {value: activite.localisation, label: activite.localisation}}
                        options={[
                            {value: "EXTERNE", label: "Externe"},
                            {value: "INTERNE", label: "Interne"}
                        ]}
                        objet={activite}
                        setObjet={setActivite}
                    />

                    {/* Nombre de salariés théoriques */}
                    <Input
                        classNameLabel={"text-end mr-2 pr-5"}
                        type={"number"}
                        name={'nombreSalarie'}
                        label="Nombre de salariés théoriques"
                        value={faite.nombreSalarie}
                        objet={faite}
                        setObjet={setFaite}
                        placeholder={"Nombre de salariés théoriques"}
                    />
                    {/* Habilitations */}
                    <SelectInput
                        classNameLabel={"text-end mr-2 pr-5"}
                        label="Habilitations"
                        options={habilitations}
                        value={habilitationAct}
                        setObjet={setHabilitationAct}
                        objet={habilitationAct}
                        isMulti={true}
                    />
                    <Input
                        classNameLabel={"text-end mr-2 pr-5"}
                        name={"codeCouleur"}
                        type={"color"}
                        label={"Couleur"}
                        placeholder={"Couleur de l'activité"}
                        value={activite.codeCouleur}
                        setObjet={setActivite}
                        objet={activite}
                    />
                    <Input
                        classNameLabel={"text-end mr-2 pr-5"}
                        name={"couleurText"}
                        type={"color"}
                        label={"Couleur Text"}
                        placeholder={"Couleur du texte l'activité"}
                        value={activite.couleurText}
                        setObjet={setActivite}
                        objet={activite}
                    />
                    <div className={"offset-4 col-8 ml-2 pl-4"} style={{background : activite.codeCouleur, color:activite.couleurText, borderRadius:'50px'}}>Preview</div>
                </div>
                <div className="d-flex justify-content-end p-2">
                    <Button className={"btn btn-alliance"} variant={"outlined"} onClick={handleSubmit}>
                        Enregistrer
                    </Button>
                </div>
            </Box>
        </Modal>
        <ModalGroups isOpen={ajoutGroups} setIsOpen={setAjoutGroups}/>
        <ModalClient setIsOpen={setAjoutClient} isOpen={ajoutClient}/>
        <ModalPrestataire setIsOpen={setAjoutPrestataire} isOpen={ajoutPrestataire} />
        <ModalHabilitation setIsOpen={setAjoutHabilitation} isOpen={ajoutHabilitation} />
    </>;
};

export default ModalEditActivite;


