import React, {useContext, useEffect, useState} from 'react';
import AllianceCalendar from 'alliance-calendar';
import logoMerlin from '../../Assets/img/logo-merlin-without-text.png';
import '../../Styles/planning.css';
import Header2Planning from "../../Components/Headers/Header2Planning";
import ModalActivite from "../../Components/Modal/ModalActivite";
import groupeActiviteAPI from "../../Services/groupeActiviteAPI";
import prestataireAPI from "../../Services/prestataireAPI";
import ActiviteAPI from "../../Services/activiteAPI";
import {toast} from "react-toastify";
import planningActiviteAPI from "../../Services/planningActiviteAPI";
import moment from "moment";
import clientAPI from "../../Services/clientAPI";
import AuthContext from "../../Context/AuthContext";
import Loading from "../../Components/Loading";
import ModalActiviteEdit from "../../Components/Modal/ModalActiviteEdit";
import {da} from "date-fns/locale";

const Planning = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [groupeActivite, setGroupeActivite] = useState({});
    const [optionsGroupeActivite, setOptionsGroupeActivite] = useState([]);

    const [prestataire, setPrestataire] = useState({});
    const [optionsPrestataire, setOptionsPrestataire] = useState([]);

    const [client, setClient] = useState({});
    const [clients, setClients] = useState([]);
    const [optionsClient, setOptionsClient] = useState([]);

    const [activite, setActivite] = useState({});
    const [activites, setActivites] = useState([]);
    const [activitesWithoutParent, setActivitesWithoutParent] = useState([]);
    const [optionsActivite, setOptionsActivite] = useState([]);
    const [optionsActivitesWithoutParent, setOptionsActivitesWithoutParent] = useState([]);

    const [events, setEvents] = useState([]);
    const [eventsFiltered, setEventsFiltered] = useState(null);

    const [eventSelected, setEventSelected] = useState({});

    const {day, setDay} = useContext(AuthContext);
    const [date, setDate] = useState(moment());
    const fetchGroupeActivite = async () => {
        try {
            let response = await groupeActiviteAPI.fetchAll();
            return response.data;
        } catch (e) {
            toast.error("Une erreur est survenue");
            console.log(e);
        }
    };

    const fetchPrestataire = async () => {
        try {
            let response = await prestataireAPI.fetchAll();
            return response.data;
        } catch (e) {
            toast.error("Une erreur est survenue");
            console.log(e);
        }
    };

    const fetchActivite = async (ga = groupeActivite.value) => {
        if (ga !== undefined && ga !== null) {
            try {
                let response = await ActiviteAPI.fetchAllByGroupeActivite(ga.id);
                setActivites(response.data);
                return response.data;
            } catch (e) {
                toast.error("Une erreur est survenue");
                console.log(e);
            }
        } else {
            return [];
        }
    };

    const fetchActiviteWithoutParent = async (client, ga) => {
        if (client !== undefined && client !== null && ga !== undefined && ga !== null) {
            try {
                let response = await ActiviteAPI.fetchAllByClientAndParentIsNull(client.id,ga.id);
                setActivitesWithoutParent(response.data);
                return response.data;
            } catch (e) {
                toast.error("Une erreur est survenue");
                console.log(e);
            }
        } else {
            return [];
        }
    };

    const fetchClients = async () => {
        try {
            let response = await clientAPI.fetchAll();
            setClients(response.data);
            return response.data;
        } catch (e) {
            toast.error("Une erreur est survenue");
            console.log(e);
        }
    };


    const fetchPlanningActivite = async () => {
        try {
            let data = [];
            let response = await planningActiviteAPI.fetchAll();
            // let response = await planningActiviteAPI.fetchAllByDate(moment(date).format('YYYY-MM-DD HH:mm:ss'));
            response.data.map(async act => {
                let employes = [];
                let titre = "";
                if (act.activites == null){
                    act.activites = [];
                }
                if (act.activites.length <= 0){
                    console.log(act)
                }
                if (act.employes !== undefined) {
                    act.employes.map((emp, index) => {
                        employes.push(emp.nom + " " + emp.prenom);
                    });
                }
                act.activites.map(acti => {
                    if (titre == ""){
                        titre = acti.groupeActivite.libelle +": " + acti.libelle;
                    }else {
                        titre = titre + ", " + acti.libelle;
                    }
                })
                if (act.activites[0]){
                    data.push({
                        "activite": {value: act, visible: false},
                        "dateDebut": {value: act.dateDebut.toString().substring(0, 10), visible: false},
                        "dateFin": {value: act.dateFin.toString().substring(0, 10), visible: false},
                        "titre": {
                            value: act.activites[0].groupeActivite.libelle + " : " + act.activites[0].libelle,
                            visible: true
                        },
                        "color": {value: act.activites[0].codeCouleur, visible: false},
                        "prestataire": {value: "Prestataire : " + (act.prestataire != null ? act.prestataire.nom : ""), visible: true},
                        "client": {value: "Client : " + act.client.nom, visible: true},
                        "textColor": {
                            value: act.activites[0] != undefined ? act.activites[0].couleurText : "#FFFFFF",
                            visible: false
                        },
                        "isReccurente": {value: act.recurrence, visible: false},
                        "details": {
                            "": moment.unix(act.dateDebut.toString().substring(0, 10)).format("HH:mm") + " - " + moment.unix(act.dateFin.toString().substring(0, 10)).format("HH:mm"),
                            "titre": titre ,
                            "prestataire": "Prestataire : " + (act.prestataire != null ? act.prestataire.nom : ""),
                            "client": "Client : " + act.client.nom,
                            "employes": employes
                        },
                        "etat": {value: act.etat, visible: false}
                    });
                }


            });
            setEvents(data);
            setIsLoading(false)
        } catch (e) {
            console.log(e);
        }
    };

    const setOptionsSelectGroupeActivite = (ga) => {
        let options = [];
        ga.map((groupeA, index) => {
            options.push({value: groupeA, label: groupeA.libelle});
        });
        setOptionsGroupeActivite(options.sort((a, b) => a.label.localeCompare(b.label)));
    };

    const setOptionsSelectPrestataire = (prest) => {
        let options = [];
        prest.map((pr, index) => {
            options.push({value: pr, label: pr.nom});
        });
        setOptionsPrestataire(options);
    };

    const setOptionsSelectActivite = (a) => {
        let options = [];
        a.map((ac, index) => {
            if (ac.enfants.length <= 0 ){
                options.push({value: ac, label: ac.libelle});
            }
        });
        setOptionsActivite(options);
    };

    const setOptionsSelectActiviteWithoutParent = (a) => {
        let options = [];
        a.map((ac, index) => {
            options.push({value: ac, label: ac.libelle});
        });
        setOptionsActivitesWithoutParent(options.sort((a, b) => a.label.localeCompare(b.label)));
    };

    const setOptionsSelectClient = (clients) => {
        let options = [];
        if (clients !== undefined && clients !== null) {
            clients.map((cl, index) => {
                options.push({value: cl, label: cl.nom});
            });
        }
        setOptionsClient(options);
    };

    useEffect(() => {
        fetchPlanningActivite();
        fetchClients().then((cls) => {
            setOptionsSelectClient(cls);
        });
        fetchGroupeActivite().then((ga) => {
            setOptionsSelectGroupeActivite(ga);
        });
        fetchPrestataire().then((prest) => {
            setOptionsSelectPrestataire(prest);
        });
    }, []);


    useEffect(() => {
        fetchActivite().then((a) => {
            setOptionsSelectActivite(a);
        });
        // fetchActiviteWithoutParent().then((act) => {
        //     setOptionsSelectActiviteWithoutParent(act);
        // });
    }, [groupeActivite]);

    useEffect(() => {
        setActivite({})
    }, [groupeActivite]);

    useEffect(() => {
        if (events.length > 0) {
            let options = [];
            let haveGroupeActivite = groupeActivite !== null && Object.keys(groupeActivite).length > 0;
            let haveActivite = activite.activite !== null && Object.keys(activite).length > 0;
            let haveClient = client.client !== null && Object.keys(client).length > 0;
            let havePrestataire = prestataire.prestataire !== null && Object.keys(prestataire).length > 0;

            if (haveGroupeActivite && options.length === 0) {
                options = events.filter((eve) => {
                    return eve.titre.value.includes(groupeActivite.label);
                });
            } else if (haveGroupeActivite && options.length > 0) {
                options = options.filter((eve) => {
                    return eve.titre.value.includes(groupeActivite.label);
                });
            }

            if (haveActivite && options.length === 0) {
                options = events.filter((eve) => {
                    return eve.titre.value.includes(activite.label);
                });
            } else if (haveActivite && options.length > 0) {
                options = options.filter((eve) => {
                    return eve.titre.value.includes(activite.label);
                });
            }

            if (haveClient && options.length === 0) {
                options = events.filter((eve) => {
                    return eve.activite.value.client.id === client.client.id;
                });
            } else if (haveClient && options.length > 0) {
                options = options.filter((eve) => {
                    return eve.activite.value.client.id === client.client.id;
                });
            }

            if (havePrestataire && options.length === 0) {
                options = events.filter((eve) => {
                    return eve.prestataire.value.replace("Prestataire : ", "") == prestataire.prestataire.nom;
                });
            } else if (havePrestataire && options.length > 0) {
                options = options.filter((eve) => {
                    return eve.prestataire.value.replace("Prestataire : ", "") == prestataire.prestataire.nom;
                });
            }

            if (!haveGroupeActivite && !haveActivite && !haveClient && !havePrestataire && options.length === 0) {
                options = events;
            }
            setEventsFiltered(options);
        }
    }, [activite, groupeActivite, client, prestataire]);


    return (
        <>
            <Header2Planning className={"mt-3"} isOpen={isOpen} setIsOpen={setIsOpen} activite={activite}
                             setActivite={setActivite} optionsActivite={optionsActivite} groupeActivite={groupeActivite}
                             setGroupeActivite={setGroupeActivite} optionsGroupeActivite={optionsGroupeActivite}
                             client={client} optionsClient={optionsClient} setClient={setClient}
                             prestataire={prestataire}
                             setPrestataire={setPrestataire} optionsPrestataire={optionsPrestataire}/>
            {isLoading == true ? <Loading /> :<>
                <AllianceCalendar setOpenModal={setOpenInfo}
                                  setOpenEditModal={setIsOpenEdit} setEventSelected={setEventSelected}
                                  // setOpenEditModal={() => {}} setEventSelected={() => {}}
                                  className={"planning"} logo={logoMerlin}
                                  showSideBar={false} events={eventsFiltered !== null ? eventsFiltered : events}
                                  viewType={"week"}
                                  day={(day !== null && moment(day['_d']) !== "") ? moment(day['_d']) : moment()}
                                  plageDebut={8} plageFin={17} setDay={setDay} date={date} setDate={setDate}
                />
            </>}

            {/* For Create*/}
            <ModalActivite fetchPlanningActivite={fetchPlanningActivite} isOpen={isOpen} setIsOpen={setIsOpen}
                           clients={optionsClient} prestataires={optionsPrestataire}
                           groupeActivites={optionsGroupeActivite} updateActivite={(client, ga) => {
                fetchActiviteWithoutParent(client, ga).then((a) => {
                    setOptionsSelectActiviteWithoutParent(a);
                });
            }} activites={optionsActivitesWithoutParent} updateClient={(act) => {
                setOptionsSelectClient(act);
            }}/>
            {/* For Update*/}
            <ModalActiviteEdit isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} clients={optionsClient} groupeActivites={optionsGroupeActivite} prestataires={optionsPrestataire} activites={optionsActivitesWithoutParent} activiteC={eventSelected.activite != undefined ? eventSelected.activite.value : {}} setEventSelected={setEventSelected} fetchPlanningActivite={fetchPlanningActivite} updateClient={(act) => {
                setOptionsSelectClient(act);
            }}/>
        </>
    );
};

export default Planning;
