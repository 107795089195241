import React from 'react';
import {
    AccountCircle,
    Assignment,
    AssignmentInd,
    CalendarMonth,
    DateRange,
    EditCalendar,
    KeyboardArrowDown,
    KeyboardArrowUp,
    PeopleAlt,
    PermContactCalendar,
    QueryStats,
    Receipt,
    ReceiptLong,
    Settings,
    Summarize,
    SupervisedUserCircle
} from '@mui/icons-material';
// import Planning from "../Page/Planification/Planning";
import PlanningAtelier from "../Page/Planification/PlanningAtelier";
import ParametrePlanif from '../Page/Planification/Parametre';
import PlanningEmploye from "../Page/Planification/PlanningEmploye";
import Activite from "../Page/Activite";
import ParametreActivite from "../Page/Activite/Parametre";
import ListePrestataire from "../Page/Activite/ListePrestataire";
import Statistique from "../Page/Activite/Statistique";
import Client from "../Page/Client";
import ParametreClient from "../Page/Client/Parametre";
import RH from "../Page/RH";
import ParametreRH from "../Page/RH/Parametre";
import Synthese from "../Page/Synthese";
import Parametre from "../Page/Parametre";
import ParametreGeneraux from "../Page/Parametre/ParametreGeneraux";
import Utilisateurs from "../Page/Parametre/Utilisateurs";
import Verification from "../Page/Planification/Verification";
import Planning from "../Page/Planification/Planning";
import EtatPreparatoire from "../Page/Client/EtatPreparatoire";
import InformationsEmploye from "../Page/RH/InformationsEmploye";

const Main = React.lazy(() => import('../Page/Main'));

export const routes = [
    {
        icon: <CalendarMonth/>,
        iconClosed: <KeyboardArrowUp/>,
        iconOpen: <KeyboardArrowDown/>,
        link: '/planning',
        name: "Planning",
        component: <Planning/>,
        visible: true,
        roles: [],
        collapse: [
            {
                icon: <EditCalendar/>,
                link: "/planning/verification",
                name: "Vérification Planning",
                component: <Verification/>,
                visible: true,
                roles: []
            }, {
                icon: <DateRange/>,
                link: '/planning/groupe',
                name: "Planning Groupe",
                component: <PlanningAtelier/>,
                visible: true,
                roles: []
            }, {
                icon: <PermContactCalendar/>,
                link: '/planning/employe',
                name: "Planning Employé",
                component: <PlanningEmploye/>,
                visible: true,
                roles: []
            }, {
                icon: <Settings/>,
                link: '/planning/parametre',
                name: "Paramètres",
                component: <ParametrePlanif/>,
                visible: true,
                roles: []
            }
        ]
    },
    {
        icon: <Assignment/>,
        iconClosed: <KeyboardArrowUp/>,
        iconOpen: <KeyboardArrowDown/>,
        link: '/activite',
        name: "Activités",
        component: <Activite/>,
        visible: true,
        roles: [],
        collapse: [
            {
                icon: <PeopleAlt/>,
                link: '/activite/prestataire',
                name: "Liste des Prestataires",
                component: <ListePrestataire/>,
                visible: true,
                roles: []
            }, {
                icon: <QueryStats/>,
                link: '/activite/statistiques',
                name: "Statistiques",
                component: <Statistique/>,
                visible: true,
                roles: []
            }, {
                icon: <Settings/>,
                link: '/activite/parametre',
                name: "Paramètres",
                component: <ParametreActivite/>,
                visible: true,
                roles: []
            }
        ]
    },
    {
        icon: <AssignmentInd/>,
        iconClosed: <KeyboardArrowUp/>,
        iconOpen: <KeyboardArrowDown/>,
        link: '/clients',
        name: "Clients",
        component: <Client/>,
        visible: true,
        roles: [],
        collapse: [
            {
                icon: <ReceiptLong/>,
                link: '/clients/facturation',
                name: "Etat préparatoire facturation",
                component: <EtatPreparatoire/>,
                visible: true,
                roles: []
            }, {
                icon: <Settings/>,
                link: '/clients/parametre',
                name: "Paramètres",
                component: <ParametreClient/>,
                visible: true,
                roles: []
            }
        ]
    }, {
        icon: <AccountCircle/>,
        iconClosed: <KeyboardArrowUp/>,
        iconOpen: <KeyboardArrowDown/>,
        link: '/RH',
        name: "RH",
        component: <RH/>,
        visible: true,
        roles: ["ROLE_ADMIN" , "ROLE_COORDINATEUR" , "ROLE_ENCADRANT"],
        collapse: [
            {
                icon: <Receipt/>,
                link: '/RH/paye',
                name: "Etat préparatoire paye",
                component: <EtatPreparatoire/>,
                visible: true,
                roles: []
            }, {
                icon: <Settings/>,
                link: '/RH/parametre',
                name: "Paramètres",
                component: <ParametreRH/>,
                visible: true,
                roles: []
            }, {
                icon: null,
                link: 'RH/informations',
                name: "Informations",
                component: <InformationsEmploye/>,
                visible: false,
                roles: []
            }
        ]
    },
    {
        icon: <Summarize/>,
        // iconClosed: <KeyboardArrowUp />,
        // iconOpen: <KeyboardArrowDown />,
        link: '/synthese',
        name: "Synthèse",
        component: <Synthese/>,
        visible: true,
        roles: []
    },
    {
        icon: <Settings/>,
        iconClosed: <KeyboardArrowUp/>,
        iconOpen: <KeyboardArrowDown/>,
        link: '/parametre',
        name: "Paramètres",
        component: <Parametre/>,
        visible: true,
        roles: [],
        collapse: [
            {
                icon: <Settings/>,
                link: '/parametre/generaux',
                name: "Paramètres Généraux",
                component: <ParametreGeneraux/>,
                visible: true,
                roles: []
            },
            {
                icon: <SupervisedUserCircle/>,
                link: '/parametre\/utilisateur',
                name: "Utilisateurs",
                component: <Utilisateurs/>,
                visible: true,
                roles: ["ROLE_ADMIN" , "ROLE_COORDINATEUR" , "ROLE_ENCADRANT"],
            }
        ]
    },
    // {
    //     icon: <Summarize/>,
    //     // iconClosed: <KeyboardArrowUp />,
    //     // iconOpen: <KeyboardArrowDown />,
    //     link: '/versionning',
    //     name: "Versionning",
    //     component: <Versionning />,
    //     visible: true,
    //     roles: []
    // },
]
