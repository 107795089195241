import React from 'react';
import './index.css';
import packageJson from '../../../package.json';
import {Link} from "react-router-dom"

const Footer = () => {
    return <div className={"footer"}>
        <p className={"copyright"}>Alliance Digital <Link to={"/versionning"}>{packageJson.version}</Link></p>
    </div>;
};

export default Footer;