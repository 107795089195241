export const ROLES_LIST = [
    {
        value: "[ROLE_ADMIN]",
        label: "Administrateur"
    },
    {
        value: "[ROLE_ASSISTANT]",
        label: "Assistant"
    },
    {
        value: "[ROLE_COORDINATEUR]",
        label: "Coordinateur"
    },
    {
        value: "[ROLE_ENCADRANT]",
        label: "Encadrant"
    },
    {
        value: "[ROLE_EMPLOYE]",
        label: "Employé"
    },
]