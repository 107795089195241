import React from 'react';
import Header2Generique from "../../Components/Headers/Header2Generique";

const Parametre = () => {
    return (
        <div>
            <Header2Generique title={"Paramètres des activités"}/>

        </div>
    );
};

export default Parametre;