import React, {useState} from 'react';
import HabilitationUser from "../Modal/ModalHabilitation/HabilitationUser";
import {Delete} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import {EmployeAPI} from "../../Services/EmployeAPI";
import {toast} from "react-toastify";

const HabilitationEmployeTab = ({user, setUser}) => {
    const [modal, setModal] = useState(false);
    const [habilitationSelected, setHabilitationSelected] = useState({});

    const handleDelete = async (habilitation) => {
        try {
            user.habilitations = user.habilitations.filter(item => item.id != habilitation.id)
            let response = await EmployeAPI.updateEmploye(user.id, user)
            setUser({...user, user})
            toast.success("Mise à jour réussi")
        } catch (e) {
            console.log(e)
            toast.error("Une erreur est survenue")
        }
    }


    const handleAdd = async () => {
        try {
            let option = user.habilitations;
            option.push(habilitationSelected.hab)
            user.habilitations = option;
            let response = await EmployeAPI.updateEmploye(user.id, user)
            setModal(false)
            toast.success("Mise à jour réussi")
        } catch (e) {
            console.log(e)
            toast.error("Une erreur est survenue")
        }
    }

    return <>
        <div className={"flex justify-content-end"}>
            <button className={"btn btn-add-activity mb-3"} onClick={() => setModal(true)}>
                Ajouter une habilitation
            </button>
        </div>
        <ul className={"list-group"}>
            {user.habilitations && user.habilitations.length > 0 ? (
                user.habilitations.map((habilitation, index) => (
                    <li key={index} className={"list-group-item d-flex justify-content-between align-items-center"}>
                        {habilitation.libelle}
                        {/*{habilitation.outils}*/}
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={() => {
                                handleDelete(habilitation)
                            }}
                        >
                            <Delete color={"error"}/>
                        </IconButton>
                    </li>
                ))
            ) : (
                <li className={"list-group-item"}>Aucune habilitations
                    pour {user.nom} {user.prenom}</li>
            )}
        </ul>
        <HabilitationUser isOpen={modal} setIsOpen={setModal} habilitationSelected={habilitationSelected}
                          setHabilitationSelected={setHabilitationSelected} user={user} handleAdd={handleAdd}/>
    </>;
};

export default HabilitationEmployeTab;