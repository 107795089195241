import Color from "./index";

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid ' + Color.grey,
    boxShadow: 50,
    p: 4,
    padding: 0,
    maxHeight: '90%',
    overflow: 'scroll',
};