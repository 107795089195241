import axios from "axios";
import {INDISPONIBILITE_API} from "../Config/url";

export class indisponibiliteAPI {
    static async fetchIndiponibiliteByEmploye(id) {
        return axios.get(INDISPONIBILITE_API + "/" + id + "/employes");
    }


    static async addIndisponibilite(indisponibilite) {
        return axios.post(INDISPONIBILITE_API, indisponibilite);

    }

    static async delete(indisponibilite) {
        return axios.delete(INDISPONIBILITE_API+"/"+indisponibilite.id);

    }

    static update(indisponibilite) {
        return axios.put(INDISPONIBILITE_API+"/"+indisponibilite.id, indisponibilite);

    }
}