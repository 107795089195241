import React, {createRef, useEffect, useState} from 'react';

import {Route, Routes} from "react-router-dom";
import AuthContext from "./Context/AuthContext";
import SideBarContext from "./Context/SideBarContext";
import Loading from "./Components/Loading";
import Sidebar from "./Components/SideBar";
import {routes} from "./rooting/routes";
import Auth from "./Services/Auth";
import {ToastContainer} from "react-toastify";
import Footer from "./Components/Footer";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import Main from "./Page/Main";
import fr from 'moment/locale/fr';
import moment from "moment";
import Versionning from "./Page/Versionning";

const AuthO = new Auth();

const Login = React.lazy(() => import('./Page/Login'));
AuthO.setup();

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(AuthO.isAuthenticated());
    const [user, setUser] = useState(AuthO.getUser());
    const [roles, setRoles] = useState(AuthO.getRoles())
    const [tabSelected, setTabSelected] = useState(window.localStorage.getItem("tabSelected") != null ? window.localStorage.getItem("tabSelected") : "informations_personnelles");
    const [uuid, setUuid] = useState("")
    const [isOpen, setIsOpen] = useState(true);
    const [routeSelected, setRouteSelected] = useState(null);
    const [day, setDay] = useState(moment())
    const ref = createRef(null);

    useEffect(() => {
        moment.updateLocale('fr', {
            weekdays: 'Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi'.split('_'),
            weekdaysShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
            weekdaysMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"]
        });
    }, []);


    return <AuthContext.Provider
        value={{isAuthenticated, setIsAuthenticated, user, setUser, roles, setRoles, uuid, setUuid, day, setDay, tabSelected, setTabSelected}}>
        <SideBarContext.Provider value={{isOpen, setIsOpen, routeSelected, setRouteSelected}}>
            <LocalizationProvider dateAdapter={AdapterMoment} localeText={fr} locale={"fr"} >
                <div className={"container-fluid"} key={"main"} ref={ref}>
                    {isAuthenticated && <>
                        <div className={"containerPerso"} key={"container"}>
                            <Sidebar reference={ref}/>
                            <div className={isOpen ? "main" : "mainCollapse"}>
                                <Routes key={"route1"}>
                                    {routes.map((route, index) => {
                                        if (route.collapse != undefined) {
                                            return <> {route.collapse.map(r => {
                                                return <Route key={"route" + r.name} element={
                                                    <React.Suspense exact fallback={<Loading/>}>
                                                        {r.component}
                                                    </React.Suspense>
                                                } path={r.link} exact={true}/>

                                            })}
                                                <Route key={"routeb" + route.name} element={
                                                    <React.Suspense exact fallback={<Loading/>}>
                                                        {route.component}
                                                    </React.Suspense>
                                                } path={route.link} exact={true}/>
                                            </>
                                        }
                                    })}

                                    <Route key={"RouteMain"} path="*" element={<Main/>}/>
                                    <Route key={"Versionning"} path={"/versionning"} element={<Versionning />}></Route>
                                </Routes>
                            </div>
                            <Footer/>
                        </div>
                    </>}
                    <Routes key={"route2"}>
                        {!isAuthenticated &&
                            <Route key={"login"} element={
                                <React.Suspense fallback={<Loading/>}>
                                    <Login/>
                                </React.Suspense>
                            } path={"*"}/>
                        }
                    </Routes>
                </div>
            </LocalizationProvider>
            <ToastContainer position={"bottom-right"} theme={"colored"}/>
        </SideBarContext.Provider>
    </AuthContext.Provider>
}

export default App;