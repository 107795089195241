import React, {useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import '../../../Styles/ModalForm.css';
import Input from "../../FormsComponents/Input";
import Button from 'react-bootstrap/Button'
import {toast} from "react-toastify";
import {groupeActiviteAPI} from "../../../Services/groupeActiviteAPI";
import {modalStyle} from "../../../Styles/ModalStyle";

const ModalGroups = ({isOpen, setIsOpen, groupe}) => {
    const [group, setGroup] = useState({});
    const handleCloseModal = () => {
        setIsOpen(!isOpen);
        setGroup({});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let bool = groupe.map(grp => {
                if (grp.libelle === group.libelle){
                    return true;
                }else{
                    return false;
                }
            })
            if (!bool.includes(true)){
                await groupeActiviteAPI.createGroup(group);
                toast.success("Le groupe a bien été créée");
                handleCloseModal();
            }else{
                toast.error("Le groupe existe déjà");
                handleCloseModal();
            }
        } catch (e) {
            toast.error("Une erreur est survenue");
        }
    }

    return <Modal open={isOpen}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h5" component="h2"
                        className={"d-flex justify-content-center p-2 border-bottom"}> Créer un Groupe
            </Typography>
            <div className={"form-modal flex flex-column gap-2"}>
                <form onSubmit={handleSubmit}>

                    <div className="my-2">
                        <Input required={true} type={"text"} name={"libelle"} label={"Nom du groupe"}
                               value={group.libelle}
                               placeholder={"Nom du groupe"} objet={group} setObjet={setGroup}/>
                    </div>

                    <div className="d-flex justify-content-end">
                        <div className="d-flex justify-content-between col-3 me-2 mt-2">
                            <Button variant={"outlined"} className={"btn btn-danger"} color={"danger"}
                                    onClick={handleCloseModal} type="button">Annuler</Button>
                            <Button variant={"outlined"} className={"btn btn-alliance"} type="submit">Enregistrer</Button>
                        </div>
                    </div>
                </form>
            </div>
        </Box>
    </Modal>;
};

export default ModalGroups;