import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import '../../../Styles/ModalForm.css';
import SelectInput from "../../FormsComponents/SelectInput";
import {UtilisateursAPI} from "../../../Services/UtilisateursAPI";
import {Button, CircularProgress} from "@mui/material";
import {toast} from "react-toastify";
import {ROLES_LIST} from "../../../Services/RolesEnum";
import {modalStyle} from "../../../Styles/ModalStyle";

const ModalRole = ({isOpen, setIsOpen, id = null, setId = null, setReload}) => {
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);

    const handleCloseModal = () => {
        setIsOpen(!isOpen);
        setId(null);
        setUser({});
        setReload(true);
    }
    const fetchUser = async id => {
        let data = await UtilisateursAPI.getUserById(id);
        setUser(data);
        setLoading(false);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            await UtilisateursAPI.updateRole(id, user);
            toast.success("Le rôle de l'utilisateur à bien été modifié")
            handleCloseModal();
        } catch (e) {
            toast.error("Une erreur est survenue");
        }
    }

    useEffect(() => {
        if (id) {
            setLoading(true)
            fetchUser(id).then(r => '')
        }

    }, [id])

    return <Modal open={isOpen}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h5" component="h2"
                        className={"d-flex justify-content-center p-2 border-bottom"}>
                Modifier le rôle de l'utilisateur
            </Typography>
            <div className={"form-modal flex flex-column gap-2"}>
                {(loading) && <div className={"text-center"}><CircularProgress color={"primary"}/></div>
                    || <>
                        <form onSubmit={handleSubmit}>

                            <SelectInput setObjet={setUser} objet={user} placeholder={"Rôle de l'utilisateur"}
                                         value={ROLES_LIST.find((value) => value.value === user.roles)} name={"roles"}
                                         label={"Rôle"} required={true}
                                         options={ROLES_LIST}/>

                            <div className="d-flex justify-content-end">
                                <div className="d-flex justify-content-between col-3 me-2 mt-2">
                                    <Button variant={"outlined"} color={"error"}
                                            onClick={handleCloseModal} type="button">Annuler</Button>
                                    <Button variant={"outlined"} color={"primary"} type="submit">Enregistrer</Button>
                                </div>
                            </div>
                        </form>

                    </>
                }
            </div>
        </Box>
    </Modal>;
};

export default ModalRole;