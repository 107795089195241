import React from 'react';
import '../../../Styles/ModalForm.css';
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {toast} from "react-toastify";
import {modalStyle} from "../../../Styles/ModalStyle";

const DeleteModal = ({id, isOpen, setIsOpen, setId, api, fetchUtilisateur = () => {}}) => {

    const deleteItem = async () => {
        try {
            await api.delete(id);
            setIsOpen(!isOpen);
            setId(null);
            toast.success("L'élément à bien été supprimé");
            fetchUtilisateur();
        } catch (e) {
            toast.error("Une erreur est survenue lors de la tentative de suppression de l'élément");
        }

    }
    const handleCloseModal = () => {
        setIsOpen(!isOpen);
        setId(null);
    }
    return (
        <Modal open={isOpen}
               onClose={handleCloseModal}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description">
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h5" component="h2"
                            className={"d-flex justify-content-center p-2 border-bottom"}>
                    Êtes vous sûr de vouloir supprimer cet élément ?
                </Typography>
                <div className="d-flex justify-content-end">
                    <div className="d-flex justify-content-between col-3 me-2 mt-2">
                        <button className={"btn btn-secondary m-2"} onClick={handleCloseModal} type={"button"}>
                            Annuler
                        </button>
                        <button className={"btn btn-add-activity m-2"} onClick={deleteItem}>
                            Supprimer
                        </button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default DeleteModal;