import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import '../../../Styles/ModalForm.css';
import Input from "../../FormsComponents/Input";
import {Button, CircularProgress} from "@mui/material";
import {toast} from "react-toastify";
import {HabilitationAPI} from "../../../Services/HabilitationAPI";
import {modalStyle} from "../../../Styles/ModalStyle";

const ModalHabilitation = ({isOpen, setIsOpen, id = null, setId = () => {}, edit = false, setEdit = () => {}, setReload = () => {}}) => {
    const [habilitation, setHabilitation] = useState({});
    const [loading, setLoading] = useState(false);
    const [validationError, setValidationError] = useState(null);

    const handleCloseModal = () => {
        setIsOpen(!isOpen);
        setEdit(false);
        setId(null);
        setHabilitation({});
        setReload(true);
    }

    const fetchHabilitation = async id => {
        let data = await HabilitationAPI.getHabilitationById(id);
        setHabilitation(data.data);
        setLoading(false);
    }

    // const handleValidation = () => {
    //     const regex = /^[a-zA-Z0-9-_]+$/;
    //     if (!regex.test(habilitation.libelle)) {
    //         setValidationError("L'habilitation ne peut contenir que des lettres, des chiffres, des - et des _.");
    //     } else {
    //         setValidationError(null);
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // handleValidation();
        //
        // if (validationError) {
        //     return;
        // }

        try {
            if (edit) {
                await HabilitationAPI.modifyHabilitation(id, habilitation);
                toast.success("L'habilitation a bien été modifiée");
            } else {
                // const regex = /^[a-zA-Z0-9-_]+$/;
                // if (!regex.test(habilitation.libelle)) {
                //     setValidationError("L'habilitation ne peut contenir que des lettres, des chiffres, des - et des _ .");
                //     return;
                // }

                await HabilitationAPI.createHabilitation(habilitation);
                toast.success("L'habilitation a bien été créée");
            }
            handleCloseModal();
        } catch (e) {
            toast.error("Une erreur est survenue");
        }
    }


    useEffect(() => {
        if (id) {
            setLoading(true);
            fetchHabilitation(id).then(r => '');
        }
    }, [id])

    return (
        <Modal open={isOpen}
               onClose={handleCloseModal}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description">
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h5" component="h2"
                            className={"d-flex justify-content-center p-2 border-bottom"}>
                    {edit ? "Modifier" : "Créer"} une Habilitation
                </Typography>
                <div className={"form-modal flex flex-column gap-2"}>
                    {loading ? (
                        <div className={"text-center"}>
                            <CircularProgress color={"primary"}/>
                        </div>
                    ) : (
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className="my-2">
                                    <Input
                                        required={true}
                                        type={"text"}
                                        name={"libelle"}
                                        label={"Nom"}
                                        value={habilitation.libelle}
                                        placeholder={"Nom de l'habilitation"}
                                        objet={habilitation}
                                        setObjet={setHabilitation}
                                    />
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="d-flex justify-content-between col-3 me-2 mt-2">
                                        <Button variant={"contained"} color={"error"} className={"btn m-2"}
                                                onClick={handleCloseModal} type="button">
                                            Annuler
                                        </Button>
                                        <Button className={"btn btn-add-activity m-2"} variant={"outlined"}
                                                color={"primary"} type="submit">
                                            Enregistrer
                                        </Button>
                                    </div>
                                </div>
                            </form>
                            {validationError && (
                                <div className="error-message mt-2 text-end"
                                     style={{fontStyle: 'italic', fontSize: 'smaller'}}>{validationError}</div>
                            )}
                        </>
                    )}
                </div>
            </Box>

        </Modal>
    );
};

export default ModalHabilitation;
