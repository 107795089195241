import axios from "axios";
import {FAITE_API} from "../Config/url";

export class FaiteAPI {
    static async getAll() {
        return axios.get(FAITE_API);
    }

    static async delete(id) {
        return axios.delete(FAITE_API + '/' + id);
    }

    static async create(faite) {
        return axios.post(FAITE_API, faite);
    }

    static async update(faite) {
        return axios.put(FAITE_API + '/' + faite.id, faite);
    }

    static async fetchFaiteByClientAndActivite(clientID, activiteID) {
        return axios.get(FAITE_API+'/'+clientID+'/client_activite/'+activiteID);

    }
}