import React, {useEffect, useState} from 'react';
import {IconButton, Tooltip} from "@mui/material";
import {Add, Delete, Visibility} from "@mui/icons-material";
import GenericDataTable from "../DataTables/GenericDataTable";
import planningHoraire from "../../Services/planningHoraire";
import {PlanningHoraireExempleAPI} from "../../Services/PlanningHoraireExempleAPI";
import ModalPlanningHoraire from "../Modal/ModalPlanningHoraire";
import ModalPlanningHoraireUpdate from "../Modal/ModalPlanningHoraire/ModalPlanningHoraireUpdate";
import ModalPlanningHoraireAddDay from "../Modal/ModalPlanningHoraire/ModalPlanningHoraireAddDay";
import moment from "moment";

const PlanningHoraires = ({user, setUser}) => {
    const [planning, setPlanning] = useState({});
    const [planningData, setPlanningData] = useState([]);
    const [planningExemple, setPlanningExemple] = useState([]);
    const [rowSelected, setRowSelected] = useState({});
    const [isOpenAdd, setIsOpenAdd] = useState(false);
    const [isOpenUpdate, setIsOpenUpdate] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [isOpenAddDay, setIsOpenAddDay] = useState(false);
    const [totalHeures, setTotalHeures] = useState(0);
    const [column, setColumn] = useState([
        {
            name: <span className="top-column">Jour</span>,
            selector: row => row.jour,
            sortable: true
        },
        {
            name: <span className="top-column">Heure Debut</span>,
            selector: row => row.heureDebut,
            sortable: true
        },
        {
            name: <span className="top-column">Heure Fin</span>,
            selector: row => row.heureFin,
            sortable: true
        },
        {
            name: <span className="top-column">Actions</span>,
            selector: row => <div className="d-flex justify-content-evenly">
                <Tooltip title={"Modifier"}>
                    <IconButton onClick={() => {
                        setRowSelected(row);
                        setIsOpenUpdate(true)
                    }}>
                        <Visibility color="warning"/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Supprimer l'horaire"}>
                    <IconButton onClick={() => {
                        setRowSelected(row);
                        setIsOpenDelete(true)
                    }}>
                        <Delete color="error"/>
                    </IconButton>
                </Tooltip>
            </div>
        }
    ]);

    const fetchPlanningHoraire = async () => {
        try {
            const response = await PlanningHoraireExempleAPI.getAll();
            setPlanningExemple(response.data);
        } catch (e) {
            console.log(e)
        }
    }

    const fetchUserPlanning = async () => {
        if (user.id){
            try {
                const response = await planningHoraire.fetchByEmploye(user.id)
                if (response.data != ""){
                    setPlanning(response.data)
                    setPlanningData((JSON.parse(response.data.jours)))
                    calculerTotalHeures((JSON.parse(response.data.jours)))
                    return response.data;
                }else {
                    setPlanning({})
                    setPlanningData([])
                    return [];
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    async function updatePlanningHoraire(pl) {
        try {
            const response = await planningHoraire.update(pl);
            setPlanning(response.data)
            setPlanningData((JSON.parse(response.data.jours)))
        } catch (e) {
            console.log(e)
        }
    }

    function calculerTotalHeures(tableau) {
        let totalMinutes = 0;

        tableau.forEach(element => {
            let heureDebut = moment(element.heureDebut, 'HH:mm');
            let heureFin = moment(element.heureFin, 'HH:mm');
            let difference = heureFin.diff(heureDebut, 'minutes');
            totalMinutes += difference;
        });

        let totalHeures = Math.floor(totalMinutes / 60);
        let minutesRestantes = totalMinutes % 60;
        if (minutesRestantes == 0){
            setTotalHeures(totalHeures+":00");
            return;
        }else{
            setTotalHeures(totalHeures+":"+minutesRestantes);
        }
    }

    useEffect(() => {
        fetchPlanningHoraire();
        fetchUserPlanning();
    }, []);

    useEffect(() => {
        fetchUserPlanning();
    }, [user]);


    useEffect(() => {
        if (isOpenDelete === true){
            let newPlanning = planningData.filter(function(element) {
                return !(element.jour === rowSelected.jour &&
                    element.heureDebut === rowSelected.heureDebut  &&
                    element.heureFin === rowSelected.heureFin);
            });
            setPlanningData(newPlanning)
            setPlanning({...planning, "jours" : newPlanning})
            planning.jours = JSON.stringify(newPlanning);
            planning.equipe = "Planning individuel"
            updatePlanningHoraire(planning)
            setIsOpenDelete(false);
        }
    }, [isOpenDelete]);

    return <>
        {planningData.length === 0 ? <>
            <div className={"d-grid "}>
                <div className={"d-flex justify-self-center mb-2"}>
                    <h4>Affecté à aucune équipe</h4>
                </div>
                <div className={"d-flex justify-content-end mb-2"}>
                    <button className={"btn btn-add-activity m-2"} onClick={setIsOpenAdd}>
                        <Add color={"white"} className={"mr-2"}/>
                        Affecter à une équipe
                    </button>
                </div>
            </div>

        </> : <>
            {planning.equipe && <h1 className={"text-center"}>{planning.equipe} ({totalHeures.toString().replace(":", "H")}) </h1>}
            <GenericDataTable
                setIsOpen={Object.keys(planning).length === 0 ? setIsOpenAdd : setIsOpenAddDay}
                btnText={"Ajouter un planning Horaire"}
                defaultSortFieldId={"nom"}
                defaultSortAsc={true}
                data={planningData}
                columnItem={column}
                noDataText={"Aucun planning trouvé"}
            />
        </>}

        <ModalPlanningHoraire  setIsOpen={setIsOpenAdd} isOpen={isOpenAdd} planningExemple={planningExemple} user={user} setCPlanning={setPlanning} setPlanningData={setPlanningData} setIsOpenAddDay={setIsOpenAddDay} />
        <ModalPlanningHoraireUpdate  setIsOpen={setIsOpenUpdate} isOpen={isOpenUpdate} jour={rowSelected} setJour={setRowSelected} updatePlanning={updatePlanningHoraire} planning={planning}/>
        <ModalPlanningHoraireAddDay isOpen={isOpenAddDay} setIsOpen={setIsOpenAddDay} planning={planning} updatePlanning={updatePlanningHoraire} />
    </>;
};

export default PlanningHoraires;